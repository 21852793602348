export default function disposeThreeObject(obj) {
    if (!obj) return;
    
    // Recursively dispose children
    while (obj.children.length > 0) {
        disposeThreeObject(obj.children[0]);
        obj.remove(obj.children[0]);
    }
    
    // Dispose geometry
    if (obj.geometry) {
        obj.geometry.dispose();
        obj.geometry = null;
    }
    
    // Dispose material
    if (obj.material) {
        if (Array.isArray(obj.material)) {
            obj.material.forEach(material => disposeMaterial(material));
        } else {
            disposeMaterial(obj.material);
        }
        obj.material = null;
    }
    
    // Helper function to dispose materials and textures
    function disposeMaterial(material) {
        if (material.map) {
            material.map.dispose();
            material.map = null;
        }
        if (material.lightMap) {
            material.lightMap.dispose();
            material.lightMap = null;
        }
        if (material.bumpMap) {
            material.bumpMap.dispose();
            material.bumpMap = null;
        }
        if (material.normalMap) {
            material.normalMap.dispose();
            material.normalMap = null;
        }
        if (material.specularMap) {
            material.specularMap.dispose();
            material.specularMap = null;
        }
        if (material.envMap) {
            material.envMap.dispose();
            material.envMap = null;
        }
        if (material.alphaMap) {
            material.alphaMap.dispose();
            material.alphaMap = null;
        }
        if (material.emissiveMap) {
            material.emissiveMap.dispose();
            material.emissiveMap = null;
        }
        if (material.roughnessMap) {
            material.roughnessMap.dispose();
            material.roughnessMap = null;
        }
        if (material.metalnessMap) {
            material.metalnessMap.dispose();
            material.metalnessMap = null;
        }
        if (material.displacementMap) {
            material.displacementMap.dispose();
            material.displacementMap = null;
        }
        // Dispose the material itself
        material.dispose();
    }
    
}
