import cleanSvg from "../svg/cleanSvg";
import {makePngFromSvg} from "../svg/makePngFromSvg";
import store from "../../../store";
import {snapshot} from "valtio";
import {v4 as uuidv4} from 'uuid';


import createTextImage from "../svg/createTextImage";
import {convertToSvg} from "../svg/convertToSvg";
import manageConcurrentRequest, {sendRequest} from "./util/manageConcurrentRequest";
import cacheImages from "../svg/cacheImages";
import svgOutline from "./util/svgOutline";
import getBoundingBoxByAngle from "../svg/getBoundingBoxByAngle";


const addNewTextElement = async (text, sectionId, oldId, style = {}) => {
    return new Promise(async (resolve, reject) => {
        
        // create new id if not exist
        const id = oldId || uuidv4()
        const snap = snapshot(store);
        
        
        // Manage request queue
        const requestId = await manageConcurrentRequest(id)
        if (!requestId) {
            resolve(null)
            return null
        } // If request is canceled
        
        
        // Initialize variables
        let currentElement = null
        let currentStyle = {}
        
        // Get current element if request is an update
        if (oldId) {
            currentElement = snap.elements.find(e => e.elementId === oldId)
            const {string, ...rest} = currentElement.text;
            currentStyle = rest
            URL.revokeObjectURL(currentElement.image);
        }
        
        
        // Set default style
        const defaultFontStyle = {
            fontSize: 10,
            fontFamily: 'Fasthand',
            fontStyle: 'Fasthand-Regular',
            ...currentStyle,
            ...style,
            string: text
        }
        
        const increaseQuality = 20
        const section = snap.sections.find(e => e.section_id === sectionId) //Get section
        const textBoxWidth = currentElement?.width || section?.width
        
        const textImage = await sendRequest(requestId, createTextImage, text, defaultFontStyle)
        
        const svgCode = await sendRequest(requestId, convertToSvg, URL.createObjectURL(textImage))
        const changedSvg = await sendRequest(requestId, cleanSvg, svgCode, textBoxWidth * increaseQuality) //Clean svg and change size
        
        const imgData = await sendRequest(requestId, makePngFromSvg, changedSvg.svg)
        
        
        if (!imgData) {
            resolve(false)
            return null
        }
        
        await cacheImages(imgData.blob, id)
        
        const snapping = await getBoundingBoxByAngle({
            width: textBoxWidth,
            height: imgData.height / increaseQuality,
            angle: 0,
            outline: changedSvg.outline,
            offset_x: section.offset_x,
            offset_y: section.offset_Y,
            sectionWidth: section.width,
            sectionHeight: section.height,
            section_offset_y: section.offset_Y,
            lockMove: true
        })
        
        // Create element object
        const element = {
            sectionId: sectionId,
            elementId: id,
            index: currentElement?.index || snap.elements.length,
            visible: true,
            type: "text",
            image: imgData.imgUrl,
            text: defaultFontStyle,
            outline: changedSvg.outline,
            snapping: snapping,
            
            needToCheck: false,
            width: textBoxWidth,
            height: imgData.height / increaseQuality,
            offset_x: currentElement?.offset_x || section.offset_x,
            offset_y: currentElement?.offset_y || section.offset_Y,
            radialAngle: currentElement?.radialAngle || section.radial_angle,
            angle: currentElement?.angle || section.angle || 0,
            lastUpdate: Date.now(),
            insideData: {
                intersect: true, // If the element is intersecting the section
                isInside: true, // If the element is intersecting the section
                points: [true, true, true, true] // Points of the element that are inside the section
            },
            lockMove : true,
        }
        
        
        // Update store
        if (oldId) {
            const index = snap.elements.findIndex(e => e.elementId === oldId)
            URL.revokeObjectURL(store.elements[index].image)
            store.elements[index].text = element.text
            store.elements[index] = element
            store.editElement = element
        } else {
            store.elements.push(element)
        }
        
        const threeViewerSection = window.threeViewer.scene.children.find(e => e.userData.id === sectionId && e.type === "section");
        
        threeViewerSection.update()
        threeViewerSection.helper.show(element)
        
        store.selectedElementId = id
        store.ui.addModalVisibility = false
        store.ui.zoom = sectionId
        
        
        // Generate decal and resolve
        //const decal = await generateElement(element, oldId)
        resolve(element)
    })
}

export default addNewTextElement;