
export default function limitElementSize(size, newSize, min = 3) {
    if (newSize.width < min || newSize.height < min) {
        if (newSize.width < newSize.height) {
            newSize.width = min
            newSize.height = min * size.y / size.x
        }
        else {
            newSize.height = min
            newSize.width = min * size.x / size.y
        }
    }
}
