import React, { useEffect, useState } from 'react';
import store from "../../store";
import {snapshot, useSnapshot} from "valtio";
//import DynamicForm from "../dynamicForm";
import configuratorFormView from "../../util/configurator/configuratorFormView"

import filterVariantList from "../../util/configurator/filterVariantList";


import findDefaultValues from "../../util/configurator/findDefaultValues";
import loadVariant from "../../util/product/loadVariant";
import ThemeElement from "../../util/theme/ThemeElement";

export default function Configurator() {
    const [formItems, setFormItems] = useState([]);
    const snap = useSnapshot(store);
    const variantsOptions = snap.variantsOptions;
    const variants = snap.variants;
    const currentSKU = snap.selectedProductSKU;
    
    useEffect(() => {
        const defaultValues = findDefaultValues(variants, variantsOptions, currentSKU)
        const view = configuratorFormView(defaultValues, variantsOptions)
        setFormItems(view);
    }, []);
    
    
    const configuratorVisibility = snap.ui.configurator;
    
    const handleOpenConfigurator = () => {
        store.ui.configurator = true;
    }
    const handleCloseConfigurator = () => {
        store.ui.configurator = false;
    }
    const handleChangeVariant = (value) => {
        const getSnapshot = snapshot(store)
        const variantList = getSnapshot?.variants

        const variant = filterVariantList(variantList, value)?.[0]
        const sku = variant?.sku
        store.ui.configurator = false;
        if (sku) {
            loadVariant(sku);
        } else {
            /*alert.showAlert({
                title: "Customization Unavailable :(",
                message:
                    <>Customization is not available for the current selection. Please change the other options to enable customization.</>,
                button: "Ok!",
                cancel: false,
            })*/
        }
    }
    
    return(
        <ThemeElement
            name={"Configurator"}
            data={{
                available: formItems.length && variants.length > 1,
                configuratorVisibility: configuratorVisibility,
                
                formItems: formItems,
                variants: variants,

                handleCloseConfigurator: handleCloseConfigurator,
                handleOpenConfigurator: handleOpenConfigurator,
                handleChangeVariant: handleChangeVariant,
            }}
        
        />
    );
}