import React, { useCallback, useMemo } from "react";
import GraphicTab from "./addTabs/Graphic";
import TextTab from "./addTabs/Text";
import MyDesign from "./addTabs/MyDesign";
import ThemeElement from "../../util/theme/ThemeElement";
import store from "../../store";
import { useSnapshot } from "valtio";

const AddModal = () => {
    const snap = useSnapshot(store);
    
    const close = useCallback(() => {
        store.ui.addModalVisibility = false;
    }, []);
    
    
    // Memoize the data to prevent unnecessary re-renders
    const themeElementData = useMemo(() => ({
        visible: snap?.ui?.addModalVisibility,
        close: close,
        Tabs: [
            {
                label: "graphic",
                content: <GraphicTab />,
            },
            {
                label: "text",
                content: <TextTab />,
            },
            {
                label: "my designs",
                content: <MyDesign />,
            },
        ],
        //selectedTab: snap.ui.selectedTab,
    }), [snap?.ui?.addModalVisibility, close]);
    
    if (!snap?.ui?.addModalVisibility) {
        return null;
    }
    
    return (
        <ThemeElement
            name={"AddModal"}
            data={themeElementData}
        />
    );
};

export default React.memo(AddModal);
