import React, { useCallback, useEffect, useState, memo } from "react";
import store from "../../../store";
import { snapshot, useSnapshot } from "valtio";
import getSavedDesignList from "../../../services/getSavedDesignList";
import getUserShopify from "../../../services/getUserShopify";
import startModificationForSaveDesign from "../../../util/internal/sections/startModificationForSaveDesign";
import calculateSize from "../../../util/configurator/calculateSize";
import deleteSavedDesign from "../../../services/deleteSavedDesign";
import alert from "../../assets/alert";
import ThemeElement from "../../../util/theme/ThemeElement";

const MyDesign = () => {
    const snap = useSnapshot(store);
    const user = snap.user;
    const userId = store.userId;
    const [isLoading, setIsLoading] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const [filteredDesigns, setFilteredDesigns] = useState([]);
    const storeName = snap.order.store_name;
    
    const appendDesign = useCallback(async (event, design = null) => {
        setInProgress(true)
        const snap = snapshot(store);
        const oldSection = design.section
        const oldElements = await startModificationForSaveDesign(oldSection);
        
        const sections = snap.sections.filter(it => it.section_id === snap.selectedSectionId);
        const updateElementsPromises = await Promise.all(oldElements.map(async (element) => {
            if (sections.length === 0) return element;

            const newSection = sections.find(section => section.section_id === snap.selectedSectionId);
            const newSize = await calculateSize(element, oldSection, newSection)
            return {
                ...element,
                ...newSize,
                sectionId: newSection.section_id
            };
        }));
        
        store.elements = [...snap.elements, ...updateElementsPromises];
        const threeViewerSections = window.threeViewer.scene.children.filter(e => e.type === "section");
        threeViewerSections.forEach(section => section.update());
        store.ui.addModalVisibility = false;
        store.selectedElementId = updateElementsPromises[0].elementId;
        setInProgress(false)
    }, []);
    
    const handleRemoveDesign = useCallback(async (id) => {
        alert.showAlert({
            title: "Confirm Delete Design",
            message: <>Are you sure you want to delete this design?<br/>This action cannot be undone.</>,
            button: "Confirm & Delete",
            cancel: "Cancel",
            callBack: async (okBtn) => {
                if (okBtn) {
                    const result = await deleteSavedDesign(storeName, id);
                    if (result) await handleLoadDesignList();
                }
            }
        });
    }, [storeName]);
    
    const handleSignIn = useCallback(async () => {
        await getUserShopify("forceToLogin");
    }, []);
    
    const handleLoadDesignList = useCallback(async () => {
        const id = userId ? userId : await getUserShopify("getUser");
        if (id) {
            setIsLoading(true);
            const result = await getSavedDesignList(id);
            if (!result || result.error) {
                setFilteredDesigns([]);
                setIsLoading(false);
                return;
            }
            setFilteredDesigns(result);
            setIsLoading(false);
        } else {
            setFilteredDesigns([]);
        }
    }, [userId]);
    
    useEffect(() => {
        handleLoadDesignList();
    }, [handleLoadDesignList]);
    
    //console.log("filteredDesigns", filteredDesigns)
    return (
        <ThemeElement
            name={"AddElementMyDesign"}
            data={{
                isLoading: isLoading,
                inProgress: inProgress,
                user: user,
                list: filteredDesigns,
                handleAppendDesign: appendDesign,
                handleSignIn: handleSignIn,
                handleDeleteDesign: handleRemoveDesign
            }}
        />
    );
};

export default memo(MyDesign);
