import React, {useEffect} from 'react';
import {Routes, Route} from 'react-router-dom';
import Home from './scenes/Home.js';
import ReactGA from 'react-ga';

function App() {
    useEffect(() => {
        ReactGA.initialize('G-TW08YGPBZT'); // Our Google Analytics tracking ID
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    return (
        <>
            <Routes>
                <Route path="/" element={<Home/>}/>
            </Routes>
        </>
    );
}

export default App;
