import React from 'react';


const Button = ({item, value, handleChange, onClick, children, pending, formData}) => {
    
    const disabled = typeof item.disabled === "function" ? item.disabled(formData) : item.disabled || false;
    
    const type = item?.buttonType || 'secondary'
    const selectedType = item?.selected || false
    
    
    const style = {
        "primary": `flex items-center justify-center h-full transition text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center
            ${selectedType && 'bg-blue-800 dark:bg-blue-700'}
            ${item?.className}
            ${disabled && 'bg-gray-300 text-gray-500 cursor-default hover:bg-gray-300 hover:text-white'}
            disabled:cursor-default disabled:!text-white disabled:bg-gray-300 disabled:hover:!text-white disabled:hover:bg-gray-300
        `,
        "secondary": `flex items-center justify-center h-full transition text-gray-700 bg-gray-100 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center
            
            ${selectedType && 'bg-gray-200 dark:bg-gray-300'}
            ${item?.className}
            
        `,
        "alt": `flex items-center justify-center h-full transition text-blue-500 bg-white hover:text-blue-800 focus:ring-4 border border-blue-600 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center
            ${selectedType && 'text-blue-800 border-blue-800'}
            ${item?.className}
            disabled:!text-gray-300 disabled:border-gray-300 disabled:cursor-default disabled:hover:!text-gray-300 disabled:hover:bg-white
        `,
        "icon": `title-icon flex items-center justify-center h-[34px] min-w-[34px] px-[4px] transition text-blue-500 focus:ring-4 border border-blue-600 focus:outline-none focus:ring-blue-300 font-medium rounded text-center
            ${selectedType && 'text-blue-800 border-blue-800'}
            ${item?.className}
        `,
        "flat": `flex items-center justify-center transition focus:outline-none font-medium rounded text-center active:bg-[rgba(0,0,0,0.05)] group
            ${selectedType && 'bg-[rgba(0,0,0,0,0.2)'}
            ${item?.className}
        `
    }
    
    
    return (
        <div>
            
            <div className={"flex flex-col"}>
                <button
                    className={`${style[type]} ${item.className || ''}`}
                    type="button"
                    onClick={(e) => {
                        if(item.onclick) {
                            item.onclick(e)
                        } else if (onClick) {
                            onClick(e)
                        }
                        return
                    }
                    }
                    disabled={disabled}
                >
                    {item?.label} {children}
                </button>
            </div>
        
        </div>
    )
}


export default Button;