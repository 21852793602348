const checkUrl = (api, formData, innerFormData = {}, value = null) => {
    
    // protect function for first render
    if (formData && Object.keys(formData).length === 0) return false;
    if (!api?.url) return false;
    if (innerFormData && Object.keys(innerFormData).length === 0) return false;
    
    const childNames = Object.keys(formData);
    
    let returnValue = {
        disable : false,
        reset : false,
        load : false
    }
    for (let i = 0; i < childNames.length; i++) {
        const childName = childNames[i];
        const searchQuery = `{${childName}}`;
        
        
        
        
        if (api?.url?.includes(searchQuery)) {
            if (!formData[childName]) {
                returnValue.disable = true
            }
            if (innerFormData[childName] !== formData[childName] ) {
                returnValue.reset = true
            }
           /* if (innerFormData[childName]) {
                if (formData[childName]) {
                
                }
            } && formData[childName] !== innerFormData[childName] && value) return true;*/
        }
        //} (formData[childNames[i]] === null || formData[childNames[i]] === "" || formData[childNames[i]] === undefined)) return true;
        
    }
    
    return returnValue;
};

export default checkUrl;
