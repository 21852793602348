function loadFont(inFontURL, inFontName) {
    return new Promise((resolve, reject) => {
        let font = new FontFace(inFontName, `url(${inFontURL})`);
        font.load().then(function (loadedFont) {
            document.fonts.add(loadedFont);
            resolve();
        }).catch(function (error) {
            reject(error);
        });
    });
}

export default loadFont;