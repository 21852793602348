import * as THREE from "three";
import {DecalGeometry} from "three/addons/geometries/DecalGeometry";
import drawSnappingTexture from "./drawSnappingTexture";


export class DecalSnapping extends THREE.Mesh {
    constructor(section) {
        
        const euler = section.euler
        const point = section.point
        const size = section.size
        const decalGeometry = new DecalGeometry(section, point, euler, size)
        const emptyTexture = new THREE.Texture();
        super(decalGeometry);
        
        this.material = window.productColorObject.materialList.normal.clone()
        this.material.map = emptyTexture
        
        this.size = size
        //this.point = point
        //this.euler = euler
        
        this.type = "snapping";
        this.name = "Snapping";
        this.layers.set(5) // 5 is the layer for the snapping
        
        // bind functions
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
    }
    
    
    // show snapping
    async show(verticalLine, horizontalLine, offsetX, offsetY) {
        
        const width = this.size.x * 1000
        const height = this.size.y * 1000
        const texture = await drawSnappingTexture({width, height, verticalLine, horizontalLine, offsetX, offsetY})
        this.material.map.dispose()
        this.material.map = new THREE.CanvasTexture(texture)
        this.material.needsUpdate = true
        
        this.visible = true
    }
    
    
    // hide snapping
    hide() {
        this.visible = false
    }
}


