export default async function cacheImages(blob, id) {
    return new Promise((resolve, reject) => {
        
        if (window.imageCache === undefined) {
            window.imageCache = new Map();
        }
        
        
        const img = new Image();
        img.onload = () => {
            
            window.imageCache.set(id, img);
            resolve(id);
        };
        img.onerror = reject;
        img.src = URL.createObjectURL(blob);
        
    });
}