
import manageTransformation from "../manageTransformation"
import store from "../../../../store"
import {snapshot} from "valtio";

export default function translateSelectedElement({ x, y }) {
    
    const snap = snapshot(store);
    let element = snap.elements.find(e => e.elementId === snap.selectedElementId)

    if (!element) return

    let newOffset_x = element.offset_x + (x || 0)
    let newOffset_y = element.offset_y + (y || 0)

    let transform = {
        offset_x: newOffset_x,
        offset_y: newOffset_y,
        snappedY: [],
        snappedX: [],
    }
    
    manageTransformation(transform)
}

