export default async function drawSnappingTexture({width, height, horizontalLine, verticalLine, offsetX, offsetY}) {
    
    const distance = window.threeViewer.camera.position.distanceTo(window.threeViewer.center)
    const distanceScale = (2 - (1.5 - distance)) * 3//distance > 1 ? (distance * 2) :  (distance * 3) + 1//(distance - 0.5) * 5//(distance / 0.7) + (distance - 0.5)
    
    // set default values
    const dashSize = distanceScale
    const gapSize = distanceScale * 1.5
    const scale = 5
    const color = '#ffffff'
    
    // Create canvas and context
    const canvas = document.createElement('canvas');
    canvas.width = width * scale;
    canvas.height = height * scale;
    const ctx = canvas.getContext('2d');
    
    ctx.strokeStyle = color;
    
    // Set the line dash pattern and lineWidth
    const returnStyle = (bold) => {
        if (bold) {
            ctx.lineWidth = 8;
            ctx.setLineDash([0, 0]);
        } else {
            ctx.lineWidth = distanceScale//2.5;
            ctx.setLineDash([dashSize, gapSize]);
        }
    }
    const boldX = [0, canvas.width]
    const boldY = [0, canvas.height]
    
    // Draw vertical dashed lines
    verticalLine.forEach(vPoint => {
        ctx.beginPath();
        const x = (vPoint - offsetX + (width / 2)) * scale
        returnStyle(boldX.includes(x))
        ctx.moveTo(x, 0);
        ctx.lineTo(x, canvas.height);
        ctx.stroke();
    });
    
    // Draw horizontal dashed lines
    horizontalLine.forEach(hPoint => {
        ctx.beginPath();
        const y = (height - (hPoint - offsetY + (height / 2))) * scale
        returnStyle(boldY.includes(y))
        ctx.moveTo(0, y);
        ctx.lineTo(canvas.width, y);
        ctx.stroke();
    });
    
    return canvas;
}