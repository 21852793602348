import store from "../../../../store";
import {snapshot} from "valtio";


export default function controlUpdate(event) {
    
    
    const threeViewer = window.threeViewer
    const sectionSnapping = window.threeViewer.sectionRadialAngleSnapp
    const controlStartByUser = window.threeViewer.controlStartByUser
    
    if (threeViewer.touchCount > 1) return false
    
    const snap = snapshot(store);
    
    if (snap.ui.fullScreen) return false
    
    const elements = snap.elements
    
    
    //if (snap.ui.zoom === null) return false
    const azimuthalAngleRadians = threeViewer.controls.azimuthAngle;
    const deg = (azimuthalAngleRadians * (180 / Math.PI)) % 360;
    
    let deg360 = deg < 0 ? deg + 360 : deg
    
    const diff = 45
    
    
    // apply snapping
    const highlighter = (item, state) => {
        //store.ui.zoom = null;
        if (state) {
            
            if (event.type === "controlend") {
                store.ui.zoom = snap.ui.zoom !== null ? item.id : false;
                item.highlight.off()
                
            } else {
                if (controlStartByUser) {
                    store.ui.zoom = false
                    const count = elements.filter(e => e?.sectionId === item.id).length
                    item.highlight.on(count)
                }
                
            }
            
        } else {
            item.highlight.off()
            
        }
    }
    
    
    // Check all sections and call highlighter function
    sectionSnapping.map(e => {
        
        const sectionDeg = e.angle * (180 / Math.PI)
        const sectionDeg360 = sectionDeg < 0 ? sectionDeg + 360 : sectionDeg
        
        if (sectionDeg360 + 360 < deg360 + diff) {
            highlighter(e, true)
        } else if (Math.abs(sectionDeg360 - deg360) < diff) {
            highlighter(e, true)
        } else {
            highlighter(e, false)
        }
        
        
    })
    
    
}