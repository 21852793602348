export default async function loadCategories(){
    
    const urlParams = new URLSearchParams(window.location.search);
    let storeName = urlParams.get("store_name")
    try {
        let response = await fetch(process.env.REACT_APP_PRODUCT_GRAPHIC_CATEGORY_API + 'categories/?store_name=' + storeName);
        if (!response) {
            console.log("Error loading categories")
            return [];
        } else {
            const data = await response.json();
            return data.contents || [];
        }
    } catch (error) {
        return [];
    }
}

