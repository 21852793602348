export default async function calculateSize(elementObj, oldSectionObj, newSection) {
    
    const elementId = elementObj.elementId;
    // If baseSectionSize exists, use it as oldSection; otherwise, initialize it with newSection
 
    if (!window.threeViewer.baseElement)  window.threeViewer.baseElement = {};
    if (!window.threeViewer.baseElement[elementId]) {
        window.threeViewer.baseElement[elementId] = {
            width: elementObj.width,
            height: elementObj.height,
            offset_x: elementObj.offset_x,
            offset_y: elementObj.offset_y,
            section : {
                width: oldSectionObj.width,
                height: oldSectionObj.height,
                offset_x: oldSectionObj.offset_x,
                offset_Y: oldSectionObj.offset_Y
            }
        };
    }
    const oldSection = window.threeViewer.baseElement[elementId].section;
    const element = window.threeViewer.baseElement[elementId];
    
    // Calculate size ratios between new and old sections
    const scaleY = newSection.height / oldSection.height;
    const scaleX = newSection.width / oldSection.width;
    const scale = Math.min(scaleX, scaleY);
    
    // Update element size based on section size change
    const newElementHeight = element.height * scale;
    const newElementWidth = element.width * scale;
    
    // Calculate new X position to keep the element centered in the section
    const newElementOffsetX = ((element.offset_x - oldSection.offset_x) * scale) + newSection.offset_x
    
    
    const newElementOffsetY = newSection.offset_Y + ((element.offset_y - oldSection.offset_Y) * scale)
    
   /*console.log("scale", scale, scaleX, scaleY, element, oldSection, newSection, {
       width: newElementWidth,
       height: newElementHeight,
       offset_x: newElementOffsetX,
       offset_y: newElementOffsetY
   })*/
    
    // Return the new dimensions and offsets of the element
    return {
        width: newElementWidth,
        height: newElementHeight,
        offset_x: newElementOffsetX,
        offset_y: newElementOffsetY
    };
}
