import * as THREE from 'three';
const raycaster = new THREE.Raycaster();

export default function findIntersectionWithSection(inMouse, inSection, withDummyPlane = false) {

    let model = withDummyPlane ? inSection.dummyPlane : inSection
    if (model === undefined) return null

    raycaster.layers.enableAll()
    raycaster.setFromCamera(inMouse, window.threeViewer.camera);

    let intersects = raycaster.intersectObject(model, false);
    if (!intersects.length) return null;

    let intersection = intersects[0];

    //console.log("intersection", inSection.dummyPlane, inSection.size)
    let uv = intersection.uv;
    let sectionSize = inSection.size.clone().multiplyScalar(1000);
    let width = sectionSize.x;
    let height = sectionSize.y;
    let x = uv.x * width - width / 2;
    let y = uv.y * height - height / 2;

    return { x , y, z : 0 }
}