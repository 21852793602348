// Import Elements
import Input from './Input';
import Select from './Select';
import DateRange from './DateRange';
import Divider from './Divider';
import Button from './Button';
import Range from './Range';
import Container from "./Container";
import Checkbox from "./Checkbox";
import Textarea from "./Textarea";
import Module from "./Module";
import Date from "./Date";
import DateHour from "./DateHour";
//import Image from "./Image";
import Number from "./Number";
//import Text from "./Text";
import RadioButton from './RadioButton';
import NumberSlider from './NumberSlider';

//import ProductImage from '../components/productImage';
//import ProductList from '../components/productList';

//Export Elements as default
export default {
    Container,
    Input,
    Select,
    DateRange,
    Divider,
    Button,
    Range,
    Checkbox,
    //Textarea,
    Module,
    Date,
    //Image,
    Number,
    //Text,
    DateHour,
    RadioButton,
    //ProductImage,
    //ProductList,
    
    NumberSlider
};