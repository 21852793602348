import React, { memo, useEffect, useState, useCallback } from "react";
import store from "../../../store";
import { useSnapshot } from "valtio";
import loadCategories from "./../../../services/loadCategories";
import addNewGraphicElement from "../../../util/internal/elements/addNewGraphicElement";
import addNewUploadedElement from "../../../util/internal/elements/addNewUploadedElement";
import ThemeElement from "../../../util/theme/ThemeElement";

const GraphicTab = memo(() => {
    const snap = useSnapshot(store);
    const selectedSectionId = snap?.selectedSectionId;
    const graphicCategories = snap?.ui.graphicCategories;
    const [isLoading, setIsLoading] = useState(false);
    const [mediaList, setMedia] = useState();
    const [filteredList, setFilteredList] = useState([]);
    const [selectedCategoryList, setSelectedCategoryList] = useState([]);
    const [inProgress, setInProgress] = useState(false);
    
    
    
    // handle click on category or graphic item
    const onClick = useCallback((item) => {
        if (item.type === 'category') {
            setSelectedCategoryList((prevList) => {
                const newList = [...prevList, item];
                handleFilterList(item.id);
                return newList;
            });
        } else {
            addNewGraphicElement(item.img, selectedSectionId);
        }
    }, [selectedSectionId, mediaList]);
    
    // Handle get file
    const handleUpload = useCallback(async (e) => {
        const isValidFileType = (file) => {
            const allowedTypes = ['image/png', 'image/jpeg', 'image/bmp', 'image/svg+xml'];
            return file && allowedTypes.includes(file.type);
        };
        
        setInProgress(true);
        const file = e.target.files[0];
        if (isValidFileType(file)) {
            try {
                store.editElement = await addNewUploadedElement(file, selectedSectionId);
            } catch (error) {
                console.error("Error uploading image:", error);
            } finally {
                setInProgress(false);
            }
        }
        
        e.target.value = null;
    }, [selectedSectionId]);
    
    // Handle Load categories
    const handleLoadCategories = useCallback(async () => {
        setIsLoading(true);
        let list = [];
        if (graphicCategories) {
            list = JSON.parse(localStorage.getItem("graphicCategories"));
        } else {
            list = await loadCategories();
            list = list.filter(category => category.is_active && category.images.length > 0);
            list.sort((a, b) =>
                (b.show_on_home - a.show_on_home) ||
                (a.priority - b.priority) ||
                a.name.localeCompare(b.name)
            );
            
            const flattedList = list.reduce((acc, category) => {
                acc.push({
                    id: category.id,
                    img: category.category_image_url,
                    description: category.description,
                    name: category.name,
                    type: 'category',
                    parent: category.show_on_home ? null : category.parent_id,
                    showOnHome: category.show_on_home,
                });
                acc.push(...category.images.sort((a, b) => a.id - b.id).map((image) => ({
                    id: image.id,
                    img: image.url,
                    description: category.description,
                    name: category.name,
                    type: 'image',
                    parent: category.id,
                    showOnHome: false,
                })));
                return acc;
            }, []);
            store.ui.graphicCategories = true;
            localStorage.setItem("graphicCategories", JSON.stringify(flattedList));
            list = flattedList;
        }
        setMedia(list);
        setIsLoading(false);
    }, [graphicCategories]);
    
    // Handle filter list
    const handleFilterList = (parentId = null, searchString = null) => {
        if (mediaList) {
            let list = mediaList.filter(item =>
                item.parent === parentId ||
                (parentId === null ? item.showOnHome : false)
            );
            
            if (searchString) {
                list = list.filter(item =>
                    item.name.toLowerCase().includes(searchString.toLowerCase()) ||
                    item.description.toLowerCase().includes(searchString.toLowerCase())
                );
            }
            setFilteredList(list);
        }
    };
    
    // Handle search on list
    const handleSearch = useCallback((text) => {
        const parentId = selectedCategoryList.length > 0 ? selectedCategoryList[selectedCategoryList.length - 1].id : null;
        handleFilterList(parentId, text);
    }, [selectedCategoryList, mediaList]);
    
    // Handle Remove Tag
    const handleRemoveTag = useCallback((id) => {
        setSelectedCategoryList((prevList) => {
            const index = prevList.findIndex(item => item.id === id);
            if (index !== -1) {
                const newList = prevList.slice(0, index);
                const parentId = newList.length > 0 ? newList[newList.length - 1].id : null;
                handleFilterList(parentId);
                return newList;
            }
            return prevList;
        });
    }, [handleFilterList, mediaList]);
    
    
    
    // Initial and loading categories data and filter list
    useEffect(() => {
        handleLoadCategories();
    }, []);
    
    
    useEffect(() => {
        if (mediaList) {
            handleFilterList();
        }
    }, [mediaList]);
    
    
    
    return (
        <ThemeElement
            name={"AddElementGraphic"}
            data={{
                inProgress,
                handleUpload,
                isLoading,
                content: filteredList,
                onClick,
                handleSearch,
                handleRemoveTag,
                tags: selectedCategoryList.map(item => ({
                    icon: item.render,
                    title: item.name,
                    id: item.id
                })),
            }}
        />
    );
});

export default GraphicTab;
