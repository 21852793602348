import React, {useMemo} from "react";
import hasValue from "../util/hasValue";


const Container = ({children, item, validationMessage, value, handleChange, style, formData}) => {
    
    // Handle Collapse
    const [collapse, setCollapse] = React.useState(true)
    const handleCollapse = useMemo(() => {
        return () => {
            if (item?.toggle) setCollapse(!collapse);
        };
    }, [item?.toggle, collapse]);
    
    // Handle Clear
    const handleClearData = (e) => {
        e.stopPropagation();
        handleChange("", item.name)
    }
    // Handle Reset
    const handleResetData = (e) => {
        e.stopPropagation();
        handleChange(item.defaultValue, item.name)
    }

    // Return View
    return (
        <div className={`form_${item.name} form_item form_item_type_${item.type} ${item.toggle? "toggle" : ""} ${collapse ? "collapse" : ""} ${hasValue(value) ? "filled" : ""} dynamicFormElements component_form`}>
            <div className={`form_${item.name}_container form_item_container` }>
                
                {item.type !== "Hidden" &&
                    <label
                        onClick={handleCollapse}
                        className={` form_${item.name}_label form_item_label`}>
                        <span className={`form_${item.name}_label_title form_item_label_title`}>
                            {item.label}
                            {item.label && !item?.disabled && item.reset && (hasValue(item.defaultValue) && value !== item.defaultValue) &&
                                <span onClick={handleResetData} className={"form_item_label_reset_control"} > <svg height="100%"  xmlns='http://www.w3.org/2000/svg' fill='currentColor' viewBox="0 0 864 864"> <path clipRule="evenodd" fillRule="evenodd"  d="M432,0C193.41,0,0,193.41,0,432s193.41,432,432,432,432-193.41,432-432S670.59,0,432,0Zm226.97,658.8c-17.21,0-31.8-11.16-36.96-26.64l-.18,.06c-8.62-24.28-20.58-47.6-35.83-69.24-59.05-83.79-158.33-129.69-260.42-120.42l-22.81,2.03,97.42,81.22c16.59,13.83,18.83,38.5,5,55.09-13.83,16.59-38.5,18.83-55.09,5l-169.96-141.7c-7.96-6.63-12.97-16.15-13.92-26.47-.92-10.32,2.29-20.59,8.93-28.54l141.66-169.92c13.83-16.59,38.5-18.83,55.09-5,16.59,13.83,18.83,38.5,5,55.09l-81.18,97.38,22.77-2.06c129.9-11.8,256.23,46.61,331.39,153.22,19.41,27.53,34.63,57.2,45.61,88.11l-.08,.03c1.63,4.29,2.53,8.94,2.53,13.81,0,21.52-17.44,38.96-38.96,38.96Z" /></svg></span>
                            }
                            {item.label && hasValue(value) && item.clear &&
                                <span onClick={handleClearData} className={"form_item_label_clear_control"} > <svg height="100%"  xmlns='http://www.w3.org/2000/svg' fill='currentColor' viewBox="0 0 864 864"> <path  className="cls-1" d="M432,0C193.41,0,0,193.41,0,432s193.41,432,432,432,432-193.41,432-432S670.59,0,432,0Zm192,569.11c17.57,17.57,17.57,46.07,0,63.64-8.79,8.79-20.3,13.18-31.82,13.18s-23.03-4.39-31.82-13.18l-137.11-137.11-137.11,137.11c-8.79,8.79-20.3,13.18-31.82,13.18s-23.03-4.39-31.82-13.18c-17.57-17.57-17.57-46.07,0-63.64l137.11-137.11-137.11-137.11c-17.57-17.57-17.57-46.07,0-63.64s46.07-17.57,63.64,0l137.11,137.11,137.11-137.11c17.57-17.57,46.07-17.57,63.64,0,17.57,17.57,17.57,46.07,0,63.64l-137.11,137.11,137.11,137.11Z"/></svg></span>
                            }
                        
                        </span>
                        <span className={`form_${item.name}_label_toggle_control form_item_label_toggle_control`} >
                        
                            {item.toggle && !item?.disabled &&
                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 20 20" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"  clipRule="evenodd"></path></svg>
                            }
                        
                        </span>
                    
                    </label>
                }
                
            
                
                <div className={`form_${item.name}_content form_item_content ` }>
                    
                    {children}
                    
                    {validationMessage &&
                        <div className={`form_${item.name}_validation form_item_validation`}>
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"> <path fill="none" d="M0 0h24v24H0z"></path> <path  d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path> </svg>
                            {String(validationMessage)}
                        </div>
                    }
                    {item.hint && !validationMessage &&
                        <div className={`${style?.hint?.className ? style?.hint?.className : ""} form_${item.name}_hint form_item_hint`}>
                            {item.hint}
                        </div>
                    }
                </div>
                
                
            </div>
        </div>
    )
}



export default Container;