
import './css/numberSliderCss.css'
import sizeType from '../util/sizeStyle'

import CustomSlider from './slider'
import React, {useEffect} from "react";

const NumberSlider = ({
    item,
    value,
    validationMessage,
    handleChange,
    handleFocus,
    handleBlur,
    handleKeyPress,
    defaultValue,
    formData,
}) => {
    const disabled = typeof item.disabled === 'function' ? item.disabled(formData) : item.disabled || false
    
    const [innerValue, setInnerValue] = React.useState(0)
    const min = item.min || 0
    useEffect(() => {
        const inputValue = Number(value) || Number(defaultValue) || Number(item.min) || 0
        setInnerValue(inputValue)
    }, [value]);
    
    const changeValue = (val, isDragging) => {
        if (handleChange) {
            const decimalCount = item.decimal || 0
            const parsedValue = parseFloat(Number(val).toFixed(decimalCount))
            if (item.updateOnMouseUp) {
                if (!isDragging) handleChange(parsedValue, item.name)
            } else {
                handleChange(parsedValue, item.name)
            }
            const inputValue = Number(parsedValue)
            setInnerValue(inputValue)
        }
    }
    // Return Input in view
    return (
        <div className={`${sizeType[item.size]} number_slider_container `}>
            
            <input
                name={item.name}
                type={item?.text === false ? 'hidden' :'number'}
                placeholder={item.placeholder}
                disabled={disabled}
                className={`disabled:text-gray-400 rounded-[5px] text-center px-[17px] h-[45px] select-auto border-gray-300 border focus:outline-none focus:bg-gray-100 focus:border-black w-[70px] ${disabled ? "disabled !select-none" : " hover:border-black"}
                    !transition whitespace-pre-wrap  focus:outline-none
                    ${sizeType[item.size]}
                    ${item.innerClassName || ''}
                    ${validationMessage ? 'border-red-500 border-1' : ''}`}
                value={innerValue ? String(innerValue)?.replace(/^0+/, '') : 0}
                min={min}
                onChange={(e) => {
                    let val = e.target.value
                    if (val < min) {
                        val = min
                    }
                    if (item.max !== undefined && val > item.max) {
                        val = item.max
                    }
                    changeValue(val)
                }}
                onFocus={(e) => {
                    if (handleFocus) handleFocus(e.target.value, item.name)
                }}
                onBlur={(e) => {
                    if (handleBlur) handleBlur(e.target.value, item.name)
                }}
                onKeyPress={(e) => {
                    if (handleKeyPress) handleKeyPress(e)
                }}
                autoFocus={item?.autoFocus}
            />
            
                <CustomSlider min={min} max={item.max || 100} change={changeValue} defaultValue={value} updateOnMouseUp={item.updateOnMouseUp} suggestion={item.suggestion} />
            
            
        </div>
    )
}

export default NumberSlider
