// TODO: MEMET : this return ata must mapped to clean data
export default async function getSavedDesignList(userId) {
    const urlParams = new URLSearchParams(window.location.search);
    let storeName = urlParams.get("store_name")
    let response = await fetch(`${process.env.REACT_APP_FILE_SHARE_API}customers/customer-sections?type=user&store_name=${storeName}&user_id=${userId}`);
    let result = await response.json();
    
    result.sort((a, b) => new Date(b.last_changed_at) - new Date(a.last_changed_at));
    //return result
    return result.map(item=> {
        const sectionsData = JSON.parse(item.section_string);
        return {
            id: item.id,
            modification_id: item.modification_id,
            
            created_at: item.created_at,
            last_changed_at: item.last_changed_at,
            
            image_url: item.image_url,
            title: item.title,
            sku : item.sku,
            
            section : sectionsData.sections[0],
        }
    });
}
