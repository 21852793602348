import store from "../store";
import React from 'react';
import * as Babel from '@babel/standalone';

import injectCss from "./../util/theme/injectCss";
import createCss from "./../util/theme/createCss"


// Fetch and parse the theme
export default async function getTheme(storeName, themeId) {
    
    const apiUrl = `${process.env.REACT_APP_THEME_API}${themeId}?store_name=${storeName}`;
    const response = await fetch(apiUrl);
    let themeJson = await response.json();
    themeJson = themeJson?.theme;
    let themeElements = themeJson.elements
    
    
    let themeString = {};
    let themeCss = []
    let themeMobileCss = []
    let themeTabletCss = []
    Object.keys(themeElements).forEach(key => {
        const componentString = themeElements[key];
        const wrappedComponentString = `
            (props) => {
                const Theme = props?.Theme;
                const data = props?.data || {};
                const Component = props?.Component || <></>;
                return(${componentString.jsx || componentString || '<></>'})
            }
        `;
        
        // Create array for css
        if (componentString.css) themeCss.push(componentString.css);
        if (componentString.mobileCss) themeMobileCss.push(componentString.mobileCss);
        if (componentString.tabletCss) themeTabletCss.push(componentString.tabletCss);
        
        
        try {
            themeString[key] = Babel.transform(wrappedComponentString, {
                presets: ['react']
            }).code;
            
        } catch (error) {
            console.error(`Error compiling component ${key}:`, error);
        }
        
        
    });
    let elementsCss = createCss(themeCss, themeTabletCss, themeMobileCss)
    
    injectCss(themeJson.css)
    injectCss(elementsCss)
    
    store.ui.theme = true

    localStorage.setItem('NeatolabsTheme', JSON.stringify(themeString));
    elementsCss = null;
    themeString = null;
    themeElements = null;
    
    // Hint for garbage collection (optional, modern JS engines usually handle this well)
    if (window.gc) {
        window.gc();
    }
    
}



