import store from '../../store';
import {snapshot} from 'valtio';
import math from "dat.gui/src/dat/color/math";


export async function fillStorePrice(snap) {
    //console.log('fillStorePrice---------------------', snap)
    
    const sku = snap.selectedVariantSKU// || snap.selectedProductSKU //snap.product.map(product => product.sku)
    
        //snap.selectedProductSKU;
    //const variant = snap.variants.find(variant => variant.sku === sku || variant.bundle_items?.find(item => item.sku === sku));
    const variant = snap.variants.find(variant => variant.sku === sku);
    
    
    
    const basePrice = Number(parseFloat((variant.price != null ? variant.price : "0").toString().replace(/,/g, '')))
    
    store.order = {
        ...snap.order,
        basePrice: basePrice.toFixed(2),
        //price: Number(variant.price).toFixed(2),
        variant_id: variant.id,
        product_id: variant.store_product_id,
    }
    return basePrice;
}
// --------------------------------------------------------
// Calculate price for all products
// --------------------------------------------------------
export default async function PriceCalculator() {
    const snap = snapshot(store);
    const products = snap.product;
    let basePrice = await fillStorePrice(snap);//Number(store.order.basePrice);
    
    
    // Calculate price, eching count, and neatolabs cost in a single iteration
    const totals = products.reduce((acc, product) => {
        const { price, echingCount, neatolabsCost, groupCount } = PriceCalculatorPerProduct(snap, product.sku);
        
        acc.totalPrice += price;
        acc.totalEchingCount += echingCount;
        acc.totalNeatolabsCost += neatolabsCost;
        acc.groupCount.push(groupCount)
        return acc;
    }, { totalPrice: basePrice, totalEchingCount: 0, totalNeatolabsCost: 0, groupCount: [] });
    
    const totalGroupCount = Math.max(...totals.groupCount)
    
    // Update store with calculated values
    store.order.price = (totals.totalPrice * totalGroupCount).toFixed(2);
    store.order.eching_sections = (totals.totalEchingCount * totalGroupCount).toString();
    store.order.neatolabsCost = (totals.totalNeatolabsCost * totalGroupCount).toString();
}



// --------------------------------------------------------
// Calculate price, eching count, and neatolabs cost for a single product
// --------------------------------------------------------
function PriceCalculatorPerProduct(snap, productSKU) {
    // Get data from store
    const sectionList = snap.sections.filter(section => section.product_sku === productSKU);
    const elementList = snap.elements.filter(element =>
        sectionList.some(section => section.section_id === element.sectionId)
    );
    
    // Get pricing data from store
    const pricing = snap.product?.find(product => product.sku === productSKU)?.pricing;
    
    // test data
    /*const pricing = {
        "price_model": "element", //fixed/section/element/size
        "price": 10,
        "discount_model": "discount_on_addition", //none/fixed/discount_on_addition
        "discount_type": "percent", //value/percent
        "discount": 25,
        "max_price_total": 70,
        "min_price_total": 40
    }*/
    
    
    // Set element count
    const elementCount = elementList.length;
    
    // set group customization count
    const groupCount = Math.max(1, ...elementList.map(element=> element[element.type]?.list?.length || 0))
    
    // Set pricing model
    const pricingModel = pricing?.price_model || 'section';
    
    // Calculate eching sections count
    const echingList = elementList.reduce((acc, element) => {
        acc[element.sectionId] = (acc[element.sectionId] || 0) + 1;
        return acc;
    }, {});
    
    const echingCount = Object.keys(echingList).length;
    
    // Calculate price
    const discountPrice = getDiscountPrice(pricing);
    let price = getPriceFromModel[pricingModel]({
        pricing,
        discountPrice,
        elementCount,
        echingCount,
        elementList,
    });
    
    // Adjust price based on limits and eching count
    if (echingCount > 0) {
        price = Math.min(Math.max(price, pricing.min_price_total), pricing.max_price_total);
    }
    
    // Calculate NeatoLabs cost
    const neatolabsCost = echingCount > 0 ? 5 + (echingCount - 1) : 0;
    
    return { price, echingCount, neatolabsCost, groupCount };
}



// --------------------------------------------------------
// Get discount price based on discount type
// --------------------------------------------------------
const getDiscountPrice = (pricing) => {
    const discountType = pricing?.discount_type || 'value';
    const discountPrice = {
        "value": () => (pricing.discount),
        "percent": () => (pricing.price * pricing.discount / 100)
    }
    return discountPrice[discountType]();
}



// --------------------------------------------------------
// Calculate price of each section by price model
// --------------------------------------------------------
const getPriceFromModel = {
    "section": ({pricing, echingCount, discountPrice}) => {

        const sectionPrice = pricing.price || 0;
        const totalPrice = sectionPrice * echingCount;

        const discount = getDiscountFromModel({
            model: pricing.discount_model,
            count: echingCount,
            discountPrice: discountPrice
        })
        return totalPrice - discount;
    },


    "fixed": ({pricing, echingCount, discountPrice}) => {
        const totalPrice = pricing.price;

        const discount = getDiscountFromModel({
            model: pricing.discount_model,
            count: echingCount,
            discountPrice: discountPrice
        })

        return totalPrice - discount;
    },


    "element": ({pricing, elementCount, discountPrice}) => {
        const elementPrice = pricing.price || 0;
        const totalPrice = elementPrice * elementCount;

        const discount = getDiscountFromModel({
            model: pricing.discount_model,
            count: elementCount,
            discountPrice: discountPrice
        })

        return totalPrice - discount;

    },

    "size": ({pricing}) => {
        return pricing.price;
    },
}



// --------------------------------------------------------
// Calculate discount by discount model
// --------------------------------------------------------
const getDiscountFromModel = ({model, count, discountPrice}) => {

    const discountModelList = {
        "none": () => {
            return 0
        },
        "fixed": () => {
            return count * discountPrice
        },
        "discount_on_addition": () => {
            return (count > 1 ? discountPrice * (count - 1) : 0)
        }
    }

    return discountModelList[model]()
}