import store from "../../../store";
import {snapshot} from "valtio";


export default async function mergeImages(sectionId, increaseQuality = 8) {
    // Create a new canvas element
    const cachedImages = window.imageCache;
    const snap = snapshot(store);
    const section = snap.sections.find(e => e.section_id === sectionId);
    const elements = snap.elements.filter(e => e.sectionId === sectionId);
    
    const canvas = document.getElementById('sectionCanvas-' + sectionId);
    canvas.width = section.width * increaseQuality;
    canvas.height = section.height * increaseQuality;
    const ctx = canvas.getContext('2d');
    
    if (elements.length === 0) {
        return canvas;
    }
    
    await Promise.all(elements.map(async (element) => {
        const cachedImage = cachedImages.get(element.elementId);
        if (cachedImage) {
            await drawCachedImage(ctx, cachedImage, element, section, increaseQuality);
        }
    }));
    
    return canvas;
}



async function drawCachedImage(ctx, img, element, section, increaseQuality) {
    ctx.save();
    
    // Set the origin to the image center for rotation
    const x =
        (element.offset_x - section.offset_x + section.width / 2) * increaseQuality;
    const y =
        (section.height - (element.offset_y - section.offset_Y + section.height / 2)) *
        increaseQuality;
    
    ctx.translate(x, y);
    ctx.rotate(((element.angle * Math.PI) / 180) * -1); // Convert angle to radians
    
    // Draw the image at its center
    ctx.drawImage(
        img,
        (-element.width / 2) * increaseQuality,
        (-element.height / 2) * increaseQuality,
        element.width * increaseQuality,
        element.height * increaseQuality
    );
    
    ctx.restore();
    return true
}


