import * as THREE from 'three'


import {RectAreaLightUniformsLib} from 'three/addons/lights/RectAreaLightUniformsLib.js';
import {isMobile} from 'react-device-detect';
import { throttle } from 'lodash';
import pointerDown from "./events/handlers/pointerDown";
import pointerMove from "./events/handlers/pointerMove";
import pointerUp from "./events/handlers/pointerUp";

import controlUpdate from "./events/handlers/controlUpdate";
import CameraControls from 'camera-controls';
import disposeThreeObject from "./disposeThreeObject";

CameraControls.install({THREE: THREE});
const clock = new THREE.Clock();


const mouseVector2Reusable = new THREE.Vector2()
const mouseDownVector2Reusable = new THREE.Vector2()

class ThreeViewer {
    
    constructor(params) {
        /**
         * This class is a wrapper for Three.js
         * @param {HTMLElement} params.element
         * @param {string} params.bgColor
         */
        
        this.params = params
        this.element = params.element
        this.isMobile = isMobile
        
        // Create width and height properties on the element
        this.clientWidth = params.element.clientWidth
        this.clientHeight = params.element.clientHeight
        
        // Mouse
        this.mouse = mouseVector2Reusable;
        this.downMouse = mouseDownVector2Reusable;
        this.intersection = {}
        this.isPinching = false // This is for mobile devices. This is used to check if the user is pinching or not.
        
        this.selectedSection = null
        this.selectedElement = null
        
        this.touchCount = 0
        
        this.throttledPointerMove = throttle(pointerMove.bind(this), 50);
        this.throttledWindowResize = throttle(this.setWindowResize.bind(this), 500);
        this.cleanScene = this.cleanScene.bind(this)
        this.haveToCleanFromScene = []
        
    }
    
    
    addDummySketchPlane(size) {
        
        // Assuming size.z and size.x are defined elsewhere and this.productSize.z is defined
        const zSize = Math.min(size.z, size.x);

        // Check if the dummySketchPlane already exists and dispose of its resources before creating a new one
        if (this.dummySketchPlane) {
            if (this.dummySketchPlane.geometry) this.dummySketchPlane.geometry.dispose();
            if (this.dummySketchPlane.material) this.dummySketchPlane.material.dispose();
            this.scene.remove(this.dummySketchPlane); // Remove the previous plane from the scene
        }

        // Create the geometry and mesh only if necessary
        const geometry = new THREE.BoxGeometry(2, 2, zSize);
        const material = new THREE.MeshBasicMaterial({ visible: false }); // Material can be basic as the plane is invisible
        let plane = new THREE.Mesh(geometry, material);
        
        plane.euler = new THREE.Euler(0, 0, this.productSize.z);
        plane.type = "dummySketchPlane";
        plane.name = "dummySketchPlane";
        plane.position.set(0, 0, 0); // Condensed position setting
        plane.layers.set(6); // 6 is the layer for the dummySketchPlane
        plane.visible = false; // Set to true if you want to see the dummy plane
        
        this.scene.add(plane);
        this.dummySketchPlane = plane;
        //console.log("Dummy sketch plane added to the decal.");
    }
    
    
    updateLightPosition() {
        // Update rect area light
        const quaternion = this.camera.quaternion//.clone();
        const euler = new THREE.Euler().setFromQuaternion(quaternion, 'YXZ');
        
        this.scene.rotation.y = -euler.y + window.leftP
        
        let cameraPos = this.camera.position.clone()
        let plot2DVector = new THREE.Vector2(cameraPos.x, cameraPos.z)
        
        const isLight = window.productColorObject.isLight
        
        let lightContrast = window.productColorObject.lightContrast
        lightContrast = lightContrast > 0.3 ? 0.3 : lightContrast

        plot2DVector.setLength(2)
        
        if (this.ambientLight) {
            this.ambientLight.position.x = plot2DVector.x
            this.ambientLight.position.z = plot2DVector.y
            this.ambientLight.position.y = 10
        }


        
        let cameraDirection = new THREE.Vector3();
        this.camera.getWorldDirection(cameraDirection);
        
        
        
        const rotationMatrix = new THREE.Matrix4().makeRotationFromEuler(euler);
        if (this.keyLight) {
            const initialPosition = new THREE.Vector3(0, 7, 10);
            const rotatedPosition = initialPosition.applyMatrix4(rotationMatrix);
            this.keyLight.position.copy(rotatedPosition);
            this.keyLight.lookAt(0, 0, 0);
            this.keyLight.intensity = 35 * (lightContrast)
        }
        
        
        if (this.fillLight) {
            const initialPosition = new THREE.Vector3(-10, 4, 10);
            const rotatedPosition = initialPosition.applyMatrix4(rotationMatrix);
            this.fillLight.position.copy(rotatedPosition);
            this.fillLight.lookAt(0, 0, 0);
        }
        
       
        if (this.rimLight) {
            const initialPosition = new THREE.Vector3(0, -30, -5);
            const rotatedPosition = initialPosition.applyMatrix4(rotationMatrix);
            this.rimLight.position.copy(rotatedPosition);
            this.rimLight.lookAt(0, 0, 0);
            const intensity = (1- (euler.x)*100)/14;
            this.rimLight.intensity =  intensity * lightContrast * 4.5
                //(1- (euler.x)*100)/14;
        }
        
        
        
        
    }
    
    cleanScene() {
        if (this.haveToCleanFromScene?.length > 0) {
            this.haveToCleanFromScene.forEach((object) => {
                disposeThreeObject(object);
                this.scene.remove(object);
            });
            this.haveToCleanFromScene = [];
            this.renderer.renderLists.dispose();
        }
    }
    
    
    initCamera() {
        if (!this.camera) {
            this.camera = new THREE.PerspectiveCamera(20, this.clientWidth / this.clientHeight, 0.01, 100)
            this.camera.position.set(0, 0, 0.5)
            this.camera.layers.enableAll()
            this.scene.add(this.camera)
        }
    }
    
    initLights() {
        // Ambient light to provide general illumination
        this.ambientLight = new THREE.AmbientLight(0xffffff, 0.6);
        this.scene.add(this.ambientLight);
        
        // Key light
        this.keyLight = new THREE.DirectionalLight(0xffffff, 7);
        this.keyLight.position.set(10, 10, 10);
        this.scene.add(this.keyLight);
        
        // Fill light
        this.fillLight = new THREE.DirectionalLight(0xffffff, 0.5);
        this.fillLight.position.set(-10, 5, 10);
        this.scene.add(this.fillLight);
        
        // Rim light (bottom light)
        this.rimLight = new THREE.DirectionalLight(0xffffff, 0);
        this.rimLight.position.set(0, 20, -10);
        this.scene.add(this.rimLight);
        
        
        
        
    }
    initViewer() {
        
        // Initialize the RectAreaLightUniformsLib
        RectAreaLightUniformsLib.init()
        
        // Create scene, camera and renderer
        this.scene = new THREE.Scene()
        
        // Create a camera and set its position then add it to the scene
        this.initCamera()
        
        // Create lights and add them to the scene
        this.initLights()

        
        this.renderer = new THREE.WebGLRenderer({
            antialias: true,
            alpha: false,
        })
        this.renderer.outputEncoding = THREE.sRGBEncoding;
        this.renderer.shadowMap.enabled = true
        this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
        
        this.setWindowResize()
        this.setUpCameraControls()
        
        this.element.appendChild(this.renderer.domElement)
        
        window.addEventListener('resize', this.throttledWindowResize, false);
        
        if (this.isMobile) {
            this.renderer.domElement.addEventListener('touchend', pointerUp)
            document.body.addEventListener('touchmove', this.throttledPointerMove)
            document.body.addEventListener('touchstart', pointerDown);
            
        } else {
            document.body.addEventListener('pointermove', this.throttledPointerMove);
            this.renderer.domElement.addEventListener('pointerdown', pointerDown);
            document.body.addEventListener('pointerup', pointerUp);
        }
        
        
        this.controls.addEventListener('update', () => {
            const quaternion = this.camera.quaternion.clone();
            const euler = new THREE.Euler().setFromQuaternion(quaternion, 'YXZ');
            this.dummySketchPlane.rotation.y = euler.y
            this.updateLightPosition()
        })
        
        
        this.controls.addEventListener('control', controlUpdate)
        this.controls.addEventListener('controlstart', () => { this.controlStartByUser = true})
        this.controls.addEventListener('controlend', (event) => {
            this.controlStartByUser = false
            controlUpdate(event)
        })
        
        this.animate()
    }
    
    setUpCameraControls() {
        // Create controls
        this.controls = new CameraControls(this.camera, this.renderer.domElement)
        
        // Set up controls bounds
        this.controls.minPolarAngle = Math.PI * 0.2;
        this.controls.maxPolarAngle = Math.PI * 0.5//Math.PI * 0.5;
        this.controls.minDistance = 0.4;
        this.controls.maxDistance = 1.5;
        
        // Set up controls speed
        this.controls.azimuthRotateSpeed = this.isMobile ? 2.5 : 2
        this.controls.polarRotateSpeed = this.isMobile ? 2 : 1
        this.controls.dollySpeed = this.isMobile ? 4 : 2
        this.controls.draggingSmoothTime = 0
        
        // Set up controls actions for mouse and touch
        this.controls.mouseButtons.wheel = CameraControls.ACTION.DOLLY
        this.controls.mouseButtons.left = CameraControls.ACTION.ROTATE
        this.controls.mouseButtons.right = CameraControls.ACTION.ROTATE
        this.controls.touches.two = CameraControls.ACTION.TOUCH_DOLLY
        this.controls.touches.three = CameraControls.ACTION.TOUCH_DOLLY_ROTATE
        this.controls.touches.one = CameraControls.ACTION.ROTATE
        
        this.controls.enableZoom = true;
        this.controls.touches.TWO = "ACTION.TOUCH_ZOOM"//THREE.TOUCH.DOLLY_ROTATE;  // Two-finger touch does zoom and pan
    }
    
    
    setWindowResize() {
        this.clientWidth = this.element.clientWidth
        this.clientHeight = this.element.clientHeight
        this.camera.aspect = this.clientWidth / this.clientHeight;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(this.clientWidth, this.clientHeight);
    }
    
    render() {
        const delta = clock.getDelta();
        this.controls.update(delta);
        
        if (this.composer) {
            this.composer.render();
        } else {
            this.renderer.render(this.scene, this.camera);
        }
    }
    
    animate() {
        // Schedule the next frame at the beginning
        this.animationFrameId = requestAnimationFrame(this.animate.bind(this));
        //console.log('animate')
        if (this.scene && this.camera) {
            // Perform rendering operations
            this.animatePerformDepthRendering();
            //this.blurShadow(this.blur * 0.4);
            this.animateResetAndRenderScene();
        }
    }
    
    animatePerformDepthRendering() {
        // Render to the render target to get the depths
        //this.scene.overrideMaterial = this.depthMaterial;
        this.renderer.setClearAlpha(0);
        //this.renderer.setRenderTarget(this.renderTarget);
        this.scene.overrideMaterial = null;
    }
    
    animateResetAndRenderScene() {
        // Reset and render the normal scene
        this.renderer.setRenderTarget(null);
        this.renderer.render(this.scene, this.camera);
        this.render();
    }
    
   
}

export {ThreeViewer};
