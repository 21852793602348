import fontList from "../util/internal/font.json";


export default async function getFontFace(family, style) {
    //console.log("family, style", family, style)
    const url = process.env.REACT_APP_GCS_BUCKET_PATH + fontList.find(item => item?.fontFamily === family)?.fontStyle?.find(item => item?.style === style)?.url;
    const name = `${family}-${style}`;
    
    // Wait for document.fonts.ready to be resolved
    await document.fonts.ready;
    
    const currentFontList = [];
    document.fonts.forEach((fontFace) => {
        currentFontList.push(fontFace.family);
    });
    
    if (currentFontList.includes(name)) {
        
        return name;
    } else {
        const fontFace = new FontFace(name, `url(${url})`);
        await fontFace.load();  // Ensure the font is fully loaded before adding
        document.fonts.add(fontFace);
        return name;
    }
}