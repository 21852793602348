import cacheImages from "../svg/cacheImages";
import {v4 as uuidv4} from "uuid";
import convertToBlobUrl from "../utils/imageUtils/convertToBlobUrl";

export default async function startModificationForSaveDesign(section) {
    
    const newElementList = [];
    
        const sectionPromises = section.elements.map(async (element) => {
            const newElement = {...element}; // Creating a shallow copy
            newElement.elementId = uuidv4();
            const image = await convertToBlobUrl(element.image);
            await cacheImages(image.blob, newElement.elementId);
            newElement.image = image.url;
            if (element.type === "custom") {
                const rawImage = await convertToBlobUrl(element.custom.rawImage);
                newElement.custom.rawImage = rawImage.url;
            }
            return newElement;
        });

        const sectionElements = await Promise.all(sectionPromises);
        newElementList.push(...sectionElements);
    
    return newElementList;
}


