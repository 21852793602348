import React from 'react';


const Input = ({item, value, validationMessage, handleChange, handleFocus, handleBlur, handleKeyPress, defaultValue, formData}) => {
    
    const disabled = typeof item.disabled === "function" ? item.disabled(formData) : item.disabled || false;
    const inputValue = value || defaultValue || "";
    // Return Input in view
    return (
        <>
            <input
                name={item.name}
                type={item.type}
                placeholder={item.placeholder}
                disabled={ disabled }
                className={`transition ${item.disabled && "disabled"}  disabled:text-gray-400 rounded-[5px] px-[17px] h-[45px] border-gray-300 border hover:border-black focus:outline-none focus:bg-gray-100 focus:border-black  block w-full ${item.innerClassName || ""} ${validationMessage ? 'border-red-500 border-2' : ''}`}
                value={inputValue}
                autoComplete="off"
                onChange={(e) => {
                    if (handleChange) handleChange(e.target.value, item.name)
                }}
                onFocus={(e) => {
                    if (handleFocus) handleFocus(e.target.value, item.name)
                }}
                onBlur={(e) => {
                    if (handleBlur) handleBlur(e.target.value, item.name)
                }}
                onKeyPress={(e) => {
                    if (handleKeyPress) handleKeyPress(e)
                }}
                autoFocus={item?.autoFocus}
            />
        </>
        
    
    )
}


export default Input;