import React, {useEffect, useRef, useState} from 'react'


const CustomSlider = ({min, max, change, defaultValue, updateOnMouseUp, suggestion}) => {
    const innerMax = max + 10
    const innerMin = min + 10
    const [value, setValue] = useState(innerMin)
    const [isDragging, setIsDragging] = useState(false)
    const sliderRef = useRef(null)
    const isMobile = window.threeViewer?.isMobile
    useEffect(() => {
        setValue(Number(defaultValue) + 10)
    }, [defaultValue])
    
    const updateValue = (clientX, dragging) => {
        const rect = sliderRef.current.getBoundingClientRect()
        const x = clientX - rect.left
        let newValue = innerMin + (x / rect.width) * (innerMax - innerMin)
        if (newValue < innerMin) newValue = innerMin
        if (newValue > innerMax) newValue = innerMax
        
        if (newValue) {
            setValue(newValue)
            change(String(newValue - 10), dragging)
        }
        
        
    }
    
    const handleMouseDown = (event) => {
        document.body.className = "touched"
        setIsDragging(true)
        
        if (event?.touches?.length > 0) {
            updateValue(event?.touches?.[0]?.clientX, true)
        } else {
            updateValue(event.clientX, true)
        }
    }
    
    const handleMouseMove = (event) => {
        if (!isDragging) return
        if (event?.touches?.length > 0) {
            updateValue(event.touches[0].clientX, true)
        } else {
            updateValue(event.clientX, true)
        }
    }
    
    const handleMouseUp = (event) => {
        document.body.className = ""
        if (updateOnMouseUp) {
            if (event?.changedTouches?.length > 0) {
                const val = event?.changedTouches?.[0]?.clientX || event?.touches?.[0]?.clientX || value
                updateValue(val, false)
            } else {
                updateValue(event.clientX, false)
            }
        }
        setIsDragging(false)
    }
    const handleMouseLeave = () => {
        setIsDragging(false)
    }
    
    return (
        <div className={'slider_container'}>
            <div className={'slider_line '}/>
            
            <div className={'slider_pointer'}>
                <div
                    className="slider_background"
                    ref={sliderRef}
                    onMouseDown={(e) => {
                        if (!isMobile) handleMouseDown(e)
                    }}
                    onMouseMove={(e) => {
                        if (!isMobile) handleMouseMove(e)
                    }}
                    onMouseUp={(e) => {
                        if (!isMobile) handleMouseUp(e)
                    }}
                    onMouseLeave={(e) => {
                        if (!isMobile) handleMouseLeave(e)
                    }}
                    
                    onTouchStart={handleMouseDown}
                    onTouchMove={handleMouseMove}
                    onTouchEnd={handleMouseUp}
                >
                    {suggestion && (
                        <div
                            className={'slider_suggestion'}
                            style={{
                                width: `${(((suggestion.max - suggestion.min) + innerMin) * 100) / (innerMax - innerMin)}%`,
                                left: `${((suggestion.min - innerMin) * 100) / (innerMax - innerMin)}%`,
                            }}
                        />
                    )}
                   
                    
                    <div
                        className="slider_progress"
                        style={{width: `${((value - innerMin) / (innerMax - innerMin)) * 100}%`}}
                    />
                    
                    <div
                        className="slider_circle"
                        style={{
                            left: `${((value - innerMin) / (innerMax - innerMin)) * 100}%`,
                            transform: 'translateX(-50%)',
                        }}
                    />
                    
                </div>
            </div>
        </div>
    )
}

export default CustomSlider
