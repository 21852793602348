import {v4 as uuidv4} from 'uuid';
import store from "../../store";
import getProductBySKU from "../../services/getProductBySKU";
import mapProductData from "./mapProductData";
import mapOptionList from "./mapOptionList";
import loadVariant from "./loadVariant";
import startModification from "../internal/sections/startModification";
import priceCalculator from "../order/priceCalculator";
import getShareDesign from "../../services/getShareDesign";



export default async function getProduct() {


    const url = new URL(window.location.href);
    const queryParams = new URLSearchParams(url.search);
    const storeName = queryParams.get('store_name')
    const sku = queryParams.get('sku')
    const development = queryParams.get('development')
    const shareId = queryParams.get('shareId')
    

    const productBySKU = await getProductBySKU(sku, storeName)

    const variantListFromApi = mapProductData(productBySKU);
    const setStore = (list) => {

        store.variantsOptions = mapOptionList(list);
        store.variants = list;
    }

    if (development) {

        setStore(variantListFromApi);
        await loadVariant(sku);
        return;
    }
    
    

    const handleMessage = async (msg) => {
        const data = msg?.data
        if (data.hasOwnProperty('product-data') && typeof data?.['product-data'] === 'object') {


            const productData = data['product-data'];
            const ProductVariants = productData?.variants;

            
            const list = ProductVariants.map(variant => {
                const mappedVariant = variantListFromApi?.find(item => item.sku === variant.sku);
                return {
                    ...mappedVariant,
                    ...variant
                }
            });

            setStore(list);
            //window.getStore();


            if (productData?.["modification_data"]) {
                await startModification(productData?.["modification_data"])
            }


            if (shareId) {
                const modification_data = await getShareDesign({storeName, shareId, sku})
                modification_data['_data'] = modification_data['section_string']
                await startModification(modification_data)
            }


            await loadVariant(sku);
            store.order = {
                modification_id: productData?.["modification_id"] || uuidv4(),
                isModified: !!productData?.["modification_id"],
                store_name: storeName,

                eching_sections: "0",
                neatolabsCost: "0",
                quantity: Number(productData?.["quantity"]),

                product_id: productData?.["id"],
                variant_id: productData?.["variant_id"]
            };
            store.storeUrl = data?.storeUrl
            priceCalculator();
        }


    }
    window.removeEventListener('message', handleMessage)
    window.addEventListener('message', handleMessage)
    window.parent.postMessage({"product-data": true}, "*");
}