import {useEffect, useState} from "react";
import store from "../store";
import {snapshot, useSnapshot} from "valtio";

import PriceCalculator from "./order/priceCalculator";
import animateTo from "./internal/sections/util/animateTo";
import CameraControls from 'camera-controls';

import updateThreeViewerByProduct from "./product/updateThreeViewerByProduct";
import priceCalculator from "./order/priceCalculator";
import animateValue from "./internal/utils/animateValue";

const useAppControl = () => {
    const [selectedSectionId, setSelectedSectionId] = useState(null);
    const [selectedElementId, setSelectedElementId] = useState(null);
    
    const snap = useSnapshot(store);
    
    

    
    
    // Handle section selection updates
    useEffect(() => {
        
        async function updateSection() {
            
                //console.log("--", snap.selectedSectionId, snap.selectedProductSKU, snap.sections, selectedSectionId, window?.threeViewer?.scene?.children)
                
                const selectedProductSKU = snap.selectedProductSKU;
                const selectedSection = snap.sections.find((s) => s.section_id === snap.selectedSectionId);
                const scene = window?.threeViewer?.scene?.children;
                
                let changeProduct = false;
                
                
                const checkProductSKU = async () => {
                    
                    if (selectedProductSKU && selectedProductSKU !== selectedSection?.product_sku) {
                        
                        await updateThreeViewerByProduct(selectedSection.product_sku);
                        changeProduct = true;
                        return true
                    } else {
                        return false
                    }
                }
                await checkProductSKU();
                
                
                const lastSection = scene?.find((s) => s.userData.id === selectedSectionId);
                if (lastSection) {
                    lastSection?.helper?.hide();
                }
                
                store.selectedElementId = null;
                setSelectedSectionId(snap.selectedSectionId); // Update state to reflect the new selected section
                setSelectedElementId(null); // Reset the selected element
                
                await animateTo(snap.selectedSectionId, {
                    fit: false,
                    current: false,
                    duration: changeProduct ? 0 : 0.25
                });
                
                if (window.threeViewer.newProduct) {
                    const canvas = window.threeViewer.renderer.domElement;
                    await animateValue(-0.1, 0, 400, (value) => {
                        window.threeViewer.scene.position.x = value;
                        canvas.style.opacity = 1- (value* -1) * 10;
                    }, "easeOutCubic")
                }
                
                
                store.editElement = null;
                store.ui.addModalVisibility = false;
            
        }
        if (snap.selectedSectionId !== selectedSectionId && snap.selectedSectionId) {
            updateSection();
        }
    }, [snap.selectedSectionId, snap.selectedProductId, snap.sections, selectedSectionId, setSelectedSectionId, setSelectedElementId]);
    
    
    // Handle section zoom updates
    useEffect(() => {
        const fit = !!snap.ui.zoom
        
        const options = {
            paddingTop: 0.01,
            paddingBottom: fit ? 0.05 : 0.07,
            paddingLeft: 0.01,
            paddingRight: 0.01,
        }
        if (snap.ui.zoom) {
            let current = false
            if (snap.ui.zoom === snap.selectedSectionId) current = true
            animateTo(snap.ui.zoom, {
                fit: fit,
                options: {
                    ...options
                },
                current: true
            })
            store.selectedSectionId = snap.ui.zoom
        }
    }, [snap.ui.zoom])
    
    
    // Handle element selection updates
    useEffect(() => {
        if (snap.selectedElementId !== selectedElementId && snap.selectedSectionId === selectedSectionId) {
            const scene = window?.threeViewer?.scene?.children
            
            const section = scene.find((s) => s.userData?.id === selectedSectionId);
            
            const snapLastElement = snapshot(store)
            const element = snapLastElement.elements.find((e) => e.elementId === snap.selectedElementId)//snap.elements.find(e => e.elementId === snap.selectedElementId)
            //const element = section?.children?.find((e) => e.userData?.id === snap.selectedElementId && e.type === "element");
            
            
            if (element) {
                //element.updateTime()
                // update element helper
                section.helper.show(element)
                animateTo(snap.selectedSectionId, {
                    fit: window.threeViewer.fitToSection,//!!snap.ui.zoom//!!selectedSectionId
                    current: false,
                })
            } else {
                // hide element helper
                section.helper.hide()
            }
            setSelectedElementId(snap.selectedElementId); // Update state to reflect the new selected element
            
            // Calculate price by changing the scene objects
            PriceCalculator();
        }
        
    }, [snap.selectedElementId]);
    
    
    // Calculate price for group list customization
    useEffect(() => {
        if (snap.editElement) {
            const type = snap.editElement.type
            if (snap.editElement[type]?.list) {
                priceCalculator();
            }
        }
        
    }, [snap.editElement]);
    
    
    // handle full screen mode
    useEffect(() => {
        const scene = window?.threeViewer?.scene?.children
        const sectionList = scene?.filter((s) => s.type === "section");
        const controls = window?.threeViewer?.controls;
        
        if (sectionList?.length) {
            if (snap.ui.fullScreen) {
                sectionList.forEach((s) => {
                    const helper = s.children.find((e) => e.type === "decalHelper");
                    if (helper) helper.hideHelper();
                    s.material.visible = false
                    store.selectedElementId = null
                    store.editElement = null
                })
                controls.touches.two = CameraControls.ACTION.TOUCH_DOLLY_OFFSET
                controls.mouseButtons.right = CameraControls.ACTION.OFFSET
                controls.saveState()
            } else {
                sectionList.forEach((s) => {
                    s.material.visible = true
                })
                controls.touches.two = CameraControls.ACTION.TOUCH_DOLLY
                controls.mouseButtons.right = CameraControls.ACTION.ROTATE
                controls.reset(true)
            }
        }
    }, [snap.ui.fullScreen]);
    
    
}

export default useAppControl;