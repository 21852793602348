import createCssWithMediaQuery from "./createCssWithMediaQuery";

export default function createCss (css, tabletCss, mobileCss) {
    const joinedCss = css.join(" \n");
    const joinedMobileCss = mobileCss.join(" \n")
    const joinedTabletCss = tabletCss.join(" \n")
    
    
    return`${joinedCss} \n${createCssWithMediaQuery(joinedTabletCss, "tablet")} \n${createCssWithMediaQuery(joinedMobileCss, "mobile")}`;
    
}