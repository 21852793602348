import svgpath from 'svgpath';
import { polygonHull } from 'd3-polygon';
import pathBounds from "../../svg/pathBounds";

function convertPathToHullSVG(pathData) {
    
    
    const path = svgpath(pathData);
    const points = [];
    
    path.iterate((segment, index, x, y) => {
        if (index !== 0) points.push([x, y]);
    });
    
    
    const hullPoints = polygonHull(points);
    if (!hullPoints) {
        return pathData; // Return original path data if hull can't be computed
    }
    
    // Create SVG path data from the convex hull points
    let hullPathData = 'M' + hullPoints.map(point => point.join(',')).join('L') + 'Z';
    
    const bounds = pathBounds(hullPathData);
    hullPathData = svgpath(hullPathData).translate(-bounds[0], -bounds[1]).rel().toString()
    return hullPathData;
}

export default convertPathToHullSVG;
