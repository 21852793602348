import store from "../../../store";
import {snapshot} from "valtio";
import cacheImages from "../svg/cacheImages";
import priceCalculator from "../../order/priceCalculator";


export default async function startModification(modificationData) {
    //const snap = snapshot(store);
    //const isModified = snap?.order?.isModified;
    
    /*if (isModified) {
        window.parent.postMessage({modification:true}, "*");
    } else {
        store.status.loading = false;
    }*/
    //async function handleMessage(event) {
        //const modificationData = event.data
        //if (modificationData.hasOwnProperty('modification-data')) {
            console.log('modificationData', modificationData)
            const data = JSON.parse(modificationData["_data"]);
            
            const newElementList = [];
            
            for (const section of data?.sections || []) {
                const sectionPromises = section.elements.map(async (element) => {
                    const newElement = {...element}; // Creating a shallow copy
                    
                    const image = await convertToBlobUrl(element.image);
                    await cacheImages(image.blob, element.elementId);
                    newElement.image = image.url;
                    if (element.type === "custom") {
                        const rawImage = await convertToBlobUrl(element.custom.rawImage);
                        newElement.custom.rawImage = rawImage.url;
                    }
                    
                    return newElement;
                });
                
                const sectionElements = await Promise.all(sectionPromises);
                newElementList.push(...sectionElements);
                
            }
            
            store.elements = newElementList;
           
            /*sectionList.forEach(section => {
                section.update()
            })*/
            //store.status.loading = false;
        //}
    //}
    //window.removeEventListener('message', handleMessage)
    //window.addEventListener('message', handleMessage)
}


async function convertToBlobUrl(imageUrl) {
    try {
        const response = await fetch(imageUrl);
        if (!response.ok) {
            throw new Error('Network response was not ok.');
        }
        const imageBlob = await response.blob();
        return {
            url: URL.createObjectURL(imageBlob),
            blob: imageBlob
        };
    } catch (error) {
        console.error('Error fetching and converting the image:', error);
        return null;
    }
}