export default function findDefaultValues (variants, variantsOptions, sku) {
    
    // Check if the variant is a bundle or not to find the default values
    const variant = variants.find(variant => {
        if (variant.is_bundle) {
            return variant.bundle_items.find(item => item.sku === sku);
        } else {
            return variant.sku === sku;
        }
    });
    
    // Initialize the default values object
    const defaultValues = {};
    
    // Iterate through the variant options to find the default values
    Object.keys(variantsOptions)?.forEach((option, index) => {
        defaultValues[option] = variant?.options[option].label;
    })
    
    return defaultValues;
}