import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'

const dracoLoader = new DRACOLoader();
const loader = new GLTFLoader();

export function loadGLB(inPath) {
    return new Promise((res, rej) => {
        
        dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');
        loader.setDRACOLoader(dracoLoader);
        
        loader.load(inPath, //"http://127.0.0.1:3000/assets/m.glb"
            function (gltf) {
                gltf.scene.traverse(function (child) {
                    if (child.isMesh) {
                        child.material.transparent = true
                    }
                });
                gltf.scene.name = "Neatolabs"
                gltf.scene.traverse((node) => {
                    if (node.isMesh) {
                        node.castShadow = true;  // Enable casting shadows
                        //node.receiveShadow = true; // Enable receiving shadows
                    }
                });
                res(gltf.scene);
            },

            function (xhr) { },
            function (error) {
                res(error)
            }
        );
    })
}
