import React, { memo, useEffect, useState } from 'react';
import store from "../../store";
import { useSnapshot } from "valtio";
import animateValue from "../../util/internal/utils/animateValue";


const Splash = () => {
    const snap = useSnapshot(store);
    const isLoading = snap?.status.loading;
    
    const [visible, setVisible] = useState(isLoading); // State to control visibility of the component
    const [opacity, setOpacity] = useState(0); // Start with opacity 0
    
    useEffect(() => {
        if (isLoading) {
            setVisible(true); // Make splash screen visible
            animateValue(0, 1, 100, (currentValue) => setOpacity(currentValue)); // Animate opacity from 0 to 1 (fade-in)
        } else {
            // Fade out the splash screen
            animateValue(1, 0, 200, (currentValue) => setOpacity(currentValue)).then(() => {
                setVisible(false); // Set visible to false after fade-out animation
            });
        }
    }, [isLoading]);
    
    // Return null if the splash screen should not be visible
    if (!visible) return null;
    
    const loadingStyle = snap.ui.loadingStyle ? JSON.parse(JSON.stringify(snap.ui.loadingStyle)) : {};
    let style = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        position: "fixed",
        ...loadingStyle,
        opacity: opacity, // Control opacity with state
        transition: "opacity 0.2s ease-out", // Smooth fade-out (fade-in handled by animateValue)
    };
    
    return (
        <div id="component_splash" style={style}>
            <img src="./assets/img/icons/loading-spinner.svg" alt="Loading spinner" />
        </div>
    );
}

export default memo(Splash);
