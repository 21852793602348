import {loadGLB} from './loadGLB'
import * as THREE from "three";
import getColorObjectOfLargestChild from './utils/getColorObjectOfLargestChild'
import steelMaterial from "./utils/steelMaterial";
import store from "../../store";
import {snapshot} from "valtio";
import initialSections from "./sections/initialSections";
import animateValue from "./utils/animateValue";

export default async function loadProductIntoScene(sku)  {
    
    const snap = snapshot(store);
    const productURL = snap.product?.find((product) => product.sku === sku)?.["3d_image_url"];
    const threeViewer = window.threeViewer
    const canvas = threeViewer.renderer.domElement;
    const newProduct = !threeViewer.product;
    threeViewer.newProduct = newProduct;
    //store.ui.loadingStyle = {background: "none"};
    
    await animateValue(0, 0.05, 300 , (value) => {
        threeViewer.scene.position.x = newProduct ? 0 : value;
        canvas.style.opacity = 1 - (value * 20);
    }, "easeInCubic")
    
    const product = await loadGLB(productURL);
    
    canvas.style.opacity = 0
    product.name = "product";
    product.type = "product";
    product.castShadow = true; // Enable shadow casting
    //product.receiveShadow = true;
    
    if (threeViewer?.product) {
        threeViewer.cleanScene()
        threeViewer.product = null;
    }
    
    //Set product material side to front
    //setAllMaterialSide(product, THREE.FrontSide);
    
    
    // Find the product base color
    const colorObject = await getColorObjectOfLargestChild(product);
    const steelMaterialObject = steelMaterial(colorObject)
    //console.log(".....", colorObject)
    
    
    findLogo(product, steelMaterialObject)
    
    
    
    // Calculate bounding box dimensions
    let box = new THREE.Box3().setFromObject(product);
    let center = box.getCenter(new THREE.Vector3());
    let size = box.getSize(new THREE.Vector3());
    
    threeViewer.productSize = size;
    
    // Set camera distance based on diameter
    let widthX = size.x;
    let widthZ = size.z;
    let maxWidth = Math.max(widthX, widthZ);
    threeViewer.controls.minDistance = maxWidth * 2.5;
    threeViewer.controls.maxDistance = maxWidth * 10;
    
    threeViewer.center = center;
    
    threeViewer.haveToCleanFromScene.push(product)
    threeViewer.scene.add(product);
    threeViewer.addDummySketchPlane(size)
    
    
    
    if (threeViewer.outlinePass) {
        threeViewer.outlinePass.selectedObjects.push(product)
    }
    threeViewer.product = product
    
    
    const productIndex = store.product.findIndex((product) => product.sku === store.selectedProductSKU);
    store.product[productIndex] = {
        ...store.product[productIndex],
        size: size,
        colorObject: product.userData.colorObject,
        center: Math.max(size.x, size.y) / 2,
    };
    
    
    
    store.status.initialized = true;
    
    
    
    
    await initialSections(sku)
    THREE.Cache.clear();
    if (!newProduct) {
        canvas.style.opacity = 0
        window.threeViewer.scene.position.x = -1;
        await animateValue(-0.1, 0, 300, (value) => {
            window.threeViewer.scene.position.x = value;
            canvas.style.opacity = 1- (value* -1) * 10;
        }, "easeOutCubic")
    }
    store.ui.loadingStyle = {};
    
    return product;
    
};





const findLogo = (product, steelMaterialObject) => {
    let logo = product.getObjectByName("logo_main")
    if (logo) {
        logo.layers.set(5) // 5 is the layer which we don't use for raycasting
        let angle = getRadialAngleFromLogo(logo)
        product.rotation.y = angle - Math.PI / 2;
        
        // change logo material
        const steelColor = ['ffffff', 'f5f6f6']
        
        if (steelColor.includes(logo.material.color.getHexString())) {
            
            const newSteel = steelMaterialObject.normal
            
            //logo.material.color = newSteel.color
            logo.material.roughness = newSteel.roughness
            logo.material.metalness = newSteel.metalness
            logo.material.roughnessMap = newSteel.roughnessMap
            logo.material.metalnessMap = newSteel.metalnessMap
            logo.material.normalMap = newSteel.normalMap
            logo.material.needsUpdate = true
            logo.needsUpdate = true
            
            
        }
        
    }
}

function getRadialAngleFromLogo(logo) {
    logo.updateWorldMatrix(true, true)
    // BBox
    let box = new THREE.Box3().setFromObject(logo);
    let center = box.getCenter(new THREE.Vector3());
    let center2d = new THREE.Vector2(center.x, center.z)
    center2d.normalize()
    
    // get angle to X axis
    return Math.atan2(center2d.y, center2d.x)
}