import React from "react";

const RadioButton = ({ item, value = "", validationMessage, handleChange }) => {
  const handleUpdate = (option) => {
    handleChange(option, item?.name);
  };

  const isChecked = (option) => {
    return value === option;
  };

  // Return Input in view
  return (
    <div className={"flex gap-2 flex-row"}>
      {item?.list && item?.list?.length > 0 ? (
        item?.list?.map((option, index) => (
          <div className={"flex gap-2 items-center"} key={index}>
            <input
              name={item?.name}
              type={"radio"}
              checked={isChecked(option.value)}
              disabled={item.disabled}
              className={`bg-gray-50 rounded-lg border border-gray-300 ${
                item.disabled && "disabled"
              }  disabled:text-gray-400   ${item.innerClassName || ""} ${
                validationMessage ? "border-red-500 border-2" : ""
              }`}
              onChange={() => {
                handleUpdate(option.value);
              }}
            />
            {/* <span className="flex flex-row gap-1">{option.label}</span> */}
            <div className="flex-col justify-start items-start gap-1 inline-flex">
              <div className="text-gray-900 text-sm font-medium leading-[14px]">
                {option.label}
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className={"text-gray-400"}> No Item!</div>
      )}
    </div>
  );
};

export default RadioButton;
