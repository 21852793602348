import {snapshot} from "valtio";
import store from '../../../store'
import {generateSection} from "./util/generateSection";
import startModification from "./startModification";


const initialSections = async (productSKU, sectionID) => {
    const snap = snapshot(store)
    const selectedProductSKU = productSKU || snap.selectedProductSKU;
    const productList = snap.product;
    const sections = snap.sections;
    const sectionListData = sections.filter(section => section.product_sku === selectedProductSKU);
    const product = productList.find(p => p.sku === selectedProductSKU);
    
    // Check if product has been updated by new SKU
    const isUpdated = snap.elements.filter(e=> e.sectionId !== null).length > 0;
    
    try {
        // Generate sections
        const sectionList = await Promise.all(sectionListData.map(section => generateSection(section, product)));
        
        
        // Add sections to scene
        for (const section of sectionList) {
            window.threeViewer.scene.add(section);
            window.threeViewer.haveToCleanFromScene.push(section);
            await section.update();
        }
        
        
        // Create snapping highlight object
        window.threeViewer.sectionRadialAngleSnapp = sectionList.map(e => ({
            id: e.userData.id,
            angle: e.userData.radialAngle,
            highlight: e.highlight
        }))
        
        // Update store for selected section
        //store.selectedSectionId = sectionList[0]?.userData?.id; //store.selectedSectionId ||
        
        //store.status.loading = false;
        // Update elements list if product has been updated
        if (!isUpdated) {
            //await startModification(sectionList);
        }
    } catch (err) {
        console.error('Error generating sections:', err);
    }
    
}

export default initialSections;