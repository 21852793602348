export default function findNearestElement(x, y, elements, currentSelectedId) {
    let nearestElement = null;
    let minDistance = Infinity;
    
    elements.forEach(element => {
        const leftX = element.offset_x + element.snapping.left//element.offset_x - element.width / 2;
        const rightX = element.offset_x + element.snapping.right//element.offset_x + element.width / 2;
        const topY = element.offset_y + element.snapping.top//element.offset_y - element.height / 2;
        const bottomY = element.offset_y + element.snapping.bottom//element.offset_y + element.height / 2;
        const centerX = element.offset_x;
        const centerY = element.offset_y;
        const distance = Math.hypot(centerX - x, centerY - y);
        
        
        if (x >= leftX && x <= rightX && y >= topY && y <= bottomY) {
            if (distance < minDistance) {
                nearestElement = element;
                minDistance = distance;
            }
        }
    });
    
    
    // If the current selected ID matches an element, always return it.
    const currentSelectedElement = elements.find(e => e.elementId === currentSelectedId);
    if (currentSelectedElement) {
        const leftX = currentSelectedElement.offset_x - currentSelectedElement.width / 2;
        const rightX = currentSelectedElement.offset_x + currentSelectedElement.width / 2;
        const topY = currentSelectedElement.offset_y - currentSelectedElement.height / 2;
        const bottomY = currentSelectedElement.offset_y + currentSelectedElement.height / 2;
        
        if (x >= leftX && x <= rightX && y >= topY && y <= bottomY) {
            return currentSelectedElement;
            
        }
    }
    
    return nearestElement;
}