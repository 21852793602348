import store from "../store";

const getProductBySKU = async (sku, storeName) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_PRODUCT_INFO_API}sku=${sku}&store_name=${storeName}&responseType=variant_selector`);
        return await response.json();
    } catch (err) {
        store.status.error = err;
    }
}

export default getProductBySKU;
