import * as THREE from "three";


export default function rotateSnapping() {
    
    const material = new THREE.MeshBasicMaterial({map: null, transparent: true});
    const geometry = new THREE.CylinderGeometry(0.007, 0.007, 0.0017, 20); //THREE.PlaneGeometry(0.02, 0.01);  // Adjust size as needed
    const numberMesh = new THREE.Mesh(geometry, material);
    numberMesh.rotation.x = Math.PI / 2;
    numberMesh.name = "rotateSnap"
    return numberMesh
}