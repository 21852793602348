import React, {memo, useEffect, lazy, Suspense} from 'react';
import store from '../store';
import {snapshot, useSnapshot} from "valtio";

//import "../css/home.css";
//import "../css/font.css";
//import "../css/assets.css";

//import getProductBySKU from "../services/getProductBySKU";
import getProduct from "../util/product/getProduct";
import initialize3dEnvironment from "../util/initialize3dEnvironment";
import useAppControl from "../util/useAppControl";

import SectionsCanvas from "../components/sectionsCanvas/SectionsCanvas";
import Splash from "../components/splash/Splash";
import getTheme from "../services/getTheme";
import getCompanySetting from "../services/getCompanySetting"
import AddModal from "../components/addElements/AddModal";


//const PerformanceTest = lazy(() => import("../components/performanceTest/PerformanceTest"));
const AlertContainer = lazy(() => import("../components/assets/alert/AlertContainer"));

const ProductInfo = lazy(() => import("../components/productInfo/ProductInfo"));
const Tour = lazy(() => import("../components/tour/Tour"));
const AddElements = lazy(() => import("../components/addElements/AddElements"));
const ToolBar = lazy(() => import("../components/toolBar/ToolBar"));
const EditText = lazy(() => import("../components/editText/EditText"));
const EditTextGroup = lazy(() => import("../components/editListTexts/EditListTexts"));
const EditMonogram = lazy(() => import("../components/editMonogram/EditMonogram"));
const EditGraphic = lazy(() => import("../components/editGraphic/EditGraphic"));

const ControlHint = lazy(() => import("../components/controlHint/ControlHint"));


const Home = memo(() => {
    const snap = useSnapshot(store);
    
    window.getStore = () => {
        const s = snapshot(store);
        return JSON.parse(JSON.stringify(s));
    }
    
    useEffect(() => {
        const initialize = async () => {
            console.log('Start to loading product data');
            await getCompanySetting ();
            //await getTheme();
            await initialize3dEnvironment();
            //await getProductBySKU();
            await getProduct();
        }
        initialize();
    }, []);
    
    
    useAppControl();
    //return ( <div><div>hi</div></div>)
    return (
        <>
            <Suspense fallback={<Splash/>}>
                <div>
                    {/*<PerformanceTest/>*/}
                    <Splash/>
                    {/*{snap?.status.loading && <Splash/>}*/}
                    <AlertContainer/>
                    
                    {snap?.ui.tour && snap?.status.initialized && <Tour/>}
                    
                    {snap?.status.loaded && (
                        <>
                            <ProductInfo/>
                        </>
                    )}
                    <SectionsCanvas/>
                    {snap?.status.initialized && (
                        <>
                            {snap?.editElement?.type === "text" && (<EditText/>)}
                            {snap?.editElement?.type === "monogram" && (<EditMonogram/>)}
                            {snap?.editElement?.type === "listTexts" && (<EditTextGroup/>)}
                            {snap?.editElement?.type === "custom" && (<EditGraphic/>)}
                            
                            
                            
                            
                            <AddElements/>
                            {snap?.ui?.addModalVisibility &&
                                <AddModal/>
                            }
                            <ToolBar/>
                            {snap.product.length > 0 && (
                                <ControlHint/>
                            )}
                            
                        </>
                    )}
                
                
                </div>
            </Suspense>
            
            <div id={"mainCanvas"}>
                <div id={'mainCanvas_preview'}></div>
                <div id={'mainCanvas_flashEffect'} className={`${snap.ui.cameraFlash ? "active": ""}`}></div>
            </div>
            
            {/*<div className={'relative w-[100vw]'}>
                <div id={'mainCanvas'} className={`fixed top-0 left-0 w-[100%] h-[100%]`}></div>
                <div id={'flashEffect'}
                     className={`w-[100vw] h-[100vh] opacity-0 fixed top-0 left-0 bg-white z-[1] transition-all after:bg-white ${snap.ui.cameraFlash ? 'opacity-100' : 'hidden opacity-0'} `}
                     style={{mixBlendMode: "plus-lighter"}}></div>
            </div>*/}
        </>
    );
});

export default Home;
