export default function findAspectRatio(svgCode) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(svgCode, "image/svg+xml");
    const svgElement = doc.querySelector("svg");
    
    if (svgElement) {
        const width = parseFloat(svgElement.getAttribute("width"));
        const height = parseFloat(svgElement.getAttribute("height"));
        return width && height ? width / height : null;
    }
    
    return null;
}
