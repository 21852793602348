import store from "../../../store";
import {snapshot} from "valtio";


export default function deleteElement(elementId) {
    
    const snap = snapshot(store);
    const selectedSectionId = snap.elements.find(e => e.elementId === elementId)?.sectionId || null;
    const ThreeViewerSection = window.threeViewer.scene.children.find(e => e.userData.id === selectedSectionId && e.type === "section");
    
    if (ThreeViewerSection) {
        
        store.elements = snap.elements.filter(e => e.elementId !== elementId)
        store.selectedElementId = null;
        ThreeViewerSection.update()
        ThreeViewerSection.helper.hide()
        
    }
    
}