import {fabric} from 'fabric';
import getFontFace from "../../../services/getFontFace";

async function createTextImage(text, options) {
    return new Promise(async (resolve, reject) => {
        
        const fabricCanvas = new fabric.StaticCanvas(null);
        const padding = options?.fontSize ? options?.fontSize * 0.5 * 20 : 40;
        
        
        const util = {
            fixSize: async (textObject) => {
                const boundingRect = textObject.getBoundingRect();
                fabricCanvas.setWidth(boundingRect.width + boundingRect.left + padding * 2); // Added buffer for text
                fabricCanvas.setHeight(boundingRect.height + boundingRect.top + padding * 2);
                return {
                    width: boundingRect.width + boundingRect.left + padding * 2,
                    height: boundingRect.height + boundingRect.top + padding * 2
                }
            }
        }
        const fontName = await getFontFace(options.fontFamily, options.fontStyle);
        
        // Create text using the registered font name
        const imageText = new fabric.Text(text, {
            textAlign: 'center',
            top: padding * 2,
            left: padding * 2,
            fontSize: options?.fontSize * 20 || 400,
            fontFamily: fontName, // Use the registered font name here
            fill: '#000000',
            ...options?.textOptions || {}
        });
        
        
        // Add text to canvas
        fabricCanvas.add(imageText);
        await util.fixSize(imageText)
        
        
        // Get data URL
        const dataUrl = fabricCanvas.toDataURL({
            format: 'png',
            quality: 1 // Highest quality
        });
        
        
        // Convert data URL to blob
        return await fetch(dataUrl)
        .then(res =>
            resolve(res.blob())
        )
        .catch(error => {
            console.error('Error converting data URL to blob:', error)
            reject(error)
        });
        
        
    })
    
    
}

export default createTextImage;
