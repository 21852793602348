
import {ThreeViewer} from "./internal/ThreeViewer";
import {RGBELoader} from "three/addons/loaders/RGBELoader";
import * as THREE from "three";
import store from "../store";
import initializeKeyboardEvents from "./internal/events/handlers/keyboardHandler";

import { GUI } from 'three/examples/jsm/libs/lil-gui.module.min.js';

const initialize3dEnvironment = async () => {

    
    const container = document.getElementById("mainCanvas_preview");
    store.status.initializing = true;
    
    if (container) {
        
        const threeViewer = new ThreeViewer({
            element: container,
        })
        await threeViewer.initViewer()
        initializeKeyboardEvents()
        
        const rgbLoader = new RGBELoader().setPath('assets/hdr/');
        const envMapTexture = await rgbLoader.loadAsync("marry8.hdr")//'t6.hdr');//marry_hall_1k-saturated
        //const envMapTexture = await rgbLoader.loadAsync('marry_hall_1k-saturated.hdr');
        //envMapTexture.mapping = THREE.EquirectangularReflectionMapping;
        //envMapTexture.backgroundRotation = (10,0,0);
        //envMapTexture.mapping = THREE.EquirectangularRefractionMapping;
        envMapTexture.mapping = THREE.EquirectangularReflectionMapping;
        envMapTexture.encoding = THREE.sRGBEncoding;
        threeViewer.scene.environment = envMapTexture;
        //threeViewer.scene.background = envMapTexture;
        
        
        threeViewer.renderer.autoClear = true;
        
        threeViewer.renderer.toneMapping = THREE.LinearToneMapping;
        threeViewer.renderer.toneMappingExposure = 0.65//1.5;
        
        threeViewer.renderer.shadowMap.enabled = true; // Enable shadow maps
        threeViewer.renderer.shadowMap.type = THREE.PCFSoftShadowMap//.PCFSoftShadowMap;
        threeViewer.renderer.physicallyCorrectLights = true;
        window.threeViewer = threeViewer;
        
        
        window.leftP = 1.2
        
        
        /*const gui = new GUI();
        // Iterate over all objects in the scene
        threeViewer.scene.traverse((object) => {
            if (object.isLight) {
                // Add intensity control
                gui.add(object, 'intensity', 0, 30, 0.01).name(`${object.type} Intensity`);
                
                // Add position controls if the light has a position property
                if (object.position) {
                    const folder = gui.addFolder(`${object.type} Position`);
                    folder.add(object.position, 'x', -30, 30, 0.01).name('X');
                    folder.add(object.position, 'y', -30, 30, 0.01).name('Y');
                    folder.add(object.position, 'z', -30, 30, 0.01).name('Z');
                }
                
                // Add target controls if the light has a target property (e.g., for SpotLight or DirectionalLight)
                if (object.target && object.target.position) {
                    const folder = gui.addFolder(`${object.type} Target`);
                    folder.add(object.target.position, 'x', -30, 30, 0.01).name('Target X');
                    folder.add(object.target.position, 'y', -30, 30, 0.01).name('Target Y');
                    folder.add(object.target.position, 'z', -30, 30, 0.01).name('Target Z');
                }
            }
        });
        
// Add controls for tone mapping exposure
        gui.add(threeViewer.renderer, 'toneMappingExposure', 0, 2, 0.01).name('Exposure');
        gui.add(window, 'leftP', -10, 10, 0.01).name('SL');

// Add controls for tone mapping types
        gui.add(threeViewer.renderer, 'toneMapping', {
            'No Tone Mapping': THREE.NoToneMapping,
            'Linear Tone Mapping': THREE.LinearToneMapping,
            'Reinhard Tone Mapping': THREE.ReinhardToneMapping,
            'Cineon Tone Mapping': THREE.CineonToneMapping,
            'ACES Filmic Tone Mapping': THREE.ACESFilmicToneMapping
        }).name('Tone Mapping');*/
        
    }
}
export default initialize3dEnvironment