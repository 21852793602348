import * as THREE from "three";
import {MathUtils} from "three";

import {GenerateDecal} from "./generateDecal";
import {createTexture} from "./createTexture";

import {DecalHelper} from "./decalHelper";
import {DecalSnapping} from "./decalSnapping";
import {DecalMap} from "./decalMap";

export async function generateSection(section, product) {
    
    // Init variables
    const width = section.width / 1000
    const height = section.height / 1000
    const size = new THREE.Vector3(width, height, 0.1)
    
    const angle = MathUtils.degToRad(section.section_angle)
    const radialAngle = MathUtils.degToRad(section.radial_angle)
    
    const offsetY = section.offset_Y / 1000
    const offsetX = section.offset_x / 1000
    
    const name = section.section_name
    const id = section.section_id
    
    
    // Create section plane
    let plane = new THREE.PlaneGeometry(width, height, 1, 1)
    let material = new THREE.MeshBasicMaterial({
        color: 0xfff,
        side: THREE.DoubleSide,
        transparent: true,
        opacity: 1,
        alphaTest: 0.1
    });
    let sectionPlane = new THREE.Mesh(plane, material)
    
    // Group section plane and rotate it by radial angle
    let group = new THREE.Group()
    group.add(sectionPlane)
    group.rotateY(radialAngle)
    
    // Set position of section plane
    sectionPlane.rotateZ(angle)
    sectionPlane.position.x = offsetX
    sectionPlane.position.y = offsetY
    sectionPlane.position.z = product.center
    
    // Crate texture and material
    let textures = await createTexture(width, height, name)
    
    let texture = textures.normal
    const sectionMaterial = window.productColorObject.materialList.normal.clone()
    sectionMaterial.map = texture
    
    // Generate decal
    let decal = new GenerateDecal({
        size: size,
        material: sectionMaterial,
        plane: sectionPlane,
        type: "section",
        name: name,
        userData: {
            id: id,
            name: name,
            radialAngle: radialAngle,
            angle: angle,
            textures: textures,
            offsetX: section.offset_x,
            offsetY: section.offset_Y,
            width: section.width,
            height: section.height,
            productSize: window.threeViewer.productSize,
        },
        layer: 1,
    })
    
    // Return decal if no error
    if (!decal.error) {
        let decalHelper = new DecalHelper()
        let decalSnapping = new DecalSnapping(decal)
        let decalMap = new DecalMap(decal)
        decal.snapping = {
            show: decalSnapping.show,
            hide: decalSnapping.hide,
        }
        decal.helper = {
            show: decalHelper.showHelper,
            hide: decalHelper.hideHelper,
            mouseUp: decalHelper.mouseUp,
            colorCornerCircles: decalHelper.colorCornerCircles,
        }
        decal.mouseUp = decalHelper.mouseUp
        decal.update = decalMap.update
        decal.add(decalHelper)
        decal.add(decalSnapping)
        decal.add(decalMap)
        //decal.highlight.off(true)
        //decal.highlight.on(0)
        return decal
    } else {
        return null
    }
    
}