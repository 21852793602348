import parse from 'parse-svg-path';
import abs from 'abs-svg-path';
import normalize from 'normalize-svg-path';
import isSvgPath from 'is-svg-path';


export default function pathBounds(path) {
    if (typeof path !== 'string' || !isSvgPath(path)) {
        return [0, 0, 0, 0];
        //throw new Error('Invalid SVG path.');
    }
    
    const parsedPath = normalize(abs(parse(path)));
    if (parsedPath.length === 0) return [0, 0, 0, 0];
    
    let bounds = [Infinity, Infinity, -Infinity, -Infinity];
    
    parsedPath.forEach(segment => {
        for (let i = 1; i < segment.length; i += 2) {
            bounds[0] = Math.min(bounds[0], segment[i]);     // minX
            bounds[1] = Math.min(bounds[1], segment[i + 1]); // minY
            bounds[2] = Math.max(bounds[2], segment[i]);     // maxX
            bounds[3] = Math.max(bounds[3], segment[i + 1]); // maxY
        }
    });
    
    return [...bounds, bounds[2] - bounds[0], bounds[3] - bounds[1]];
}
