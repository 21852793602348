export function makePngFromSvg(svgCode) {
    return new Promise((resolve, reject) => {
        
        const svgImage = new Image();
        svgImage.src = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgCode)}`;
        
        svgImage.onload = () => {
            
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            
            
            canvas.width = svgImage.width// * qualityMultiplier;
            canvas.height = svgImage.height// * qualityMultiplier;
            
            context.drawImage(svgImage, 0, 0, canvas.width, canvas.height);
            canvas.toBlob((blob) => {
                if (blob) {
                    resolve({
                        
                        imgUrl: URL.createObjectURL(blob),
                        blob: blob,
                        width: canvas.width,
                        height: canvas.height,
                        
                    });
                } else {
                    reject(new Error("Canvas to Blob conversion failed"));
                }
            }, 'image/png');
            
        };
        
        svgImage.onerror = (error) => {
            console.error("Error loading SVG", error);
            reject(new Error("Error loading SVG"));
        };
    });
}