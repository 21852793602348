class AlertService {
    constructor() {
        
        this.defaultAlertProps = {
            title: null,
            message: null,
            button: "Ok!",
            cancel: "Cancel",
            className: "",
            confirm: null,
            
            isConfirmed: false,
            isVisible: false,
            
            callBack: async (okBtn) => {
            },
            onConfirm: async (conformation) => {
            }
        };
        this.alertProps = {...this.defaultAlertProps};
        this.listeners = [];
    }
    
    showAlert(props) {
        this.alertProps = {...this.alertProps, ...props, isVisible: true};
        this.notifyListeners();
    }
    
    hideAlert() {
        this.alertProps = {...this.defaultAlertProps, isVisible: false};
        this.notifyListeners();
    }
    
    confirmAlert(conformation = true) {
        this.alertProps = {...this.alertProps, isConfirmed: conformation};
        this.notifyListeners();
    }
    
    registerListener(listener) {
        this.listeners.push(listener);
    }
    
    notifyListeners() {
        this.listeners.forEach(listener => listener(this.alertProps));
    }
}

// Creating a singleton instance
const alertService = new AlertService();
export default alertService;
