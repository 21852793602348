export default async function convertToBlobUrl(imageUrl) {
    try {
        const response = await fetch(imageUrl);
        if (!response.ok) {
            throw new Error('Network response was not ok.');
        }
        const imageBlob = await response.blob();
        return {
            url: URL.createObjectURL(imageBlob),
            blob: imageBlob
        };
    } catch (error) {
        console.error('Error fetching and converting the image:', error);
        return null;
    }
}