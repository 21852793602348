import store from "../../../../store";
import {snapshot} from "valtio";
//import checkElementInsideSection from "../../elements/util/checkElementInsideSection";
//import getBoundingBoxByAngle from "../../svg/getBoundingBoxByAngle";


const restrictElementInsideSection = async (objectTransform, selectedElement = null) => {
    const snap = snapshot(store);
    const elementId = selectedElement?.id || snap.selectedElementId;
    const sectionId = snap.selectedSectionId;

    const sectionData = snap.sections.find(section => section.section_id === sectionId)
    const element = selectedElement || snap.elements.find(element => element.elementId === elementId);
    
    const snapping = element.snapping;
    let transform = objectTransform
    
    
    
    if (objectTransform.offset_x) {
        
        
        const horizontalShift = element.lockMove ? 0 : snapping.width * 0.7//snapping.width - 5//snapping.width * 0.7;
        
        const leftAllowed = 0 - (sectionData.width / 2) - snapping.left - horizontalShift
        const rightAllowed = (sectionData.width / 2)  - snapping.right + horizontalShift
        
        
        if (objectTransform.offset_x < leftAllowed) {
            transform.offset_x = leftAllowed ;
            transform.snappedX.push(0 - (sectionData.width / 2))
        } else if (objectTransform.offset_x > rightAllowed) {
            transform.offset_x = rightAllowed
            transform.snappedX.push(sectionData.width / 2)
        }
    }
    if (objectTransform.offset_y) {
        
        const verticalShift = element.lockMove ? 0 : snapping.height * 0.7//snapping.height - 5//snapping.height * 0.7;
        const topAllowed =  sectionData.offset_Y + (sectionData.height /2) + snapping.top + verticalShift
        const bottomAllowed = sectionData.offset_Y - (sectionData.height /2) + snapping.bottom - verticalShift
        
        if (objectTransform.offset_y > topAllowed) {
            transform.offset_y =  topAllowed
            transform.snappedY.push((sectionData.height / 2) + sectionData.offset_Y)
        } else if (objectTransform.offset_y <  bottomAllowed) {
            transform.offset_y =  bottomAllowed
            transform.snappedY.push(sectionData.offset_Y - (sectionData.height /2))
        }
    }
    
    if (objectTransform.width) {
        const maxWidth = snapping.maxWidth;
        const maxHeight = snapping.maxHeight;
        
        const margin = snapping.margin;
        
        let newWidth = objectTransform.width;
        let newHeight = objectTransform.height;
        const minMargin = Math.min(...margin)
        const minIndex = snapping.margin.indexOf(minMargin)
        let orientation = "horizontal";
        
        if (minIndex === 0 || minIndex === 2) orientation = "vertical";
        
        const limitHeight = () => {
            if (newHeight >= maxHeight) {
                newHeight = maxHeight;
                newWidth = (maxHeight / element.height) * element.width;
                transform.snappedY.push((sectionData.height / 2) + sectionData.offset_Y)
                transform.snappedY.push((-sectionData.height / 2) + sectionData.offset_Y)
            }
            
            
        }
        const limitWidth = () => {
            if (newWidth >= maxWidth) {
                newWidth = maxWidth;
                newHeight = (maxWidth / element.width) * element.height;
                transform.snappedX.push(sectionData.width / 2)
                transform.snappedX.push(-sectionData.width / 2)
            }
            
        }
        
        if (element.lockMove) {
            if (orientation === "vertical") {
                limitHeight()
            } else {
                limitWidth()
            }
        }
        
        
        
        
        transform.width = newWidth;
        transform.height = newHeight;
    }
    return transform
    
    
    
    // This will check if the element is inside the section and update the element
    //return checkElementInsideSection(newElement, sectionData, objectTransform)
}

export default restrictElementInsideSection