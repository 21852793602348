import cleanSvg from "../svg/cleanSvg";
import {makePngFromSvg} from "../svg/makePngFromSvg";
import store from "../../../store";
import {v4 as uuidv4} from 'uuid';

import cacheImages from "../svg/cacheImages";
import getCoverSize from "./util/getCoverSize";
import svgOutline from "./util/svgOutline";
import getBoundingBoxByAngle from "../svg/getBoundingBoxByAngle";

const addNewGraphicElement = async (src, sectionId) => {
    return new Promise(async (resolve, reject) => {
        
        
        fetch(src)
        .then(response => response.text())
        .then(async svgCode => {
            const increaseQuality = 20
            const section = store.sections.find(e => e.section_id === sectionId) //Get section
            
            
            const elementWidth = section.width * 0.7
            
            const changedSvg = await cleanSvg(svgCode, elementWidth * increaseQuality) //Clean svg and change size
            
            const imgData = await makePngFromSvg(changedSvg.svg) //Make png from svg
            
            const imgSize = getCoverSize(section, imgData, 0.7)
            const id = uuidv4()
            await cacheImages(imgData.blob, id)
            
            const snapping = await getBoundingBoxByAngle({
                width: imgSize.width,
                height: imgSize.height,
                angle: 0,
                outline: changedSvg.outline,
                offset_x: section.offset_x,
                offset_y: section.offset_Y,
                sectionWidth: section.width,
                sectionHeight: section.height,
                section_offset_y: section.offset_Y,
                lockMove: true
            })
            
            const element = {
                sectionId: sectionId,
                elementId: id,
                index: store.elements.length,
                visible: true,
                type: "graphic",
                image: imgData.imgUrl,
                outline: changedSvg.outline,
                snapping: snapping,
                needToCheck: false,
                width: imgSize.width,//elementWidth,
                height: imgSize.height,//imgData.height / increaseQuality,
                offset_x: section.offset_x,
                offset_y: section.offset_Y,
                radialAngle: section.radial_angle,
                angle: section.angle || 0,
                insideData: {
                    intersect: true, // If the element is intersecting the section
                    isInside: true, // If the element is intersecting the section
                    points : [true, true, true, true] // Points of the element that are inside the section
                },
                lockMove : true,
            }
            
            
            store.elements.push(element)
            store.selectedElementId = id
            store.ui.addModalVisibility = false
            store.ui.zoom = sectionId
            
            const threeViewerSection = window.threeViewer.scene.children.find(e => e.userData.id === sectionId && e.type === "section");
            
            threeViewerSection.update()
            await threeViewerSection.helper.show(element)
            //const decal = await generateElement(element)
            
            //console.log("generated ", decal)
            
            
            
            
        })
        .catch(error => {
            resolve(0)
            console.error("Error add graphic element:", error);
        });
    })
}

export default addNewGraphicElement;