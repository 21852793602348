import * as THREE from 'three';

// Reuse vectors to reduce memory allocation
const tempV1 = new THREE.Vector3();
const tempV2 = new THREE.Vector3();
const tempV3 = new THREE.Vector3();

export default function createPlaneFromSection(sections) {
    let plane = new THREE.Plane();
    getPointFromBufferGeometry(sections.geometry, 0, tempV1);
    getPointFromBufferGeometry(sections.geometry, 1, tempV2);
    getPointFromBufferGeometry(sections.geometry, 2, tempV3);
    
    sections.localToWorld(tempV1);
    sections.localToWorld(tempV2);
    sections.localToWorld(tempV3);
    
    plane.setFromCoplanarPoints(tempV1, tempV2, tempV3);
    return plane;
}

export function getPointFromBufferGeometry(bufferGeometry, index, target) {
    let position = bufferGeometry.attributes.position;
    target.set(
        position.getX(index),
        position.getY(index),
        position.getZ(index)
    );
    return target;
}
