import {fabric} from 'fabric';
import * as THREE from 'three';


export async function createTexture(width, height, text = '') {
    function createCanvas(dashed, text) {
        const canvas = new fabric.StaticCanvas(null, {
            width: width * 3000,
            height: height * 3000,
        });
        
        const strokeWidth = dashed ? 1.5 : 4;
        // Draw border
        const border = new fabric.Rect({
            left: 0,
            top: 0,
            width: canvas.width - strokeWidth,
            height: canvas.height - strokeWidth,
            stroke: 'white',
            strokeWidth: strokeWidth,
            fill: 'transparent',
            strokeDashArray: dashed ? [5, 3] : null,
        });
        canvas.add(border);
        
        // Draw text if provided
        if (text) {
            let fontSize = 60; // Starting font size
            const textObject = new fabric.Text(text, {
                left: canvas.width / 2,
                top: canvas.height / 2,
                fontSize: fontSize,
                fontFamily: "DINPro Condensed",
                fill: 'white',
                textAlign: 'center',
                originX: 'center',
                originY: 'center',
            });
            
            // Add the text object to canvas to measure and adjust font size
            canvas.add(textObject);
            
            // Decrease the font size until the text fits
            while (textObject.width > canvas.width - 40 && fontSize > 1) {
                fontSize -= 1;
                textObject.set('fontSize', fontSize);
                textObject.setCoords();
            }
        }
        canvas.renderAll();
        return canvas.lowerCanvasEl;
    }
    
    const solidBorderCanvas = await createCanvas(false);
    const solidBorderCanvasText = await createCanvas(false, text);
    const dashedBorderCanvas = await createCanvas(true);
    
    
    const hover = new THREE.CanvasTexture(solidBorderCanvas);
    const hoverText = new THREE.CanvasTexture(solidBorderCanvasText);
    const normal = new THREE.CanvasTexture(dashedBorderCanvas);
    
    
    return {normal: normal, hover: hover, hoverText: hoverText};
}
