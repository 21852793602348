import { fabric } from 'fabric';
import loadFont from './loadFont';

function createMonogramImage(text, options) {
    return new Promise(async (resolve, reject) => {
        

        const fabricCanvas = new fabric.StaticCanvas(null);
        const spacing = 1
        const fontSize = 400
        const padding = fontSize / 10 

        let fontFolder = options.location
        let fileType = options.fontType || "otf"

        await loadFont(fontFolder + 'Left.' + fileType, options.name + '-Left');
        await loadFont(fontFolder + 'Center.' + fileType, options.name + '-Center');
        await loadFont(fontFolder + 'Right.' + fileType, options.name + '-Right');

        function addTextToCanvas(textString, left, fontName) {
            let text = new fabric.Text(textString, {
                fontFamily: fontName, // Use the loaded font name
                fontSize: fontSize,
                left: left,
                top: padding,
            });
            fabricCanvas.add(text);
            return text;
        }

        // Set up text properties
        let leftText = addTextToCanvas(text[0] || "", 0, options.name + "-Left");
        let totalTextWidth = leftText.width
        let totalTextHeight = leftText.height
        if (text[1]) {
            let paddingForText2 = leftText.width + spacing
            let centerText = addTextToCanvas(text[1], paddingForText2, options.name + "-Center");
            totalTextWidth += centerText.width
            totalTextWidth += spacing
            totalTextHeight = Math.max(totalTextHeight, centerText.height);

            if (text[2]) {
                let paddingForText3 = paddingForText2 + centerText.width + spacing
                let rightText = addTextToCanvas(text[2], paddingForText3, options.name + "-Right");
                totalTextWidth += rightText.width
                totalTextWidth += spacing
                totalTextHeight = Math.max(totalTextHeight, rightText.height);
            }
        }

        fabricCanvas.setWidth(totalTextWidth);
        fabricCanvas.setHeight(totalTextHeight + (padding * 4));

        // Get data URL
        const dataUrl = fabricCanvas.toDataURL({
            format: 'png',
            quality: 1 // Highest quality
        });

        // Convert data URL to blob
        return await fetch(dataUrl)
            .then(res =>
                resolve(res.blob())
            )
            .catch(error => {
                console.error('Error converting data URL to blob:', error)
                reject(error)
            });
    })
}

export default createMonogramImage;
