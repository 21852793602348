import React, {memo} from "react"
import filterVariantList from "../filterVariantList";
import ThemeElement from "../../theme/ThemeElement";


export default function SizeFormView({name, list, selected, variants, className = ''}) {
    return {
        name: name,
        label: `Select ${name}`,
        placeholder: `Select ${name}`,
        type: "Module",
        module: SelectSize,
        list: list?.map((item) => ({
            label: item.label,
            value: item.label,
        })),
        variants: variants,
        clear: false,
        reset: false,
        defaultValue: selected || Object.keys(list)[0],
        className: className,
    }
}


const SelectSize = memo(({item, submit, data}) => {
    const formData = data;
    const availableList = item?.list?.sort((a, b) => a.label.localeCompare(b.label)).map(size=> {
        const variants = filterVariantList(item.variants, {...data, size: size.label})
        return ({
            ...size,
            disabled: variants.length === 0
        })
    })
    
    return (
       <ThemeElement
            name={"FormSize"}
            data={{
                list : availableList,
                formData:formData,
                submit:submit,
                item:item,
            }}
       />
       
    )
})

