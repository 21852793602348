import store from "../store";


export default function getUserShopify(state) {
    const userId = store?.user?.id;

    if (userId) {
        return Promise.resolve(userId);
    }

    return new Promise((resolve, reject) => {
        const handleMessage = (msg) => {
            const data = msg?.data;
            if (data && data.user && typeof data.user === 'object') {
                const userId = Number(data.user.id);
                store.userId = userId;
                // TODO: remove userId from store
                store.user = data.user
                window.removeEventListener('message', handleMessage);
                resolve(userId);
            }
        };

        window.addEventListener('message', handleMessage)
        if(state==="forceToLogin"){
            window.parent.postMessage({ "forceToLogin": true }  , '*');
        }else{
            window.parent.postMessage({ "getUser": true }  , '*');
        }

    });
}