import * as THREE from "three";
import {MathUtils} from "three";


const reusableEuler = new THREE.Euler();

const reusableVector = new THREE.Vector3();
const calculateElementHelper = async (element) => {
    
    
    const ThreeViewerSection = window.threeViewer.scene.children.find(e => e.userData.id === element.sectionId && e.type === "section");
    
    
    const width = element.width / 1000
    const height = element.height / 1000
    const zSize = Math.min(ThreeViewerSection.userData.productSize.z, ThreeViewerSection.userData.productSize.x)
    const size = reusableVector.set(width, height, zSize)
    
    
    const angle = element.angle
    const sectionAngle = ThreeViewerSection.userData.radialAngle
    
    //const imgUrl = element.image
    const elementId = element.elementId
    const sectionId = element.sectionId
    
    // calculate position
    const position = new THREE.Vector3();
    position.x = 0;
    position.z = 0;
    position.y = element.offset_y / 1000;
    
    // calculate rotation
    const radius = Math.abs(ThreeViewerSection.distance)
    const chordLength = ThreeViewerSection.size.x
    const angleFromChord = (chordLength * 360) / (2 * Math.PI * radius)
    
    const difAngle = MathUtils.degToRad(element.offset_x / 1000 / chordLength * angleFromChord)
    
    const euler = reusableEuler.set(0, sectionAngle + difAngle, angle * Math.PI / 180)
    
    
    // Generate decal
    let decal = {
        
        sectionId: sectionId,
        sectionAngle: sectionAngle,
        
        elementId: elementId,
        size: size,
        sizeRatio: height / width,
        angle: angle,
        position: position,
        euler: euler,
        center: window.threeViewer.center,
    }
    
    
    return decal
    
}
export default calculateElementHelper;

