
export default function filterVariantList(variantList, formValues) {
    let variant = variantList;
    
    Object.keys(formValues).forEach((key) => {
        variant = variant.filter(item =>
            item?.options?.[key]?.label === formValues?.[key] &&
            item?.available === true &&
            item?.is_enable_customization === true &&
            
            // Check the product profile to see if the bundle item is available
            (item?.is_bundle ?
                item?.bundle_items?.filter(bundleItem => !!bundleItem["3d_image_url"]).length === item?.bundle_items?.length
                :
                !!item["3d_image_url"])
        );
    })
    return variant;
}