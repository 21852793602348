export default function resizeImage(file, maxWidth, maxHeight) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        const image = new Image();
        
        reader.onload = function (event) {
            image.src = event.target.result;
            
        };
        
        reader.onerror = function () {
            reject(new Error("Error reading file"));
            
        };
        
        image.onload = () => {
            
            const canvas = document.getElementById('sectionsCanvas_effectCanvas');
            const ctx = canvas.getContext('2d');
            
            
            let targetWidth = image.width;
            let targetHeight = image.height;
            
            // First, check width
            if (targetWidth > maxWidth) {
                targetHeight = (targetHeight * maxWidth) / targetWidth;
                targetWidth = maxWidth;
            }
            
            // Then check height
            if (targetHeight > maxHeight) {
                targetWidth = (targetWidth * maxHeight) / targetHeight;
                targetHeight = maxHeight;
            }
            
            canvas.width = targetWidth;
            canvas.height = targetHeight;
            
            ctx.clearRect(0, 0, targetWidth, targetHeight);
            ctx.fillStyle = 'white';
            ctx.fillRect(0, 0, targetWidth, targetHeight);
            ctx.drawImage(image, 0, 0, targetWidth, targetHeight);
            
            canvas.toBlob(blob => {
                resolve({
                    blob: blob,
                    width: targetWidth,
                    height: targetHeight,
                    imageData: ctx.getImageData(0, 0, targetWidth, targetHeight).data
                })
            });
            
        };
        reader.readAsDataURL(file);
    });
}
