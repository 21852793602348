export default async function manageConcurrentRequest(id) {
    if (!window.manageConcurrentRequest) {
        window.manageConcurrentRequest = {};
    }
    
    const newRequest = {
        id: id,
        time: Date.now(),
    };
    
    if (await check(newRequest)) {
        return null
    } else {
        window.manageConcurrentRequest[id] = newRequest;
        return newRequest;
    }
}

export async function check(request) {
    const req = window.manageConcurrentRequest[request.id];
    
    if (req?.id) {
        return req.time > request.time;
        
    } else {
        return false;
    }
}

export async function sendRequest(requestId, func, ...args) {
    const checkConcurrentRequest = await check(requestId);
    if (checkConcurrentRequest) {
        return false
    }
    
    return await func(...args);
}