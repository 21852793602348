import store from "../../../../store"
import {snapshot} from "valtio";
//import boundingBox from "./boundingBox";
import pathBounds from "../../svg/pathBounds";


const createSnapPoints = (sectionId, elementId) => {
    
    const snap = snapshot(store)
    
    const section = snap.sections.find(el => el.section_id === sectionId)
    const elementList = snap.elements.filter(el => el.sectionId === sectionId && el.elementId !== elementId)
    
    const xSnapList = [section.offset_x]
    const ySnapList = [section.offset_Y]
    
    xSnapList.push(section.offset_x + section.width / 2)
    xSnapList.push(section.offset_x - section.width / 2)
    
    ySnapList.push(section.offset_Y + section.height / 2)
    ySnapList.push(section.offset_Y - section.height / 2)
    
    elementList.forEach(el => {
        const pathBound = pathBounds(el.snapping.path);
        xSnapList.push(pathBound[0] + el.offset_x)
        xSnapList.push(pathBound[2] + el.offset_x)
        ySnapList.push(el.offset_y - pathBound[1])
        ySnapList.push(el.offset_y - pathBound[3])
        
        xSnapList.push(el.offset_x)
        ySnapList.push(el.offset_y)
        /*const size = boundingBox(el.width, el.height, el.angle)
        
        xSnapList.push(el.offset_x + size.width / 2)
        xSnapList.push(el.offset_x - size.width / 2)
        ySnapList.push(el.offset_y + size.height / 2)
        ySnapList.push(el.offset_y - size.height / 2)
        xSnapList.push(el.offset_x)
        ySnapList.push(el.offset_y)*/
        
    })
    
    window.threeViewer.snapPoints = {
        x: [...new Set(xSnapList)],
        y: [...new Set(ySnapList)],
    }
    
}

export default createSnapPoints


