import svgpath from 'svgpath';
import pathBounds from './pathBounds';

export default function getBoundingBoxByAngle({ angle, outline, width, height, offset_x, offset_y, sectionWidth, sectionHeight, section_offset_y, lockMove = false }) {
    const limitPercentage = 0.7
    const decimal = (n) => Number(n.toFixed(7));
    const posX = (offset, gap) => {
        if (offset < 0 && gap >= 0) return -1
        if (offset >= 0 && gap >= 0) return 1
        if (offset < 0 && gap < 0) return -1
        if (offset >= 0 && gap < 0) return 1
        
    }
    const posY = (offset, gap) => {
        if (offset < 0 && gap >= 0) return -1
        if (offset >= 0 && gap >= 0) return -1
        if (offset < 0 && gap < 0) return 1
        if (offset >= 0 && gap < 0) return -1
    }
    
    
    // Calculate the initial bounding box of the path
    const initialBounds = pathBounds(outline);
    const pathWidth = initialBounds[2] - initialBounds[0];
    const pathHeight = initialBounds[3] - initialBounds[1];
    
    // Calculate the scale factors for width and height
    const scaleX = width / pathWidth;
    const scaleY = height / pathHeight;
    
    // Scale the path to fit the element size
    const scaledPath = svgpath(outline).translate(-initialBounds[0], -initialBounds[1]).scale(scaleX, scaleY).toString();
    
    
    
    
    const scaledPathBounds = pathBounds(scaledPath);
    const scaledPathCenterX = (scaledPathBounds[2] + scaledPathBounds[0]) / 2;
    const scaledPathCenterY = (scaledPathBounds[3] + scaledPathBounds[1]) / 2;
    
    // Rotate the scaled path by the given angle
    const rotatedPath = svgpath(scaledPath).translate(-scaledPathCenterX, -scaledPathCenterY).rotate(-angle).toString(); //.translate(-scaledPathCenterX, -scaledPathCenterY)
    
    
    
    
    const rotatedPathBounds = pathBounds(rotatedPath);
    
    const rotatedBoundingBoxWidth = rotatedPathBounds[4]// - rotatedPathBounds[0];
    const rotatedBoundingBoxHeight = rotatedPathBounds[5]// - rotatedPathBounds[1];
    
    
    
    const offset_y_center = (offset_y - section_offset_y)
    const fullOffsetX = (sectionWidth / 2) + offset_x;
    const fullOffsetY = (sectionHeight / 2) + offset_y_center;
    
    
    const boundingBoxWidth = rotatedPathBounds[2] - rotatedPathBounds[0];
    const boundingBoxHeight = rotatedPathBounds[3] - rotatedPathBounds[1];
    
    
    
    const margin = [
        
        decimal(sectionHeight - fullOffsetY + rotatedPathBounds[1]),// + (lockMove ? 0 : boundingBoxHeight * limitPercentage),
        decimal(sectionWidth - fullOffsetX - rotatedPathBounds[2]),// + (lockMove ? 0 : boundingBoxWidth * limitPercentage),
        decimal(fullOffsetY - rotatedPathBounds[3]),// + (lockMove ? 0 : boundingBoxHeight * limitPercentage),
        
        decimal(fullOffsetX + rotatedPathBounds[0])//+ (lockMove ? 0 : boundingBoxWidth * limitPercentage),
    ]
    
    
    
    
    
    
    
    const gapX = decimal(Math.abs(rotatedPathBounds[2]) - Math.abs(rotatedPathBounds[0]));
    const gapY = decimal(Math.abs(rotatedPathBounds[3]) - Math.abs(rotatedPathBounds[1]));
    
    const positionX = posX(offset_x, gapX);
    const availableSectionWidth = Math.min(margin[1], margin[3]) + boundingBoxWidth
        //sectionWidth - (Math.abs(offset_x)*2) - (gapX * positionX) ;
    let maxWidth = (availableSectionWidth / rotatedBoundingBoxWidth) * width ;
    
    
    const positionY = posY(offset_y_center, gapY)
    const availableSectionHeight = Math.min(margin[0], margin[2]) + boundingBoxHeight
        //sectionHeight - (Math.abs(offset_y_center)*2) - (gapY * positionY) ;
    const maxHeight = (availableSectionHeight / rotatedBoundingBoxHeight) * height
    
    
    const centerX = offset_x - (gapY * positionY)
    const centerY = offset_y - (gapX * positionX)
   
    
    
    
    
    return {
        gapX: gapX,
        gapY: gapY,
        offset_y_center: offset_y_center,
        
        left: rotatedPathBounds[0],
        right: rotatedPathBounds[2],
        top: rotatedPathBounds[1],
        bottom: rotatedPathBounds[3],
        
        path: rotatedPath,
        
        width: decimal(boundingBoxWidth),
        height: decimal(boundingBoxHeight),
        
        maxWidth:decimal(maxWidth),// + (lockMove ? 0 : boundingBoxWidth * limitPercentage),
        maxHeight:decimal(maxHeight),/// + (lockMove ? 0 : boundingBoxWidth * limitPercentage),
        
        centerX : centerX,
        centerY : centerY,
        
        margin: margin,
        
        
    };
}