import formViewTemplate from "./formViewTemplate";
import store from "../../store";
import {snapshot} from "valtio";
export default function ConfiguratorFormView(defaultValue, variantsOptions) {
    
    const snap = snapshot(store);
    const form = [];
    
    Object.keys(variantsOptions).map((item) => {
        const list = JSON.parse(JSON.stringify(variantsOptions[item]));
        
        const theme = item in formViewTemplate ? formViewTemplate[item] : formViewTemplate.Default;
        
        const view = theme({
            name: item,
            list: list,
            selected: defaultValue?.[item],
            variants: snap.variants,
        })
        form.push(view);
    })
    
    return form;
    
}