import {fabric} from 'fabric';


export default async function svgToPng(file) {
    if (!file || file.type !== 'image/svg+xml') {
        return {file: file};
    }
    
    const reader = new FileReader();
    const fabricCanvas = new fabric.StaticCanvas(null);
    const targetWidth = 2000; // Desired width
    
    return new Promise((resolve, reject) => {
        reader.onload = async (e) => {
            const svgText = e.target.result;
            
            fabric.loadSVGFromString(svgText, async (objects, options) => {
                // Group SVG elements
                const svgGroup = fabric.util.groupSVGElements(objects, options);
                
                // Calculate the scale factor to set the width to 2000px
                const scaleFactor = targetWidth / svgGroup.width;
                svgGroup.set({
                    scaleX: scaleFactor,
                    scaleY: scaleFactor
                });
                
                fabricCanvas.add(svgGroup);
                
                // Adjust canvas size according to the scaled SVG
                const boundingRect = svgGroup.getBoundingRect();
                fabricCanvas.setWidth(boundingRect.width);
                fabricCanvas.setHeight(boundingRect.height);
                
                // Export to PNG
                const dataUrl = await fabricCanvas.toDataURL({
                    format: 'png',
                    quality: 1 // High quality
                });
                
                fetch(dataUrl)
                .then(res => res.blob())
                .then(blob => {
                    // Create a File object from the Blob
                    const fileName = "converted_image.png";
                    const svgFile = new File([blob], fileName, {type: 'image/png'});
                    resolve({
                        file: svgFile,
                        blob: blob
                    });
                })
                .catch(reject);
            });
        };
        
        reader.onerror = reject;
        reader.readAsText(file);
    });
}
