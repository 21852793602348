import {toast} from "react-toastify";


const validationNotifier = (rs) => {
    let type = "error"
    const innerError = Object.values(rs).map(item=> {
        return <li className={"opacity-70"}>{item}</li>
    })
    const message = <ul className={"list-disc list-inside my-[5px]"}>{innerError}</ul>
    toast[type](message, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}
export default validationNotifier