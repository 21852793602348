import {v4 as uuidv4} from "uuid";
import { snapshot } from "valtio";

import store from "../../store";
import calculateSize from "../configurator/calculateSize";
import loadProductIntoScene from "../internal/loadProduct";
import PriceCalculator from "../order/priceCalculator";

export default async function loadVariant(sku) {
    const snap = snapshot(store);
    let variant =  snap.variants.find(variant => variant.sku === sku);
    const elements = snap.elements;
    const sections = snap.sections;
    const order = snap.order;
    
    
    store.selectedVariantSKU = sku;
    // Set the loading status to true
    store.status.loading = true;
    
    /*if (variant.is_bundle) {
        const selectedBundleProductSKU = snap.selectedBundleProductSKU || product.bundle_items[0].sku;
        product = product.bundle_items.find((item) => item.sku === selectedBundleProductSKU)
        
    }*/
    
    // Create an array of products for the variant
    // ------------------------------------------------------------
    const newProduct = variant?.is_bundle ? variant?.bundle_items : [variant];
    
    
    
    // Create an array of sections for the variant
    // ------------------------------------------------------------
    const newSections =  newProduct.flatMap((item, index) => {
        
        // Create a shallow copy of the sections array and sort the copy by radial_angle
        const sortedSections = [...item.sections].sort((a, b) => a.radial_angle - b.radial_angle);
        
        return sortedSections.map(sectionItem => ({
            ...sectionItem,
            product_sku: item.sku,
            design_id: uuidv4(),
            bundle_index: index+1,
        }));
    });
    
    
    
    // Create an array of elements for the variant if there are any
    // ------------------------------------------------------------
    const updateElementsPromises = elements.map(async (element) => {
        
        if (sections.length === 0) return element;
        
        const index = sections.findIndex(section => section.section_id === element.sectionId);
        let isElementInSectionList = true
        if (index < 0 || index > newSections.length - 1) isElementInSectionList = false
        
        const oldSection = await sections[index];
        const newSection = await newSections[index];
        
        const newSize = isElementInSectionList && newSection ? await calculateSize(element, oldSection, newSection) : {};
        // TODO: REFACTOR "restrictElementInsideSection"
        const insideData = false//isElementInSectionList ? await restrictElementInsideSection(newSize, element) : false;
        
        return {
            ...element,
            ...newSize,
            insideData: insideData,
            sectionId: newSection ? newSection.section_id : null
        };
    });
    
    const newElements = await Promise.all(updateElementsPromises)
    
    
    
    // Update the store with the new product, sections, and elements
    // ------------------------------------------------------------
    store.product = newProduct;
    store.sections = newSections;
    store.elements = newElements;
    const selectedProductSKU =
        variant.is_bundle ?
            newProduct[0].sku//snap.selectedBundleProductSKU || newProduct[0].sku
            : sku;
    
    //console.log("selectedProductSKU", selectedProductSKU, snap.selectedBundleProductSKU)
    store.selectedBundleProductSKU = null
    store.selectedProductSKU = selectedProductSKU
    //const imageUrl = newProduct.find(product => product.sku === selectedProductSKU)['3d_image_url'];
    await loadProductIntoScene(selectedProductSKU);
    
    
    let sectionIndex = snap.selectedSectionId ? sections.findIndex(section => section.section_id === snap.selectedSectionId) : 0;
    
    if (sectionIndex < 0) sectionIndex = 0;
    if (sectionIndex > newSections.length - 1) sectionIndex = newSections.length - 1;
    store.selectedSectionId = newSections[sectionIndex].section_id;

    
    
    PriceCalculator();
    store.status.loaded = true;
    
    
    
    // Set the loading status to true
    store.status.loading = false;
    return newProduct;
}