import React from 'react';


const Number = ({item, value, validationMessage, handleChange, handleFocus, handleBlur, handleKeyPress, defaultValue, formData}) => {
    
    const disabled = typeof item.disabled === "function" ? item.disabled(formData) : item.disabled || false;
    const inputValue = value || defaultValue || "";
    // Return Input in view
    return (
        <>
            {item?.type === "Number" && !item.handle &&
                <style>
                    {`
                input[type="number"]::-webkit-inner-spin-button,
                    input[type="number"]::-webkit-outer-spin-button {
                      -webkit-appearance: none;
                      margin: 0;
                    }
                    
                    input[type="number"] {
                      -moz-appearance: textfield; /* Firefox */
                    }
                `}
                </style>
            }
            <input
                name={item.name}
                type={"Number"}
                placeholder={item.placeholder}
                disabled={disabled}
                className={`transition ${item.disabled && "disabled"}  disabled:text-gray-400 rounded-md px-[8px] h-[42px] border-gray-200 border hover:border-gray-400 focus:outline-none focus:border-blue-500 focus:ring-1 block w-full ${item.innerClassName || ""} ${validationMessage ? 'border-red-500 border-2' : ''}`}
                value={inputValue}
                onChange={(e) => {
                    if (handleChange) handleChange(e.target.value, item.name)
                }}
                onFocus={(e) => {
                    if (handleFocus) handleFocus(e.target.value, item.name)
                }}
                onBlur={(e) => {
                    if (handleBlur) handleBlur(e.target.value, item.name)
                }}
                onKeyPress={(e) => {
                    if (handleKeyPress) handleKeyPress(e)
                }}
                autoFocus={item?.autoFocus}
            />
        </>
        
    
    )
}


export default Number;