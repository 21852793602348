import React, {useEffect, useRef} from 'react';

import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import selectedItem from "./select-util/selectedItem";
import fetchOptions from "./select-util/fetchOptions";
import returnValue from "./select-util/returnValue";
//import style from "./select-util/style";
import checkUrl from "./select-util/checkUrls";
//import {addNewView, addNewAction} from "./select-util/addNew";
import debounce from "./select-util/debounce";
import ThemeElement from "../../../util/theme/ThemeElement";

export default ({item, value, validationMessage, handleChange, bind, formData}) => {
    
    
    
    const SelectComponent = item?.addNew ? CreatableSelect : Select;
    
    
    const [loadedOptions, setLoadedOptions] = React.useState([]);
    const [innerValue, setInnerValue] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);
    const [isDisabled, setIsDisabled] = React.useState(false);
    const [innerFormData, setInnerFormData] = React.useState(formData);
    //const [initial, setInitial] = React.useState(false);
    
    
    //const [lastRequest, setLastRequest] = React.useState(0);
    
    const selectRef = useRef(null);
    
    const loadOptions = debounce((inputValue) => {
        
        
        //setInitial(true)
        if (item.api && (!loaded || inputValue)) {
            setLoading(true)
            fetchOptions(item.api, inputValue, value, item.defaultValue, item?.list, formData).then((data) => {
                
                setLoading(false)
                setLoaded(true)
                setLoadedOptions(data)

            })
        } else if (item.list && !item.api) {
            setLoadedOptions(item.list)
        }
        
    },500)
    
    
    const selectOption = () => {
        const selected = selectedItem(value, loadedOptions)
        if (bind) bind(selected, item.name)
        return selected
    }
    
    useEffect(() => {
            
        setInnerValue(selectOption())
        
        /*if (value !== "" && value !== null && loadedOptions.length === 0) {
            loadOptions()
        }*/
        const check = checkUrl(item.api, formData, innerFormData, value)
        if (check.reset) {
            setLoaded(false)
        }
        setIsDisabled(check.disable)
        if (value === "") {
            selectRef.current.setValue(null)
            setLoadedOptions([])
        }
        if (loadedOptions.length === 0 && /*!initial &&*/ !item?.disablePreload) {
            loadOptions()
        }
        
    }, [value, loadedOptions]);
    
    useEffect(() => {
        let listReset = false
        if (item?.list && item.parent) {
            setLoadedOptions(item.list)
        }
        const check = checkUrl(item.api, formData, innerFormData, value)
        if (check.reset || listReset) {
            setLoaded(false)
        }
        setInnerFormData(formData)
        setIsDisabled(check.disable)
        
        if (check.reset || listReset) {
            setLoadedOptions([])
            setInnerValue(null)
            handleChange(null, item.name, null)
        }
        
        
    }, [formData])
    
    return (
        <div className={`${item.innerClassName || ""} dynamicForm component`}>
            <style>
                {`
                    .dynamicForm .css-1f43avz-a11yText-A11yText,
                    .css-b62m3t-container {
                        width: 100%;
                    }
                    .dynamicForm .css-1y6o3d0-menu {width:100%}
                    .dynamicForm .css-chz8pb-Control:hover {
                        border-color: black;
                    }
                    .dynamicForm .css-chz8pb-Control {
                        cursor: text;
                    }
                    .dynamicForm .selectClass {
                        height:100%;
                        min-width:100%;
                        display:block;
                        background-color:transparent;
                    }
                    .dynamicForm .css-1dimb5e-singleValue {
                        display:flex;
                        align-items:center;
                    }
                `}
            </style>
            {/*{loadedOptions.length < 1 ?
                <>Loading..</>
                :*/}
            <SelectComponent
                ref={selectRef}
                //loadOptions={loadOptions}
                onInputChange={(changeValue) => {
                    //if (changeValue !== value && ) {
                    loadOptions(changeValue)
                    //}
                }}
                isLoading={loading}
                options={loadedOptions}
                //defaultOptions
                value={innerValue}
                //formatCreateLabel={addNewView}
                //onCreateOption={(newOption) => {addNewAction(newOption, item, formData, loadOptions)}}
                defaultValue={null}
                onChange={(newData) => {
                    setInnerValue(newData);
                    handleChange(returnValue(newData), item.name, newData);
                }}
                className={`selectClass ${item.innerClassName2 || ""}`}
                isClearable={false}
                isDisabled={isDisabled || (item?.disabled && typeof item?.disabled === "function" ? item?.disabled(formData) : item?.disabled)}
                placeholder={item.placeholder}
                onMenuOpen={() => {
                    //if (loadedOptions.length === 0 && !item?.disablePreload) loadOptions()
                    if (loadedOptions.length === 0) loadOptions();
                }}
                /*styles={style({
                    validation: validationMessage//,
                    //style: item.style || {}
                })}*/
                classNamePrefix="custom-select"
                components={{
                    Option: CustomOption,
                    IndicatorSeparator: null,
                    DropdownIndicator: null,
                    Control: CustomControl,
                    Menu: CustomMenu
                }}
                {...item.features}
                /*menuIsOpen={true}*/
            />
            {/*}*/}
            
        </div>
    )
}

const CustomOption = ({ label, data, isSelected,innerRef, innerProps}) => (
    <div ref={innerRef} {...innerProps}>
        <ThemeElement
            name={"FormSelectOption"}
            data={{
                isSelected:isSelected,
                label:label,
                data:data,
                innerProps:innerProps
            }}
        />
    </div>
);
const CustomControl = ({ children, innerProps, innerRef, menuIsOpen }) => (
    <div ref={innerRef} {...innerProps} className={`${menuIsOpen ? "open": ""}`}>
        <ThemeElement
            name={"FormSelectControl"}
            data={{
                children:children,
                innerProps:innerProps
            }}
        />
    </div>
);
const CustomMenu = ({ children, innerProps, innerRef, menuIsOpen }) => (
    <div ref={innerRef} {...innerProps} className={`${menuIsOpen ? "open": ""}`} >
        <ThemeElement
            name={"FormSelectMenu"}
            data={{
                children:children,
                innerProps:innerProps
            }}
        />
    </div>
);


