import store from "../store";
import getTheme from "./getTheme"
export default async function loadCompanySetting(){
    
    const urlSettingParam = CheckUrlParam();
    const storeName = urlSettingParam.storeName
    
    if (!storeName) {
        console.log("Store not found.");
        return;
    }
    let settings = {}
    
    try {
        let response = await fetch(process.env.REACT_APP_SETTINGS_API + storeName);
        
        if (!response) {
            console.log("Error loading setting.")
        } else {
            const data = await response.json();
            
            data.forEach(item=> {
              settings[item.key] = item.value
            })
        }
        
        
        const appSetting = {...settings, ...urlSettingParam}
        store.ui.setting = appSetting;
        store.order.store_name = appSetting.storeName;
        
        await getTheme(appSetting.storeName, appSetting.theme.id || appSetting.theme)
        return true;
        
    } catch (error) {
        store.ui.setting = {...urlSettingParam}
    }
}


function CheckUrlParam() {
    
    const urlObj = new URL(window.location.href);
    const queryParams = new URLSearchParams(urlObj.search);

    // Convert the query parameters to an object
    const params = {};
    queryParams.forEach((value, key) => {
        const camelCaseKey = key.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
        
        params[camelCaseKey] = value;
    });
    
    return params
}

