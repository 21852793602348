export default function defaultFormView({name, list, selected, className=''}) {
    
    return {
        name: name,
        label: `Select ${name}`,
        placeholder: `Select ${name}`,
        type: "Select",
        list: list?.map((item) => ({
            label: item.label,
            value: item.values[0],
        })),
        
        clear: false,
        reset: false,
        defaultValue: selected || Object.keys(list)[0],
        className: className,
    }
}