import store from "../../../../store";
import {snapshot} from "valtio";
import getMouseDownIntersection from "../getMouseDownIntersection";


export default async function pointerUp(event) {
    if (store.ui.fullScreen) return
    document.body.className = ""
    // console.log("pointerUp", event.target.id)
    
    const threeViewer = window.threeViewer;
    const isMobile = threeViewer.isMobile;
    
    
    if (isMobile) {
        const touchCount = event?.touches?.length || 0
        
        //const element = await getMouseDownIntersection(false)
        if (touchCount > 0) {
            threeViewer.intersection = {}
            //pointerMove(event)
            
            //getMouseDownIntersection(touchCount > 1)
            return
        }
        if (!threeViewer?.intersection?.active) {
            
            try {
                if (event.target.id !== "toolBar") {
                    store.selectedElementId = await getMouseDownIntersection(false)
                    threeViewer.intersection = {}
                    threeViewer.controls.enabled = true;
                    //pointerMove(event)
                    return
                }
            } catch (e) {
                console.log(e)
            }
            
            
        }
    }
    
    
    const snap = snapshot(store);
    
    
    threeViewer.controls.enabled = true;
    threeViewer.intersection = {}
    
    const eventX = event.type === "touchmove" ? event.touches[0].pageX : event.clientX;
    const eventY = event.type === "touchmove" ? event.touches[0].pageY : event.clientY;
    threeViewer.intersection.mouseUp = {
        x: ((eventX - (threeViewer.element.getBoundingClientRect().left)) / threeViewer.element.clientWidth) * 2 - 1,
        y: -((eventY - (threeViewer.element.getBoundingClientRect().top)) / threeViewer.element.clientHeight) * 2 + 1
    }
    
    
    const section = threeViewer.scene.children.find(child => child.userData.id === snap.selectedSectionId)
    section.mouseUp()
    section.snapping.hide()
}