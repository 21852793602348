import axios from "axios";
/*import {api_endpoint} from "./../config";

import {clearToken} from "./../store/auth/api";
import store from "../store";
import {signout} from "../store/auth";*/


/*axios.defaults.baseURL = api_endpoint;
axios.defaults.withCredentials = true;
axios.defaults.headers["Content-Type"] = "application/json";
axios.defaults.headers["Accept"] = "application/json"*/


const changeCursor = (cursor) => {
    //const overlay = document.getElementById('overlay');
    
    if (cursor) {
        document.body.style.cursor = 'wait';
        //overlay.style.display = 'block';
    } else {
        document.body.style.cursor = 'default';
        //overlay.style.display = 'none';
    }
};


axios.interceptors.request.use(
    
    (config) => {
      //changeCursor(true)
      return config;
    },
    (error) => {
     // changeCursor(false)
      Promise.reject(error);
    }
);

axios.interceptors.response.use(
  (response) => {
    // console.log(response);
      changeCursor(false)
      return response;
  },

  (err) => {
      changeCursor(false)
    // console.log(err.response, 'api');

    // if (err?.response.status !== 200) {
    //   api.clearToken();
    //   store.dispatch(signout());
    // }
    if (err.response.data.message === 'This action is unauthorized.') {
      return err.response
    }
    if (err.response.data.message === "Unauthenticated.") {
      //store.dispatch(signout());
      // store.dispatch(postUserLogout())
    }
    // window.location.href = '/#/login'

    // if (err.response.data.message === "Unauthenticated.") {
    //   dropIsLogin();
    // }

    return err.response;

  }
);

export default axios;
