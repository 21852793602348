import * as THREE from 'three';
import calculateElementHelper from "../../elements/util/calculateElementHelper";


export class DecalHelper extends THREE.Object3D {
    constructor() {
        super();
        
        this.type = "decalHelper"
        this.name = "decalHelper"
        
        this.visible = false
        this.initialized = false
        this.layers.set(4); // 4 is the layer for decal helper
        
        this.colorCornerCircles = this.colorCornerCircles.bind(this)
        this.showHelper = this.showHelper.bind(this)
        this.hideHelper = this.hideHelper.bind(this)
        this.mouseUp = this.mouseUp.bind(this)
        
        this.size = new THREE.Vector3();
        this.point = new THREE.Vector3();
        this.euler = new THREE.Euler();
        this.eulerData = new THREE.Euler();
        
        //this.rotation = new THREE.Euler();
        
        this.material = window.productColorObject.uiMaterial.normal;
        this.cornerZCorrect = 0.0015
        this.threshold = 150
        this.addScalar = 0.003;
        this.lightColor = window.productColorObject.isLight ? 0x000000 : 0xffffff;
    }
    
    
    mouseUp() {
        if (this.numberMesh) this.numberMesh.visible = false
    }
    
    updateCornerList() {
        
        const alignedZ = (this.size.z / 2) + this.cornerZCorrect;
        // Define the corner points
        this.cornerPointsList = [
            new THREE.Vector3(-this.boxSize.x / 2, -this.boxSize.y / 2, alignedZ),
            new THREE.Vector3(this.boxSize.x / 2, -this.boxSize.y / 2, alignedZ),
            new THREE.Vector3(this.boxSize.x / 2, this.boxSize.y / 2, alignedZ),
            new THREE.Vector3(-this.boxSize.x / 2, this.boxSize.y / 2, alignedZ),
        ];
    }

    colorCornerCircles(element) {
        if(!element?.insideData) return
        this.cornerCircles.forEach((circle, index) => {
            const isCircleInside = element.insideData.points[index]
            circle.material.color.set(isCircleInside ? circle.userData.originalColor : circle.userData.warningColor)
        })
    }
    
    async showHelper(element) {
        if (!element) return
        const decal = await calculateElementHelper(element)
        
        this.userData = element;
        
        this.size.copy(decal.size);
        this.point.copy(decal.position);
        this.euler.set(decal.euler);
        
        this.decalAngle = decal.angle;
        this.boxSize = {
            x: decal.size.x + (this.addScalar * 2),
            y: decal.size.y + (this.addScalar * 2)
        }
        
        
        this.position.copy(this.point);
        this.rotation.copy(decal.euler);
        
        
        this.eulerData.copy(decal.euler);
        
        this.generate()
        this.visible = true

        this.colorCornerCircles(element)
        
        window.threeViewer.cursorActive = true
        window.threeViewer.selectedSection = element.sectionId
        window.threeViewer.selectedElement = element.elementId
    }
    
    
    hideHelper() {
        
        // Hide and reset the active helper
        window.threeViewer.cursorActive = false
        this.visible = false
    }
    
    
    generate() {
        
        // Set the position and rotation of the helper and handle the objects
        this.updateCornerList()
        
        if (!window.threeViewer.isMobile) {
            this.handleRotationHelper()
        }
        this.handlePlane()
        this.handleCornerCircles()
        this.handleLines()
        
        const updateRotation = 0 - this.eulerData.z
        const action = window.threeViewer.intersection.action
        if (action === "rotate" || action === "pinch") {
            if (this.numberMesh) this.numberMesh.visible = true
            this.showNumber(this.decalAngle, updateRotation)
        } else {
            if (this.numberMesh) this.numberMesh.visible = false
        }
        
        this.initialized = true
    }
    
    
    handleRotationHelper() {
        
        // If the rotation helper exists, update position or add it to the scene
        if (!this.initialized) {
            this.rotationHelper = window.productColorObject.uiMaterial.rotate.clone()
            this.add(this.rotationHelper)
        }
        this.rotationHelper.position.y = -this.boxSize.y / 2 - 0.01
        this.rotationHelper.position.z = this.size.z / 2
        
        this.rotationHelper.rotation.y = 0 - this.eulerData.z
    }
    
    
    showNumber(number, rotation) {
        
        // Check if there's an existing texture and dispose of it
        if (this.numberMesh && this.numberMesh.material.map) {
            this.numberMesh.material.map.dispose();
        }
        
        // Create the texture
        const rotateNumber = number < 180 ? 0 - number : 360 - number;
        const textTexture = this.createTextTexture(Math.floor(rotateNumber || 0).toString());
        
        // Check if the mesh exists, update it, or create it
        if (!this.numberMesh) {
            this.numberMesh = window.productColorObject.uiMaterial.rotateSnap.clone();
            this.add(this.numberMesh);
        }
        this.numberMesh.position.z = this.size.z / 2;
        this.numberMesh.material.map = textTexture;
        this.numberMesh.material.needsUpdate = true;
        this.numberMesh.rotation.y = rotation + Math.PI / 2;
    }
    
    
    handleCornerCircles() {
        
        // Handle the corner circles
        this.cornerCircles = this.cornerCircles || [];
        
        this.cornerPointsList.forEach((point, index) => {
            let sphereMesh;
            
            if (this.cornerCircles.length > index) {
                // Update existing sphereMesh position
                sphereMesh = this.cornerCircles[index];
                sphereMesh.position.set(point.x, point.y, point.z);
            } else {
                // Create a new sphereMesh and add it to the cornerCircles array
                let sphereMesh = window.productColorObject.uiMaterial.cornerCircle.clone()
                sphereMesh.material = sphereMesh.material.clone()
                sphereMesh.position.set(point.x, point.y, point.z);
                sphereMesh.userData.cursorType = index % 2 === 0 ? "nesw-resize" : "nwse-resize";
                this.add(sphereMesh);
                this.cornerCircles.push(sphereMesh);
            }
        });
    }
    
    
    handlePlane() {
        
        // Handle the plane
        if (this.initialized) {
            this.translateHelper.resize(this.boxSize.x, this.boxSize.y, this.translateHelper)
            this.translateHelper.position.set(0, 0, this.size.z / 2)
        } else {
            const mesh = window.productColorObject.uiMaterial.plane
            this.translateHelper = mesh.clone();
            this.translateHelper.resize = mesh.resize
            this.add(this.translateHelper);
        }
    }
    
    
    handleLines() {
        
        // Handle the lines
        if (!this.initialized) {
            const lines = window.productColorObject.uiMaterial.lines
            this.refLine = lines.clone()
            this.refLine.resize = lines.resize
            this.add(this.refLine);
        }
        this.refLine.resize(this.cornerPointsList)
    }
    
    
    createTextTexture(text) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.width = 100;   // Adjust size as needed
        canvas.height = 100;  // Adjust size as needed
        
        // Set text properties
        context.fillStyle = '#ffffff';  // Background color
        context.fillRect(0, 0, canvas.width, canvas.height);
        const fontSize = 40;  // Adjust font size as needed
        context.font = fontSize + 'px Arial';
        context.fillStyle = '#000000';  // Text color
        context.textAlign = 'center';
        context.textBaseline = 'middle'; // Align text vertically in the middle
        
        // Calculate the approximate vertical middle
        const middle = canvas.height / 2 + fontSize * 0.1;
        
        context.fillText(text, canvas.width / 2, middle);
        
        const texture = new THREE.Texture(canvas);
        texture.needsUpdate = true;
        
        return texture;
    }
    
}



