import React, {forwardRef, memo, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';
import ThemeElement from "../../util/theme/ThemeElement";


const Input = memo(forwardRef((props,ref) => {

    const {
        onChange,
        onFocus,
        placeholder,
        icon,
        iconPosition = 'right',
        size = "md",
        tags = [], //labels
        handleRemoveTag, //onLabelChange
        defaultValue = '',
        id,
        autoFocus = false,
        selectAll,
        className,
        handleClear,
        onEnter
    } = props;
    
    
    const [value, setValue] = useState(defaultValue || '');
    const [isFocused, setIsFocused] = useState(false);
    
    const inputRef = useRef();
    
    const handleChange = useCallback((event) => {
        setValue(event.target.value);
        onChange && onChange(event.target.value);
    }, [onChange]);
    
    
    useEffect(() => {
        if (autoFocus) {
            inputRef.current.focus();
        }
        if (selectAll === value) {
            inputRef.current.select();
        }
    }, [autoFocus, value]);
    useEffect(() => {
        if (selectAll === value && isFocused) {
            inputRef.current.select()
        }
    }, [isFocused]);
    
    
    useImperativeHandle(ref, () => ({
        setFocus: (focus) => {
            if (focus) {
                inputRef.current.focus();
            } else {
                inputRef.current.blur();
            }
        },
        getText: () => {
            return inputRef.current.value;
        },
        setText: (text) => {
            setValue(text);
        }
    }))
    
    
    const handleFocus = useCallback(() => {
        inputRef.current && inputRef.current.focus();
    }, []);
    
   
    
    return (
        <ThemeElement
            name={"Input"}
            data={{
                size: size,
                isFocused: isFocused,
                value: value,
                className: className,
                icon: typeof icon === 'function' ? icon(value, setValue) : icon,
                iconPosition: iconPosition || 'left',
                tags: tags,
                placeholder: placeholder,
                onFocus: onFocus,
                setIsFocused: setIsFocused,
                inputRef: inputRef,
                id: id,
                setValue: setValue,
                handleFocus: handleFocus || (() => {}),
                handleChange: handleChange || (() => {}),
                handleClear: handleClear || (() => {}),
                handleKeyPress: (e)=> {
                    if(e.key === 'Enter' && onEnter) {
                        e.preventDefault();
                        onEnter(e.target.value);
                        setValue('');
                    }
                },
                handleRemoveTag: handleRemoveTag || (() => {}),
            }}
        
        />
    )
}));

export default Input;
export { Input };