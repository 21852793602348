import React,{ useCallback, useMemo, memo } from "react";
import { useSnapshot } from "valtio";
import store from "../../store";
import Configurator from "../../components/configurator/Configurator";
import Input from "../../components/assets/Input";
import {Tab, Tabs} from "../../components/assets/Tab";
import DynamicForm from "../../components/dynamicForm";
import BundleProduct from "../../components/bundleProduct/BundleProduct";
import ImageComponent from "../../components/imageComponent";

const componentList = {
    Configurator,
    Input,
    Tab: Tabs,
    TabItem: Tab,
    DynamicForm,
    BundleProduct,
    ImageComponent,
};


const ThemeElement = memo((props) => {
    const snap = useSnapshot(store);
    
    /*const componentList = useMemo(() => ({
        Configurator: Configurator,
        Input: Input,
        Tab: Tabs,
        TabItem: Tab,
        DynamicForm: DynamicForm,
        BundleProduct: BundleProduct,
        ImageComponent: ImageComponent
    }), []);*/
    
    const getTheme = useCallback((name)=> {
        const themeString = JSON.parse(localStorage.getItem('NeatolabsTheme'))
        return new Function('React', 'return ' + themeString[name])(React);
       
    },[])
    const Theme = useCallback(
        (innerProps) => {
            if (!snap.ui.theme) return null;
            const themeFunc = getTheme(innerProps.name);
            return themeFunc({ data: innerProps || {}, Theme, Component: componentList});
        },
        []
    );
    
    const Component = useCallback(
        (innerProps) => {
            const Component = componentList[innerProps.name];
            const componentType = typeof Component;
            if (componentType === "object") {
                return <Component {...innerProps} />
            } else {
                return componentList[innerProps.name]({ props: innerProps || {}});
            }
        },
        []
    );
   /* const Component = (innerProps) => {
        const Component = componentList[innerProps.name];
        return <Component {...innerProps} />;
    };*/
    
    // Check if the theme element exists
    const Content = useMemo(() => getTheme(props?.name), [props?.name]);
    
    if (!Content) {
        console.error(`Theme element '${props?.['name']}' not found.`);
        return null;
    }
    
    return <Content data={props?.['data'] || {}} Theme={Theme} Component={Component} />;
});

export default ThemeElement;
