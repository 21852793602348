import * as THREE from "three";
import getMouseMoveIntersection from "../getMouseMoveIntersection";
import manageTransformation from "../../elements/manageTransformation";
import rotateSnapping from "../snapping/rotate";
import transformResize from "./transformResize";
import transformMove from "./transformMove";
import restrictElementInsideSection from "./restrictElementInsideSection";
import limitElementSize from "./limitElementSize";


const vector = new THREE.Vector3();
export default async function pointerMove(event) {
    
    const threeViewer = window.threeViewer;
    
    if (!threeViewer?.intersection) {return}
    const intersection = threeViewer.intersection;
    const controls = threeViewer.controls;
    
    
    
    // When helper is active
    if (threeViewer?.cursorActive && !controls.currentAction) {
        
        
        // Update global mouse position
        const eventX = event.type === "touchmove" ? event.touches[0].pageX : event.clientX;
        const eventY = event.type === "touchmove" ? event.touches[0].pageY : event.clientY;
        threeViewer.intersection.mouse = {
            x: ((eventX - (threeViewer.element.getBoundingClientRect().left)) / threeViewer.element.clientWidth) * 2 - 1,
            y: -((eventY - (threeViewer.element.getBoundingClientRect().top)) / threeViewer.element.clientHeight) * 2 + 1
        }
        
        
        // Get the intersection
        const intersectionMove = await getMouseMoveIntersection()
        
        
        // Get the action type
        const getActionType = (action) => {
            
            if (action === "tap") {
                
                if (event?.touches?.length === 2) {
                    return "pinch"
                } else {
                    return 'move'
                }
            } else {
                return action
            }
            
        }
        
        
        // When Mouse Down Intersection not called
        if (!intersection.active) {
            if (intersectionMove?.object?.userData?.cursorType) {
                document.body.className = intersectionMove.object.userData.cursorType
            } else {
                document.body.className = "default"
            }
        }
        
        
        // When Mouse Down Intersection called and we have an intersection
        else if (intersection.action) {
            
            
            const object = intersection.active
            const section = threeViewer.scene.children.find(child => child.type === "section" && child.userData.id === object?.sectionId)
            
            
            // Create object transform
            let objectTransform = {
                snappedX: [],
                snappedY: [],
                offset_y: object.offset_y,
                offset_x: object.offset_x,
            }
            
            
            // When we have an active object and an intersection point
            if (object && intersectionMove) {
                
                // Mouse position based on the 3d plane
                const mouse = {
                    x: intersectionMove.x,
                    y: intersectionMove.y
                }
                
                
                // Get the action type
                const actionType = getActionType(intersection.action)
                
                
                // ----------------------------------------
                // Move
                // ----------------------------------------
                if (actionType === "move") {
                    
                    const newPosition = transformMove({
                        mouse: mouse,
                        mouseDifDrag: threeViewer.intersection.difDrag,
                        snapping: object.snapping,
                    })
                    
                    objectTransform.offset_x = newPosition.x
                    objectTransform.offset_y = newPosition.y
                    objectTransform.snappedX = newPosition.snappedX
                    objectTransform.snappedY = newPosition.snappedY
                    /*section.snapping.show(
                        newPosition.snappedX,
                        newPosition.snappedY,
                        section.userData.offsetX,
                        section.userData.offsetY,
                    )*/
                }
                
                
                // ----------------------------------------
                // Rotate
                // ----------------------------------------
                if (actionType === "rotate") {
                    
                    const rotate = intersection.rotate
                    const initialAngle = Math.atan2(rotate.yStart - rotate.yCenter, rotate.xStart - rotate.xCenter) * (180 / Math.PI);
                    const currentAngle = Math.atan2(mouse.y - rotate.yCenter, mouse.x - rotate.xCenter) * (180 / Math.PI);
                    
                    objectTransform.width = object.width
                    objectTransform.height = object.height
                    objectTransform.angle = rotateSnapping(currentAngle - initialAngle)
                    
                }
                
                
                // ----------------------------------------
                // Resize
                // ----------------------------------------
                if (actionType === "nwse-resize" || actionType === "nesw-resize") {
                    //console.log("resize")
                    const newSize = transformResize({
                        center: {
                            x: object.offset_x,
                            y: object.offset_y
                        },
                        pointStart: threeViewer.intersection.startDrag,
                        mouse: mouse,
                        size: threeViewer.intersection.startSize
                    })
                    
                    objectTransform.width = newSize.width
                    objectTransform.height = newSize.height
                }
                
                
                // ----------------------------------------
                // Pinch
                // ----------------------------------------
                if (actionType === "pinch" && intersection.downSecond) {
                    
                    // Scale
                    
                    function calculateDistance2D(first, second) {
                        const dx = second.x - first.x;
                        const dy = second.y - first.y;
                        return Math.sqrt(dx * dx + dy * dy);
                    }
                    
                    const secondPoints = {
                        x: ((event.touches[1].pageX - (threeViewer.element.getBoundingClientRect().left)) / threeViewer.element.clientWidth) * 2 - 1,
                        y: -((event.touches[1].pageY - (threeViewer.element.getBoundingClientRect().top)) / threeViewer.element.clientHeight) * 2 + 1
                    }
                    
                    const distanceStart = calculateDistance2D(intersection.down, intersection.downSecond)
                    const distanceEnd = calculateDistance2D(secondPoints, threeViewer.intersection.mouse)
                    
                    const scale = distanceEnd / distanceStart
                    const speed = Math.abs(1 - scale) + 1
                    const speedScale = scale > 1 ? scale * speed : scale * speed
                    
                    const width = intersection.startSize.x * speedScale
                    const height = intersection.startSize.y * speedScale
                    
                    const newSize = {width, height}
                    limitElementSize(intersection.startSize, newSize)
                    
                    // Rotation
                    
                    const initialAngle = Math.atan2(intersection.downSecond.y - intersection.down.y, intersection.downSecond.x - intersection.down.x) * (180 / Math.PI);
                    const currentAngle = Math.atan2(secondPoints.y - threeViewer.intersection.mouse.y, secondPoints.x - threeViewer.intersection.mouse.x) * (180 / Math.PI);
                    
                    const difAngle = currentAngle - initialAngle;
                    const newAngle = intersection.startAngle + difAngle
                    
                    objectTransform.width = newSize.width
                    objectTransform.height = newSize.height
                    objectTransform.angle = rotateSnapping(newAngle)
                    
                    
                }
                
                
                // ----------------------------------------
                // Call transformation manager
                // ----------------------------------------
                if (Object.keys(objectTransform).length) {

                    //-----------------------------------------
                    // Update the element
                    //-----------------------------------------
                    manageTransformation(objectTransform)
                }
            }
        }
        
        
    }
}