import React, { memo, useEffect, useState, useCallback, useMemo } from "react";

const fetchAndCleanSvg = async (url) => {
    try {
        const response = await fetch(url);
        const svgText = await response.text();
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(svgText, "image/svg+xml");
        const svgElement = svgDoc.querySelector('svg');
        svgElement.setAttribute('width', '100%');
        svgElement.setAttribute('height', '100%');
        return new XMLSerializer().serializeToString(svgElement);
    } catch (error) {
        console.error('Error fetching or processing SVG:', error);
        return null;
    }
};

const ImageComponent = memo(({ src }) => {
    const [loaded, setLoaded] = useState(false);
    const [svgContent, setSvgContent] = useState('');
    
    const loadSvgContent = useCallback(async () => {
        if (src.endsWith('.svg')) {
            const cleanedSvg = await fetchAndCleanSvg(src);
            if (cleanedSvg) {
                setSvgContent(cleanedSvg);
                setLoaded(true);
            }
        } else {
            const img = new Image();
            img.src = src;
            img.onload = () => setLoaded(true);
            img.onerror = () => setLoaded(true); // handle errors by considering the image as loaded
        }
    }, [src]);
    
    useEffect(() => {
        loadSvgContent();
    }, [loadSvgContent]);
    
    const containerStyle = useMemo(() => ({
        position: 'relative',
        pointerEvents: 'none',
        height: svgContent? '100%' : '',
        width: svgContent? '100%' : '',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
    }), [svgContent]);
    
    const loadingImageStyle = useMemo(() => ({
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        zIndex: 1,
        transition: 'opacity 0.3s',
        opacity: loaded ? 0 : 1
    }), [loaded]);
    
    const contentStyle = useMemo(() => ({
        position: 'relative',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        zIndex: 1,
        transition: 'opacity 0.3s',
        opacity: loaded ? 1 : 0
    }), [loaded]);
    
    return (
        <div style={containerStyle}>
            <img
                src={'/assets/img/Loading.gif'}
                style={loadingImageStyle}
                alt="Loading"
            />
            {svgContent ? (
                <div
                    dangerouslySetInnerHTML={{ __html: svgContent }}
                    style={contentStyle}
                />
            ) : (
                <div
                    style={contentStyle}
                >
                    <embed
                        src={src}
                        alt=""
                        style={contentStyle}
                        width={"100%"}
                        height={"100%"}
                    />
                </div>
            )}
        </div>
    );
});

export default ImageComponent;
