import getMouseDownIntersection from "../getMouseDownIntersection";
import createSnapPoints from "../../events/snapping/createSnapPoints";
import store from "../../../../store";
import {snapshot} from "valtio";


export default async function pointerDown(event) {
    //console.log("pointerDown", event)
    
    const threeViewer = window.threeViewer;
    const snap = snapshot(store);
    const isFullScreen = snap.ui.fullScreen;
    
    const isTwoFinger = event?.touches?.length > 1;
    const isMobile = threeViewer.isMobile;
    
    document.body.className = "touched"
    window.threeViewer.touchCount = event?.touches?.length || 0;
    
    // Don't do anything if we're in full screen mode
    if (!isFullScreen) {
        
        const eventX = event.type === "touchstart" ? event.touches[0].clientX : event.clientX;
        const eventY = event.type === "touchstart" ? event.touches[0].clientY : event.clientY;
        
        const x = ((eventX - (threeViewer.element.getBoundingClientRect().left)) / threeViewer.element.clientWidth) * 2 - 1;
        const y = -((eventY - (threeViewer.element.getBoundingClientRect().top)) / threeViewer.element.clientHeight) * 2 + 1;
        
        threeViewer.intersection.down = {x: x, y: y}
        
        
        if (isTwoFinger) {
            const eventXSecond = event.touches?.[1]?.clientX;
            const eventYSecond = event.touches?.[1]?.clientY;
            
            const xSecond = ((eventXSecond - (threeViewer.element.getBoundingClientRect().left)) / threeViewer.element.clientWidth) * 2 - 1;
            const ySecond = -((eventYSecond - (threeViewer.element.getBoundingClientRect().top)) / threeViewer.element.clientHeight) * 2 + 1;
            
            threeViewer.intersection.downSecond = {x: xSecond, y: ySecond}
        } else {
            threeViewer.intersection.downSecond = null
        }
        
        
        const intersectionElement = await getMouseDownIntersection(isTwoFinger)// window.threeViewer.isMobile ? getMouseDownIntersectionMobile() : await getMouseDownIntersection()
        
        // Create snap points for selected section
        createSnapPoints(snap.selectedSectionId, intersectionElement);
        
        if (!isMobile) {
            store.selectedElementId = intersectionElement;
        } else if (intersectionElement) {
            store.selectedElementId = intersectionElement;
        }
        
    }
    
    
}