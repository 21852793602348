export default function moveSnapping(x, y, snapping, position) {
    // Define the threshold for snapping
    
    const distance = window.threeViewer.camera.position.distanceTo(window.threeViewer.center)
    const threshold = (2 - (1.5 - distance));
    
    let snappedX = []
    let snappedY = []
    
    
    // Calculate element edges
    const elementLeft = snapping.left + x
    const elementRight = snapping.right + x
    const elementTop = y - snapping.top
    const elementBottom = y - snapping.bottom
    
    // Get snapping points from the store
    const snapPoints = window.threeViewer.snapPoints || {x: [0], y: [0]};
    const snapPointsX = snapPoints.x;
    const snapPointsY = snapPoints.y;
    
    
    // Find the closest snapping point for X and Y
    let closestSnapXFromCenter = findClosestSnapPoint(x, snapPointsX);
    let closestSnapXFromRight = findClosestSnapPoint(elementRight, snapPointsX);
    let closestSnapXFromLeft = findClosestSnapPoint(elementLeft, snapPointsX);
    
    let closestSnapYFromCenter = findClosestSnapPoint(y, snapPointsY);
    let closestSnapYFromTop = findClosestSnapPoint(elementTop, snapPointsY);
    let closestSnapYFromBottom = findClosestSnapPoint(elementBottom, snapPointsY);
    
    
    // Snap X coordinate
    
    if (Math.abs(closestSnapXFromRight - elementRight) <= threshold) {
        x = closestSnapXFromRight - snapping.right
        snappedX.push(closestSnapXFromRight)
    }
    if (Math.abs(closestSnapXFromLeft - elementLeft) <= threshold) {
        x = closestSnapXFromLeft - snapping.left
        snappedX.push(closestSnapXFromLeft)
    }
    if (Math.abs(closestSnapXFromCenter - x) <= threshold) {
        x = closestSnapXFromCenter;
        snappedX.push(closestSnapXFromCenter)
    }
    
    
    // Snap Y coordinate
    
    if (Math.abs(closestSnapYFromTop - elementTop) <= threshold) {
        y = closestSnapYFromTop + snapping.top
        snappedY.push(closestSnapYFromTop)
    }
    if (Math.abs(closestSnapYFromBottom - elementBottom) <= threshold) {
        y = closestSnapYFromBottom + snapping.bottom
        snappedY.push(closestSnapYFromBottom)
    }
    if (Math.abs(closestSnapYFromCenter - y) <= threshold) {
        y = closestSnapYFromCenter;
        snappedY.push(closestSnapYFromCenter)
    }
    
    
    // Return the new coordinates
    return {x, y, snappedX, snappedY};
}

// Helper function to find the closest snap point
function findClosestSnapPoint(value, snapPoints) {
    return snapPoints.reduce((prev, curr) => {
        return (Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev);
    });
}
