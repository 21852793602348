export default function createCssWithMediaQuery (css, type) {
    const size = {
        "mobile": 1200,
        "tablet": 1200
    }
    return (`/* --- ${type} ---*/
@media screen and (max-width: ${size[type]}px) {
${css}
}`)
;

}