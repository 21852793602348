export default function mapProductData(dataList) {
    return dataList.map((data) => ({
        '3d_image_url': data['3d_image_url'],// || 'https://storage.googleapis.com/3dplugin/db547b5c-94f9-492c-b980-ab263ee265a4/glb_files/(54020)__40oz-bottle-w/-chug-lid.glb',
        '2d_image_url': data['2d_image_url'],
        'name': data['name'],
        'sku': data['sku'],
        'variant_name': data['variant_name'],
        'product_id': data['product_id'],
        'variant_id': data['variant_id'],
        'price': data['price'],
        'is_bundle': data['is_bundle'],
        'bundle_quantity': data['bundle_quantity'],
        'is_enable_customization': data['is_enable_customization'],
        'pricing': data['pricing'],
        'bundle_items': data['bundle_items'],
        'options': data['options'] || {},
        'available': true,
        'sections': data['sections']//[data['sections'][1]], //
    }));
}