import * as THREE from "three";


const cornerCircle = (color) => {
    let sphereGeometry = new THREE.SphereGeometry(0.0025, 22, 22); // radius, widthSegments, heightSegments
    let sphereMaterial = new THREE.MeshBasicMaterial({
        color: color,
        //antialias: true
    });
    let sphereMesh = new THREE.Mesh(sphereGeometry, sphereMaterial);
    
    sphereMesh.layers.set(4); // 3 is the layer for decal helper
    sphereMesh.name = "cornerCircle";
    sphereMesh.type = "decalHelper";
    sphereMesh.userData = {
        visible: true,
        helperType: "scale",
        cursorType: "scale",
        originalColor: color,
        warningColor: new THREE.Color('rgb(255,0,0)'),
    }
    sphereMesh.helperType = "scale";
    
    return sphereMesh;
}

export default cornerCircle;