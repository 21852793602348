import * as THREE from "three";


const rotate = () => {
    let planeGeo = new THREE.CylinderGeometry(0.006, 0.006, 0.0015, 32);
    let planeMat = new THREE.MeshBasicMaterial({
        side: THREE.DoubleSide,
        map: new THREE.TextureLoader().load("./assets/img/icons/rotationHelper.png"),
        transparent: false,
        
        // show in front of everything else
        //depthWrite: false,
        //depthTest: false,
    });
    let planeMesh = new THREE.Mesh(planeGeo, planeMat);
    planeMesh.rotation.x = Math.PI / 2;
    
    planeMesh.name = "rotationHelper"
    planeMesh.userData = {
        visible: true,
        helperType: "rotate",
        cursorType: "rotate",
    }
    planeMesh["type"] = "decalHelper"
    planeMesh.layers.set(4);
    return planeMesh
}

export default rotate;