export default async function applyEffects({id, customValues}) {
    return new Promise((resolve, reject) => {
        console.log('custom?.contrast', customValues)
        const imgElement = window.imageCache.get(id + '_rawImage');
        applyBlackWhiteThreshold(imgElement, customValues).then((url) => {
            
            /* const can = document.getElementById('memet');
             can.src = url//imgElement.src//URL.createObjectURL(imgElement)
             */
            resolve(url)
        });
        
    })
}


async function applyBlackWhiteThreshold(imageElement, customValues) {
    return new Promise((resolve, reject) => {
        // Create a canvas and get the WebGL context
        var canvas = document.createElement('canvas');
        //var gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
        var gl = canvas.getContext('webgl', { antialias: true }) || canvas.getContext('experimental-webgl', { antialias: true });
        
        canvas.width = imageElement.width;
        canvas.height = imageElement.height;
        
        // Vertex shader code
        var vsSource = `
            attribute vec4 aVertexPosition;
            attribute vec2 aTextureCoord;
            varying highp vec2 vTextureCoord;
            void main(void) {
                gl_Position = aVertexPosition;
                vTextureCoord = vec2(aTextureCoord.x, 1.0 - aTextureCoord.y);
            }
        `;
        
        // Fragment shader code
        /*var fsSource = `
            varying highp vec2 vTextureCoord;
            uniform sampler2D uSampler;
            uniform highp float uThreshold;
            uniform int uIsInverted;
            void main(void) {
                highp vec4 texelColor = texture2D(uSampler, vTextureCoord);
                highp float gray = 0.3 * texelColor.r + 0.59 * texelColor.g + 0.11 * texelColor.b;
                highp float bw = (gray >= uThreshold / 255.0) ? 1.0 : 0.0;
        
                if (uIsInverted == 1) {
                    bw = 1.0 - bw;
                }
        
                gl_FragColor = vec4(vec3(bw), texelColor.a); // Preserving the original alpha value
            }
        `;*/
        var fsSource = `
            precision highp float;

            varying vec2 vTextureCoord;
            uniform sampler2D uSampler;
            uniform float uThreshold;
            uniform int uIsInverted;
            
            void main(void) {
                vec4 texelColor = texture2D(uSampler, vTextureCoord);
                float gray = 0.3 * texelColor.r + 0.59 * texelColor.g + 0.11 * texelColor.b;
                float edgeSoftness = 0.02; // Adjust for more or less softness
                float lowerBound = uThreshold / 255.0 - edgeSoftness;
                float upperBound = uThreshold / 255.0 + edgeSoftness;
                float bw = smoothstep(lowerBound, upperBound, gray);
            
                if (uIsInverted == 1) {
                    bw = 1.0 - bw;
                }
            
                gl_FragColor = vec4(vec3(bw), texelColor.a);
            }
        `;
        
        // Initialize shader program
        const shaderProgram = initShaderProgram(gl, vsSource, fsSource);
        if (!shaderProgram) {
            reject('Shader program initialization failed.');
            return;
        }
        
        // Attributes from the vertex shader
        const positionLocation = gl.getAttribLocation(shaderProgram, 'aVertexPosition');
        const texcoordLocation = gl.getAttribLocation(shaderProgram, 'aTextureCoord');
        
        // Vertex buffer
        const vertexBuffer = gl.createBuffer();
        gl.bindBuffer(gl.ARRAY_BUFFER, vertexBuffer);
        const vertices = new Float32Array([
            -1.0, 1.0, 0.0, 1.0,
            -1.0, -1.0, 0.0, 0.0,
            1.0, 1.0, 1.0, 1.0,
            1.0, -1.0, 1.0, 0.0,
        ]);
        gl.bufferData(gl.ARRAY_BUFFER, vertices, gl.STATIC_DRAW);
        gl.vertexAttribPointer(positionLocation, 2, gl.FLOAT, false, 16, 0);
        gl.enableVertexAttribArray(positionLocation);
        gl.vertexAttribPointer(texcoordLocation, 2, gl.FLOAT, false, 16, 8);
        gl.enableVertexAttribArray(texcoordLocation);
        
        // Create a texture from the image element
        const texture = gl.createTexture();
        /*gl.bindTexture(gl.TEXTURE_2D, texture);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, imageElement);*/
        
        gl.bindTexture(gl.TEXTURE_2D, texture);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR); // Use LINEAR Filtering for MIN
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR); // Use LINEAR Filtering for MAG
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, imageElement);
        
        // Set the shader program to use
        gl.useProgram(shaderProgram);
        
        // Set the uniform values
        gl.uniform1f(gl.getUniformLocation(shaderProgram, 'uThreshold'), customValues?.contrast || 100);
        gl.uniform1i(gl.getUniformLocation(shaderProgram, 'uIsInverted'), customValues?.effect?.invert ? 1 : 0); // Pass as int
        console.log('customValues?.invert', customValues)
        // Draw the image
        gl.viewport(0, 0, canvas.width, canvas.height);
        gl.clearColor(0, 0, 0, 0);
        gl.clear(gl.COLOR_BUFFER_BIT);
        gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
        
        // Convert the canvas to a Blob and resolve the promise with the Blob's URL
        canvas.toBlob(blob => {
            if (!blob) {
                reject('Could not create a blob from the canvas');
                return;
            }
            const url = URL.createObjectURL(blob);
            
            // Clean up WebGL resources
            gl.deleteTexture(texture);
            gl.deleteBuffer(vertexBuffer);
            gl.deleteProgram(shaderProgram);
            
            // Nullify the canvas and context references to help with garbage collection
            canvas = null;
            gl = null;
            
            // Resolve the promise with the URL
            resolve(url);
        });
        
        
        // Function to create shader
        function initShaderProgram(gl, vsSource, fsSource) {
            const vertexShader = loadShader(gl, gl.VERTEX_SHADER, vsSource);
            const fragmentShader = loadShader(gl, gl.FRAGMENT_SHADER, fsSource);
            
            // Create the shader program
            const shaderProgram = gl.createProgram();
            gl.attachShader(shaderProgram, vertexShader);
            gl.attachShader(shaderProgram, fragmentShader);
            gl.linkProgram(shaderProgram);
            
            // If creating the shader program failed, alert
            if (!gl.getProgramParameter(shaderProgram, gl.LINK_STATUS)) {
                alert('Unable to initialize the shader program: ' + gl.getProgramInfoLog(shaderProgram));
                return null;
            }
            
            return shaderProgram;
        }
        
        function loadShader(gl, type, source) {
            const shader = gl.createShader(type);
            gl.shaderSource(shader, source);
            gl.compileShader(shader);
            
            // See if it compiled successfully
            if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
                alert('An error occurred compiling the shaders: ' + gl.getShaderInfoLog(shader));
                gl.deleteShader(shader);
                return null;
            }
            
            return shader;
        }
    });
}



