import limitElementSize from "./limitElementSize";

export default function transformResize({
    center,
    pointStart,
    mouse,
    size
}) {
    
    // Calculate distance between two points
    function calculateDistance(x, y, x1, y1) {
        return Math.sqrt(Math.pow(x1 - x, 2) + Math.pow(y1 - y, 2));
    }
    
    // Start Distance
    const distanceStart = calculateDistance(pointStart.x, pointStart.y, center.x, center.y)
    
    // End Distance
    const distanceEnd = calculateDistance(mouse.x, mouse.y, center.x, center.y)
    
    // Calculate scale
    const scale = distanceEnd / distanceStart
    const width = size.x * scale
    const height = size.y * scale

    const newSize =  {
        width,
        height
    }
    limitElementSize(size, newSize)
    return newSize
}