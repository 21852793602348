import {LineGeometry} from "three/addons/lines/LineGeometry";
import {LineMaterial} from "three/addons/lines/LineMaterial";
import {Line2} from "three/addons/lines/Line2";


export default function lines(color) {
    // Initialize the geometry and material
    const geometry = new LineGeometry();
    const material = new LineMaterial({
        color: color,
        linewidth: 0.002,
        alphaToCoverage: true,
        //antialias: true,
    });
    
    // Create the line object
    const line = new Line2(geometry, material);
    line.name = "refLine";
    // Add a resize method to the line object
    line.resize = function (points) {
        let positions = [];
        for (let i = 0; i < points.length; i++) {
            positions.push(points[i].x, points[i].y, points[i].z);
        }
        positions.push(points[0].x, points[0].y, points[0].z);
        this.geometry.setPositions(positions);
        this.geometry.attributes.position.needsUpdate = true;
        this.computeLineDistances();
    };
    
    return line;
}
