import { SpringValue } from '@react-spring/web';
import { easings } from '@react-spring/web';

export default async function animateValue(startValue, endValue, duration, onUpdate, easingFunction = "easeOutCubic", condition = true) {
    const value = new SpringValue(startValue);
    if (condition) {
        await value.start({
            to: endValue,
            config: {
                duration,
                easing: easings[easingFunction], // Apply the easing function
            },
            onChange: (currentValue) => onUpdate(currentValue),
        });
    } else {
        onUpdate(endValue);
    }
    
}