import * as THREE from "three";


const plane = () => {
    let plane = new THREE.PlaneGeometry(0.1, 0.1);
    let material = new THREE.MeshBasicMaterial({
        color: 0xffffff,
        side: THREE.DoubleSide,
        transparent: true,
        opacity: 1,
        depthWrite: true,
        depthTest: true,
        polygonOffset: true,
        polygonOffsetFactor: -4,
    });
    let mesh = new THREE.Mesh(plane, material);
    mesh.visible = false;
    mesh.userData = {
        visible: true,
        helperType: "translate",
        cursorType: "move",
    }
    mesh.type = "decalHelper"
    mesh.name = "helperPlane"
    mesh.layers.set(4);
    mesh.resize = (width, height, plane) => {
        // Dispose of the old geometry
        plane.geometry.dispose();
        // Create a new geometry with the new size
        plane.geometry = new THREE.PlaneGeometry(width, height);
    };
    return mesh
}
export default plane;