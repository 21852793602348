import React from "react";
import store from "../../store";
import {useSnapshot} from "valtio";
import ThemeElement from "../../util/theme/ThemeElement";


export default function BundleProduct() {
    const snap = useSnapshot(store)
    const productList = snap.product
    
    const elementList = snap.elements
    const selectedItemSKU = snap.selectedProductSKU
    const selectedItemIndex = productList.findIndex(p => p.sku === selectedItemSKU)
    const sectionList = snap.sections
    const selectedItem = productList.find(p => p.sku === selectedItemSKU);
    
    
    const itemList = productList.map(product => {
        const sections = sectionList
        .filter(section => section.product_sku === product.sku)
        .map(section => {
            // Check if there are elements for this section
            const hasElements = elementList.some(element => element.sectionId === section.section_id);
            return {
                ...section,
                hasElements: hasElements
            };
        });
        
        return {
            ...product,
            sections: sections
        };
    });
    
    
    const handleSelectItem = async (item) => {
        store.selectedSectionId = sectionList.filter(section => section.product_sku === item.sku)[0].section_id;
        store.selectedBundleProductSKU = item.sku;
    }
    
    
    return (
        <ThemeElement
            name={"BundleProduct"}
            data={{
                selectedItem:selectedItem,
                handleSelectItem:handleSelectItem,
                selectedItemIndex:selectedItemIndex,
                itemList:itemList,
                
            }}
        />
        
    )
}