import svgpath from 'svgpath';

import getPathFromSVG from './getPathFromSVG';
import pathBounds from './pathBounds';
import findAspectRatio from './findAspectRatio';
import resizeSvg from './resizeSvg';
import svgToPng from "./svgToPng";
import {convertToSvg} from "./convertToSvg";
import svgOutline from "../elements/util/svgOutline";

const cleanSvg = async (string, svgWidth, svgMaxHeight) => {
    let svgString = string
    
    function checkSvgForSpecificElements(svgText) {
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(svgText, "image/svg+xml");
        
        const hasSvgTag = svgDoc.querySelectorAll('svg').length > 0; // Check for <svg> tags
        const hasImage = svgDoc.querySelectorAll('image').length > 0; // Check for <image> tags
        const hasGroup = svgDoc.querySelectorAll('g').length > 0; // Check for <g> (group) tags
        
        
        return {
            cleanSvg: !(hasImage || hasGroup),
            isSvg: hasSvgTag,
        } // Return false if any of the conditions are met
    }
    
    const isClean = checkSvgForSpecificElements(svgString);
    if (!isClean.cleanSvg) {
        let pngUrl = null
        if (isClean.isSvg) {
            const blob = new Blob([svgString], {type: 'image/svg+xml'});
            const file = new File([blob], 'svg.svg', {type: 'image/svg+xml'});
            const png = await svgToPng(file)
            pngUrl = URL.createObjectURL(png.file)
        } else {
        
        }
        
        svgString = await convertToSvg(pngUrl)
        URL.revokeObjectURL(pngUrl)
        
    }
    
    const svgPath = getPathFromSVG(svgString); // Get the path from the SVG
    let svgBounds = pathBounds(svgPath); // Calculate bounds of the original path
    
    // Move svg to left top corner and calculate the new width and height
    let translatedSvgPath = svgpath(svgPath).translate(-svgBounds[0], -svgBounds[1]).toString();
    
    let width = svgBounds[4]; // Calculate new width
    let height = svgBounds[5]; // Calculate new height
    
    if (svgMaxHeight) {
        
        let resizeSvg = svgpath(translatedSvgPath)
        if (width > svgWidth) {
            const scaleW = svgWidth / width
            resizeSvg = resizeSvg.scale(scaleW);
        }
        if (height > svgMaxHeight) {
            const scaleH = svgMaxHeight / height
            resizeSvg = resizeSvg.scale(scaleH);
        }
        let svgBounds = pathBounds(resizeSvg.toString());

        width = svgBounds[4]
        height = svgBounds[5]
        
        translatedSvgPath = resizeSvg.toString()
        
    }
    // SVG with updated width, height, and viewBox
    const outlineObj = await svgOutline(translatedSvgPath);
    const svg = `
        <svg width="${width}" height="${height}" fill-rule="evenodd" viewBox="0 0 ${width} ${height}" xmlns="http://www.w3.org/2000/svg">
            <path d="${translatedSvgPath}" fill="#bbbbbb" stroke="black" stroke-width="0"/>
        </svg>
    `;
    
    
    
    // Resize the SVG to the specified size
    if (svgMaxHeight) {
        return {
            svg: resizeSvg(svg, svgWidth, svgMaxHeight),// Resize the SVG to the specified size
            outline: outlineObj
        }
    } else if (svgWidth) {
        const aspectRatio = findAspectRatio(svg); // Get aspect ratio of the SVG
        return {
            svg: resizeSvg(svg, svgWidth, svgWidth / aspectRatio),// Resize the SVG to the specified size
            outline: outlineObj
        }
    } else {
        return {
            svg: svg,
            outline: outlineObj
        };
    }
};

export default cleanSvg;
