import * as THREE from "three";
import {DecalGeometry} from "three/addons/geometries/DecalGeometry";
import mergeImages from "../../svg/mergeImages";


export class DecalMap extends THREE.Mesh {
    constructor(section) {
        
        const euler = section.euler
        const point = section.point
        const size = section.size
        const decalGeometry = new DecalGeometry(section, point, euler, size)
        const emptyTexture = new THREE.Texture();
        super(decalGeometry);
        
        
        this.material = window.productColorObject.materialList.normal.clone()
        this.material.map = emptyTexture
        this.sectionId = section.userData.id
        this.size = size
        
        
        this.type = "mapDecal";
        this.name = "mapDecal";
        this.layers.set(7) // 7 is the layer for map decals
        
        // bind functions
        this.update = this.update.bind(this)
        this.initCanvas = this.initCanvas.bind(this)
    }
    
    initCanvas () {
        
        if (!this.canvas) {
            const canvas = document.getElementById('sectionCanvas-' + this.sectionId)
            if (canvas) {
                canvas.generateMipmaps = true;
                canvas.minFilter = THREE.LinearMipmapLinearFilter;
                this.canvas = canvas
                return true
            }
            return false
        }
        return true
    }
    // show snapping
    async update() {
        
        const canvas = this.initCanvas();
        if (canvas) {
            await mergeImages(this.sectionId)
            
            this.material.map.dispose()
            this.material.map = new THREE.CanvasTexture(this.canvas)
            
            this.material.needsUpdate = true
            
            this.visible = true
            return true
        }
        
    }
}
