export default function getCoverSize(section, image, percentage = 1) {
    
    const sectionWidth = section.width
    const sectionHeight = section.height
    
    const imageWidth = image.width
    const imageHeight = image.height
    
    
    let width, height
    
    width = sectionWidth * percentage
    height = width * (imageHeight / imageWidth)
    
    if (height > sectionHeight * percentage) {
        height = sectionHeight * percentage
        width = height * (imageWidth / imageHeight)
    }
    
    return {
        width: width,
        height: height
    }
    
}