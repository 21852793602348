export default function rotateSnapping(angle) {
    
    // Define the threshold for snapping
    const threshold = 5;
    
    
    // Define snapping points
    const snapPoints = [0, 45, 90, 135, 180, 225, 270, 315, 360];
    
    // Adjust the angle to be within [0, 360]
    angle = angle % 360;
    if (angle < 0) angle += 360;
    
    // Find the closest snapping point
    let closestSnap = snapPoints.reduce((prev, curr) => {
        return (Math.abs(curr - angle) < Math.abs(prev - angle) ? curr : prev);
    });
    
    // Check if the angle is within the threshold of the closest snapping point
    if (Math.abs(closestSnap - angle) <= threshold) {
        return closestSnap;
    }
    
    // If the angle is not within the threshold, return the original angle
    return angle;
}