import * as THREE from "three";
import store from "../../../store";
import {snapshot} from "valtio";
import findNearestElement from "./findNearestElement";
import findIntersectionWithSection from "./findIntersectionWithSection";


// Pre-instantiated raycasters
const elementsRaycaster = new THREE.Raycaster();
const helperRaycaster = new THREE.Raycaster();
const dummySketchPlaneRaycaster = new THREE.Raycaster();

// Reusable Vector3 and Euler instances
const reusableMouseDownVector = new THREE.Vector3();


export default async function getMouseDownIntersection(isTwoFinger, mouseUp = false) {
    const snap = snapshot(store);
    // Get the selected element id
    const selectedElementId = snap.selectedElementId;
    const selectedSectionId = snap.selectedSectionId;
    const section = snap.sections.find(s => s.section_id === selectedSectionId)
    
    const threeViewer = window.threeViewer;
    const isMobile = threeViewer.isMobile;
    
    
    let intersects
    
    // Get the model
    const model = threeViewer.scene.children.find(child => child.type === "section" && child.userData.id === selectedSectionId);
    const dummySketchPlane = threeViewer.scene.getObjectByName("dummySketchPlane");
    
    
    const elements = snap.elements.filter(e => e.sectionId === selectedSectionId);
    
    // Get the mouse position
    const mouse = threeViewer.intersection.down
    
    helperRaycaster.setFromCamera(mouse, threeViewer.camera);
    helperRaycaster.params.Mesh.threshold = 1;
    helperRaycaster.layers.set(4);
    
    dummySketchPlaneRaycaster.setFromCamera(mouse, threeViewer.camera);
    dummySketchPlaneRaycaster.layers.set(6);
    
    // Check for intersections between the ray and the model
    let helperIntersects = helperRaycaster.intersectObject(model, true)
    let dummySketchPlaneIntersects = dummySketchPlaneRaycaster.intersectObject(dummySketchPlane)[0];
    
    if (!dummySketchPlaneIntersects) return
    
    let uv = findIntersectionWithSection(mouse, model)

    if (!uv) {
        uv = findIntersectionWithSection(mouse, model, true)
    }
    if(!uv) return

    uv.y = uv.y + section.offset_Y
    let selected = findNearestElement(uv.x, uv.y, elements, selectedElementId, section)
    
    
    if (selectedElementId) {
        
        
        // Change cursor type
        if (isMobile) {
            if (isTwoFinger) selected = elements.find(e => e.elementId === selectedElementId)
            threeViewer.intersection.action = isTwoFinger ? "pinch" : "tap"
            if (selectedElementId) {
                threeViewer.controls.enabled = false;
            } else {
                model?.helper?.hide()
            }
        } else if (helperIntersects.length > 0) {
            
            // Update selected based on store data
            selected = elements.find(e => e.elementId === selectedElementId)
            threeViewer.intersection.action = helperIntersects[0].object.userData.cursorType
            if (selected) threeViewer.controls.enabled = false;
            
            if (helperIntersects[0].object.userData.cursorType === "rotate") {
                threeViewer.intersection.rotate = {
                    xStart: helperIntersects[0].object.position.x * 1000,
                    yStart: helperIntersects[0].object.position.y * 1000,
                    xCenter: helperIntersects[0].object.parent.position.x * 1000,
                    yCenter: helperIntersects[0].object.parent.position.y * 1000,
                };
            }
            
        }
        
        if (selected?.elementId) {
            const selectedX = selected.offset_x
            const selectedY = selected.offset_y
            
            
            threeViewer.intersection.active = selected;
            
            threeViewer.intersection.difDrag = reusableMouseDownVector.set(
                uv.x - selectedX,
                uv.y - selectedY,
                uv.z
            );
            
            
            threeViewer.intersection.startDrag = {
                x: uv.x,
                y: uv.y,
            };
            
            threeViewer.intersection.startAngle = selected.angle;
            
            threeViewer.intersection.startSize = {x: selected.width, y: selected.height}
            
            
        }
    }
    
    return selected?.elementId
    
    
}
