import resizeImage from "./resizeImage";
import cacheImages from "../../svg/cacheImages";


export default async function initialEffect({rawFile, id}) {
    
    const utils = {
        calculateOptimalThreshold: async (imageData) => {
            
            const histogram = await utils.calculateHistogram(imageData);
            const total = imageData.length / 4;
            const optimalThreshold = await utils.otsusMethod(histogram, total);
            
            // Define the offset as a percentage of the optimal threshold
            const offsetPercentage = 0.4; // for example, 10%
            const someOffset = Math.round(optimalThreshold * offsetPercentage);
            
            let minThreshold = optimalThreshold - someOffset; // For 'more white' but not totally white
            let maxThreshold = optimalThreshold + someOffset; // For 'more black' but not totally black
            
            // Ensure min and max thresholds are within bounds
            minThreshold = Math.min(Math.max(minThreshold, 0), 255);
            maxThreshold = Math.max(Math.min(maxThreshold, 255), 0);
            
            // Return the calculated thresholds and optimal threshold
            return {
                min: minThreshold,
                max: maxThreshold,
                avg: optimalThreshold
            };
            
        },
        calculateHistogram: async (imageData) => {
            const histogram = new Array(256).fill(0);
            for (let i = 0; i < imageData.length; i += 4) {
                const gray = parseInt(0.3 * imageData[i] + 0.59 * imageData[i + 1] + 0.11 * imageData[i + 2]);
                histogram[gray]++;
            }
            return histogram;
        },
        otsusMethod: async (histogram, total) => {
            let sum = 0;
            for (let i = 1; i < 256; ++i) {
                sum += i * histogram[i];
            }
            
            let sumB = 0;
            let wB = 0;
            let wF = 0;
            let mB;
            let mF;
            let max = 0;
            let between = 0;
            let threshold1 = 0;
            let threshold2 = 0;
            
            for (let i = 0; i < 256; ++i) {
                wB += histogram[i];
                if (wB === 0) continue;
                wF = total - wB;
                if (wF === 0) break;
                
                sumB += i * histogram[i];
                mB = sumB / wB;
                mF = (sum - sumB) / wF;
                
                between = wB * wF * Math.pow(mB - mF, 2);
                
                if (between > max) {
                    threshold1 = i;
                    if (between > max) {
                        max = between;
                        threshold2 = i;
                    }
                }
            }
            return ((threshold1 + threshold2) / 2.0) || 100;
        },
    }
    
    const image = await resizeImage(rawFile, 2000, 2000)
    
    const rawImageId = await cacheImages(image.blob, id + '_rawImage')
    
    const optimalValue = await utils.calculateOptimalThreshold(image.imageData)
    const contrast = optimalValue.avg
    
    
    return ({
        rawImage: URL.createObjectURL(image.blob),
        width: image.width,
        height: image.height,
        optimalValue: optimalValue,
        contrast: contrast,
        rawImageId: rawImageId,
        
        /*        rawImage: image.blob,
                rawImageId: rawImageId,
                
                width: image.width,
                height: image.height,
                optimalValue: optimalValue,
                contrast: contrast,*/
    })
}