export default (value, optionsList) => {
    if (typeof value === "string" || typeof value === "number") {
        const returnValue = optionsList?.find(option => String(option?.value) === String(value));
        return returnValue
    } else if (Array.isArray(value)) {
        const returnValue = optionsList?.filter(option => value?.includes(option?.value));
        return returnValue
    } else if (String(value) !== "0") {
        if (!value || value === "") return null;
    } else {
        return null;
    }
    
};