import potrace from 'potrace';


export function convertToSvg(image) {
    return new Promise((resolve, reject) => {
        potrace.trace(image, function (err, svg) {
            if (err) {
                reject(err);
            } else {
                resolve(svg);
            }
        });
        
        /* potrace.trace(image, {
            turnpolicy: potrace.Potrace.TURNPOLICY_WHITE,
            
        },function (err, svg) {
            if (err) {
                reject(err);
            } else {
                resolve(svg);
            }
        });*/
    });
}
