function isBase64(value) {
    if (typeof value !== 'string') {
        return false;
    }
    
    const parts = value.split(';base64,');
    if (parts.length !== 2) {
        return false;
    }
    
    const contentType = parts[0].split(':')[1];
    const base64 = parts[1];
    
    try {
        const decoded = atob(base64);
        const encoded = btoa(decoded);
        if (encoded !== base64) {
            return false;
        }
    } catch (err) {
        return false;
    }
    
    return contentType !== undefined && base64 !== undefined;
}

export default isBase64