import rotate from "./helper/rotate";
import cornerCircle from "./helper/cornerCircle";
import plane from "./helper/plane";
import rotateSnap from "./helper/rotateSnap";
import lines from "./helper/lines";


export default function createHelperMaterial(color) {
    
    return ({
        rotate: rotate(),
        cornerCircle: cornerCircle(color),
        plane: plane(),
        rotateSnap: rotateSnap(),
        lines: lines(color),
    })
}