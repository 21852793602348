import axios from "../../../../middleware/axios";
import hasValue from "../../util/hasValue";


const fetchOptions = async (api, query = "", value, defaultValue, list, formData) => {
    return new Promise((resolve, reject) => {
        
        
        
        const replaceFormData = (value) => {
            let innerValue = value
            if (formData) {
                Object.keys(formData).forEach((key) => {
                    innerValue = innerValue?.replaceAll("{" + key + "}", formData[key])
                })
            }
            return innerValue
        }
        const baseUrl = replaceFormData(api.url?.replaceAll("{query}", query))
        const queryUrl = query? replaceFormData(api.query?.replaceAll("{query}", query)) || "" : "";
        const viewUrl = replaceFormData(api.view?.replaceAll("{query}", query)) || baseUrl;
        
        const innerValue = mergeArrays(convertToArray(value),convertToArray(defaultValue))
        
        callApi(baseUrl + queryUrl).then((response) => {
            let mappedData = mapApiData(response.data, api.optionValue)
            
            if (hasValue(innerValue)) {
                const valueItem = {}
                
                innerValue.map((val, index) => {
                    
                    callApi(viewUrl + "/" + val).then((item) => {
                        valueItem[index] = mapApiData(item.data, api.optionValue)
                        if (Object.keys(valueItem).length === innerValue.length ) {
                            let valueExtendArray = []
                            if (value) {
                                valueExtendArray = Object.values(valueItem).map(
                                    (i) => {return i[0]}
                                )
                            }
                           
                            resolve(
                                extendByList(
                                    mergeArrays(
                                        mappedData,
                                        valueExtendArray
                                    ),
                                    list
                                )
                            )
                        }
                    })
                })
                
                
            } else {
                resolve(
                    
                    extendByList(mappedData, list)
                )
            }
        })
        
        .catch((error) => {
            reject(error);
        });
    });
};

const callApi = async (url) => {
    return new Promise((resolve, reject) => {
        axios
        .get(url, {withCredentials: true})
        .then((response) => {
            resolve(response.data)
        })
    })
    
}

const mapApiData = (data, optionValue) => {
    return convertToArray(data).map((res) => (
        {
            ...optionValue(res),
            data: res,
        }
    ));
}
const convertToArray = (value) => {
    if (hasValue(value)){
        if (Array.isArray(value)) {
            return value
        } else if (hasValue(value)) {
            return [value]
        }
    } else {
        return []
    }
}

const extendByList = (data, list) => {
    if (list) {
        return mergeArrays(data, list)
    } else {
        return data
    }
}
function mergeArrays(arr1, arr2) {
    const merged = arr2;
    
    for (let i = 0; i < arr1.length; i++) {
        if (!merged.find(item => item.value === arr1[i].value)) {
            merged.push(arr1[i]);
        }
    }
    
    
    return merged;
}
export {mergeArrays}
export default fetchOptions;