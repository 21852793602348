import React, {memo} from "react"
import filterVariantList from "../filterVariantList";
import ThemeElement from "../../theme/ThemeElement";

export default function ColorFormView({name, list, selected, variants, className = ''}) {
    return {
        name: name,
        label: `Select ${name}`,
        placeholder: `Select ${name}`,
        type: "Module",
        module: SelectColor,
        list: list?.map((item) => ({
            label: item.label,
            value: item.label,
            color: item.values
        })),
        variants: variants,
        defaultValue: selected || list[0]?.label,
        className: className,
    }
}


const SelectColor = memo(({item, submit, data}) => {
    const formData = data
    const availableList = []
    item?.list?.map(color => {
        const variants = filterVariantList(item.variants, {...data, color: color.label})
        if (variants.length !== 0) {
            availableList.push(color)
        }
    })
    const checkAvailable = filterVariantList(item.variants, data)
    if (checkAvailable.length === 0 && availableList.length > 0) {
        submit(availableList[0].label)
    }
    return (
        <ThemeElement
            name={"FormColor"}
            data={{
                list : availableList.sort((a, b) => a.label.localeCompare(b.label)),
                formData:formData,
                submit:submit,
                item:item,
            }}
        >
        </ThemeElement>
    )
    
})
