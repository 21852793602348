import manageTransformation from "../manageTransformation"
import store from "../../../../store";
import {snapshot} from "valtio";

export default function scaleSelectedElement(sign) {
    
    const snap = snapshot(store);
    let percentage = 0.1

    let element = snap.elements.find(e => e.elementId === store.selectedElementId)

    if (!element) return

    let newWidth = element.width + (Math.max(element.width * percentage, 1) * sign)
    let newHeight = element.height + (Math.max(element.height * percentage, 1) * sign)

    let transform = {
        width: newWidth,
        height: newHeight,
        snappedX: [],
        snappedY: [],
    }
    
    manageTransformation(transform)
}

