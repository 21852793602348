import deleteElement from "../../elements/deleteElement";
import scaleSelectedElement from "../../elements/util/scaleSelectedElement";
import translateSelectedElement from "../../elements/util/translateSelectedElement";
import store from "../../../../store";
import {snapshot} from "valtio";
export default function initializeKeyboardEvents() {
    window.addEventListener("keydown", adjustWithKeyBoard);
}

function adjustWithKeyBoard(event) {
    
    const snap = snapshot(store);
    const shift = event.shiftKey ? 5 : 1;
    let focusedElement = document.activeElement;
    let isInput = focusedElement?.tagName === "INPUT" || focusedElement?.tagName === "TEXTAREA";
    
    if (isInput) {
        switch (event.code) {
            case "Enter":
                //if (snap.editElement && !focusedElement.onkeypress) store.editElement = null;
                break;
            default:
                break;
        }
        return;
    }
    
    
    const distance = window.threeViewer.camera.position.distanceTo(window.threeViewer.center)
    let translationValue = Math.abs(1.6 - (1.5 - distance)) * shift;
    translationValue = translationValue < 0.5 ? 0.5 : translationValue;
    
    
    switch (event.code) {
        
        case "Enter":
            
            if (snap.selectedElementId) {
                const element = snap.elements.find(e => e.elementId === snap.selectedElementId)
                if (element.type !== "graphic" || element?.isRawImage) {
                    store.editElement = element
                }
            } else {
                store.ui.addModalVisibility = true;
            }
            break;
        case "ArrowLeft":
            translateSelectedElement({ x: -translationValue });
            break;
        case "ArrowRight":
            translateSelectedElement({ x: translationValue });
            break;
        case "ArrowUp":
            translateSelectedElement({ y: translationValue });
            break;
        case "ArrowDown":
            translateSelectedElement({ y: -translationValue });
            break;
        case "Escape":
            if (snap.ui.addModalVisibility) {
                store.ui.addModalVisibility = false;
            } else if (snap.editElement) {
                store.editElement = null;
            } else {
                store.selectedElementId = null;
            }
            
            break;
        case "Delete":
        case "Backspace":
            deleteElement(store.selectedElementId)
            break;
        case "NumpadAdd":
        case "BracketRight":
        case "Equal":
            scaleSelectedElement(1);
            break;
        case "NumpadSubtract":
        case "BracketLeft":
        case "Minus":
            scaleSelectedElement(-1);
            break;
        default:
            break;
    }
}

export { initializeKeyboardEvents };