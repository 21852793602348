import * as THREE from "three";
import {DecalGeometry} from "three/addons/geometries/DecalGeometry";
import createPlaneFromSection from "./createPlaneFromSection";

let planeGeo = new THREE.BufferGeometry()
const maxVerticesSize = 1000;
let vertices = new Float32Array(maxVerticesSize);
let uvs = new Float32Array(maxVerticesSize);



export class GenerateDecal extends THREE.Mesh {
    constructor({
        plane,
        size,
        material,
        type = "section",
        userData = {},
        layer = 1,
        name = "decal",
    }) {
        
        const product = window.threeViewer.product
        let quaternion = new THREE.Quaternion();
        let euler = new THREE.Euler();
        let geometryPlane = createPlaneFromSection(plane);
        let meshWorldPos = plane.getWorldPosition(new THREE.Vector3()).clone();
        let pos = meshWorldPos.clone();
        pos.y = 0;
        let dir = pos.clone().negate().normalize();
        let ray = new THREE.Ray(meshWorldPos, dir);
        let rayCaster = new THREE.Raycaster(ray.origin, ray.direction);
        
        rayCaster.params.Points.threshold = 0.01;
        let intersects = rayCaster.intersectObject(product, true);
        
        plane.getWorldQuaternion(quaternion);
        euler.setFromQuaternion(quaternion, 'XYZ');
        
        
        if (intersects?.length) {
            
            const object = intersects[0].object;
            const point = intersects[0].point;
            
            
            const decalGeometry = new DecalGeometry(object, point, euler, size);
            super(decalGeometry);
            
            this.plane = geometryPlane
            this.originPlane = new THREE.Plane(this.plane.normal.clone(), 0)
            this.material = material
            this.type = type
            this.layers.set(layer)
            this.userData = userData
            this.name = name
            this.euler = euler
            this.object = object
            this.point = point
            this.size = size
            
            this.distance = this.originPlane.distanceToPoint(this.point)
            
            this.highlight = {
                on: this.highlightOn.bind(this),
                off: this.highlightOff.bind(this),
                state: false
            }
            this.addDummyPlane()
        } else {
            super();
            this.error = true
        }
        
    }
    
    highlightOn = (count) => {
        if (!this.highlight.state) {
            if (this.material.map) this.material.map.dispose()
            
            if (!count) {
                this.material.map = this.userData.textures.hoverText
            } else {
                this.material.map = this.userData.textures.hover
            }
            this.highlight.state = true
            this.material.needsUpdate = true
        }
    }
    highlightOff = (state) => {
        if (this.highlight.state || state) {
            this.material.map.dispose()
            this.material.map = this.userData.textures.normal
            this.highlight.state = false
            this.material.needsUpdate = true
        }
        
    }

    addDummyPlane = () => {

        if (this.dummyPlane) {
            this.dummyPlane.geometry.dispose();
            this.dummyPlane.material.dispose();
            this.remove(this.dummyPlane);
            this.dummyPlane = null;
        }

        

        let sizeX = this.size.x
        let sizeY = this.size.y

        let extend = 1
        let multiX = 1 / sizeX
        let multiY = 1 / sizeY
        let data = [
            // Add center quad points
            { location: "sLeftTop", point: new THREE.Vector3(-sizeX / 2, sizeY / 2, 0), uv: new THREE.Vector2(0, 1) },
            { location: "sRightTop", point: new THREE.Vector3(sizeX / 2, sizeY / 2, 0), uv: new THREE.Vector2(1, 1) },
            { location: "sRightBottom", point: new THREE.Vector3(sizeX / 2, -sizeY / 2, 0), uv: new THREE.Vector2(1, 0) },
            { location: "sLeftBottom", point: new THREE.Vector3(-sizeX / 2, -sizeY / 2, 0), uv: new THREE.Vector2(0, 0) },

            // Add left extension points
            { location: "sLeftTopExt", point: new THREE.Vector3(-extend, sizeY / 2, 0), uv: new THREE.Vector2(-1 * multiX, 1) },
            { location: "sLeftBottomExt", point: new THREE.Vector3(-extend, -sizeY / 2, 0), uv: new THREE.Vector2(-1 * multiX, 0) },

            // Add right extension points
            { location: "sRightTopExt", point: new THREE.Vector3(extend, sizeY / 2, 0), uv: new THREE.Vector2(1 + multiX, 1) },
            { location: "sRightBottomExt", point: new THREE.Vector3(extend, -sizeY / 2, 0), uv: new THREE.Vector2(1 + multiX, 0) },

            // Add top extension points
            { location: "sTopLeftExt", point: new THREE.Vector3(-sizeX / 2, extend, 0), uv: new THREE.Vector2(0, 1 + multiY) },
            { location: "sTopRightExt", point: new THREE.Vector3(sizeX / 2, extend, 0), uv: new THREE.Vector2(1, 1 + multiY) },

            // Add bottom extension points
            { location: "sBottomRightExt", point: new THREE.Vector3(sizeX / 2, -extend, 0), uv: new THREE.Vector2(1, -1 * multiY) },
            { location: "sBottomLeftExt", point: new THREE.Vector3(-sizeX / 2, -extend, 0), uv: new THREE.Vector2(0, -1 * multiY) },

            // Add most top left extension points
            { location: "sMostTLExt", point: new THREE.Vector3(-extend, extend, 0), uv: new THREE.Vector2(0, 1 + multiY) },

            // Add most top right extension points
            { location: "sMostTRExt", point: new THREE.Vector3(extend, extend, 0), uv: new THREE.Vector2(1 + multiX, 1 + multiY) },

            // Add most bottom right extension points
            { location: "sMostBRExt", point: new THREE.Vector3(extend, -extend, 0), uv: new THREE.Vector2(1 + multiX, 0) },

            // Add most bottom left extension points
            { location: "sMostBLExt", point: new THREE.Vector3(-extend, -extend, 0), uv: new THREE.Vector2(-1 * multiX, -1 * multiY) },
        ]
        let verticesArr = []
        let uvsArr = []

        function addTriangle(point1, point2, point3) {
            verticesArr.push(
                point1.point.x, point1.point.y, point1.point.z,
                point2.point.x, point2.point.y, point2.point.z,
                point3.point.x, point3.point.y, point3.point.z
            )
            uvsArr.push(
                point1.uv.x, point1.uv.y,
                point2.uv.x, point2.uv.y,
                point3.uv.x, point3.uv.y
            )
        }
    
        function addQuad(point1, point2, point3, point4) {
            addTriangle(point1, point2, point4)
            addTriangle(point2, point3, point4)
        }
        //addQuad(data[12], data[13], data[14], data[15])
        addQuad(data[0], data[1], data[2], data[3])     // Center quad
        addQuad(data[4], data[0], data[3], data[5])     // Left extension
        addQuad(data[1], data[6], data[7], data[2])     // Right extension
        addQuad(data[8], data[9], data[2], data[0])     // Top extension
        addQuad(data[3], data[2], data[10], data[11])   // Bottom extension
        addQuad(data[12], data[8], data[0], data[4])    // Most top left extension
        addQuad(data[9], data[13], data[6], data[1])    // Most top right extension
        addQuad(data[2], data[7], data[14], data[10])   // Most bottom right extension
        addQuad(data[5], data[3], data[11], data[15])   // Most bottom left extension

        let vertices = new Float32Array(verticesArr);

        let uvs = new Float32Array(uvsArr);

        planeGeo.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
        planeGeo.setAttribute('uv', new THREE.BufferAttribute(uvs, 2));

        let planeMat = new THREE.MeshBasicMaterial({
            color: 0xffff00, side: THREE.DoubleSide,
            transparent: true, opacity: 0.5
        });
        let plane = new THREE.Mesh( planeGeo, planeMat );
        plane.position.copy(this.point)
        plane.type= "decalHelperMesh"
        plane.rotateOnAxis(new THREE.Vector3(0, 1, 0), this.userData.radialAngle)
        
        this.add(plane)
        this.dummyPlane = plane
        plane.visible = false
    }
    
    dispose() {
        // Dispose of the decal geometry
        if (this.geometry) {
            this.geometry.dispose();
        }
        
        // Dispose of the material and its textures
        if (this.material) {
            // If there are multiple materials (an array of materials)
            if (Array.isArray(this.material)) {
                this.material.forEach(material => {
                    if (material.map) material.map.dispose();
                    if (material.lightMap) material.lightMap.dispose();
                    if (material.bumpMap) material.bumpMap.dispose();
                    if (material.normalMap) material.normalMap.dispose();
                    if (material.displacementMap) material.displacementMap.dispose();
                    if (material.specularMap) material.specularMap.dispose();
                    if (material.emissiveMap) material.emissiveMap.dispose();
                    if (material.alphaMap) material.alphaMap.dispose();
                    if (material.roughnessMap) material.roughnessMap.dispose();
                    if (material.metalnessMap) material.metalnessMap.dispose();
                    material.dispose();
                });
            } else {
                // If it's a single material
                if (this.material.map) this.material.map.dispose();
                // Repeat the dispose process for all other texture types as above
                this.material.dispose();
            }
        }
        
        // Dispose of any dummy planes or additional meshes added as children
        if (this.dummyPlane) {
            if (this.dummyPlane.geometry) this.dummyPlane.geometry.dispose();
            if (this.dummyPlane.material) this.dummyPlane.material.dispose();
            this.remove(this.dummyPlane); // Remove the dummyPlane from the parent mesh (this decal)
        }
        
        // if (this.plane) this.plane.dispose();
        
        // Remove this decal from its parent if it has one
        if (this.parent) {
            this.parent.remove(this);
        }
    }
    
}
