import moveSnapping from "../snapping/move";


export default function transformMove({mouse, mouseDifDrag, snapping}) {
    
    const x = (mouse.x - mouseDifDrag.x)
    const y = (mouse.y - mouseDifDrag.y)

    const snappedPosition = moveSnapping(x, y, snapping)
    
    return {
        x: snappedPosition.x,
        y: snappedPosition.y,
        snappedX: snappedPosition.snappedX,
        snappedY: snappedPosition.snappedY,
    }
}