import store from "../../../store";
import {snapshot} from "valtio";
import getBoundingBoxByAngle from "../svg/getBoundingBoxByAngle";
import restrictElementInsideSection from "../events/handlers/restrictElementInsideSection";

export default async function manageTransformation(transform) {
    
    const threeViewer = window.threeViewer;
    const snap = snapshot(store);
    const elementId = snap.selectedElementId;
    const sectionId = snap.selectedSectionId;
    const sectionData =  snap.sections.find(section => section.section_id === sectionId)
    
    if (elementId) {
        const index = snap.elements.findIndex(element => element.elementId === elementId);
        const element = snap.elements[index];
        
        const section = threeViewer.scene.children.find(child => child.type === "section" && child.userData.id === sectionId)
        
        if (checkChange(transform, element)) {
            //const newTransform
            const {snappedX, snappedY, ...transformObject} = await restrictElementInsideSection(transform)
            transform = transformObject
            //if (transform.angle || transform.width || transform.offset_y) {
                transform.snapping = await getBoundingBoxByAngle({
                    width: transform.width || element.width,
                    height: transform.height || element.height,
                    angle: transform.angle || element.angle,
                    outline: element.outline,
                    offset_x: transform.offset_x || element.offset_x,
                    offset_y: transform.offset_y || element.offset_y,
                    sectionWidth: sectionData.width,
                    sectionHeight: sectionData.height,
                    section_offset_y: sectionData.offset_Y,
                    lockMove: element.lockMove,
                })
            //}
            
            const newElement = {
                ...element,
                ...transform,
            }
            store.elements[index] = newElement;
            section.snapping.show(
                snappedX || [],
                snappedY || [],
                section.userData.offsetX,
                section.userData.offsetY,
            )
            section.update()
            section.helper.show(newElement)
            
            window.threeViewer.baseElement = {};
        }
    }
    
    
}


const checkChange = (transform, element) => {
    
    const moveThreshold = 0.5;
    const scaleThreshold = 1;
    const rotateThreshold = 0.1;
    
    const offset_x = transform.offset_x;
    const offset_y = transform.offset_y;
    const angle = transform.angle;
    const width = transform.width;
    const height = transform.height;
    
    const currentOffset_x = element.offset_x;
    const currentOffset_y = element.offset_y;
    const currentAngle = element.angle;
    const currentWidth = element.width;
    const currentHeight = element.height;
    
    
    if (
        Math.abs(offset_x - currentOffset_x) >= moveThreshold ||
        Math.abs(offset_y - currentOffset_y) >= moveThreshold ||
        Math.abs(angle - currentAngle) >= rotateThreshold ||
        Math.abs(width - currentWidth) >= scaleThreshold ||
        Math.abs(height - currentHeight) >= scaleThreshold
    ) {
        return true;
    }
    return false;
}




