import React, {useEffect, useState} from 'react';
import ThemeElement from "../../util/theme/ThemeElement";


const Tab = ({activeTab, onClick, label}) => {
    const handleClick = () => {
        onClick(label);
    }
    
    return (
        <ThemeElement
            name={"TabItem"}
            data={{
                activeTab: activeTab,
                onClick: handleClick,
                label: label
            }}
        />
    );
}

const Tabs = ({props}) => {
    const {list, selected} = props;
    const [activeTab, setActiveTab] = useState(selected || list?.[0].label);
    
    
    const onClickTabItem = (tab) => {
        setActiveTab(tab)
    }
    
    useEffect(() => {
        onClickTabItem(selected);
    }, [list]);

    
    return (
        <ThemeElement
            name={"Tabs"}
            data={{
                activeTab: activeTab,
                onClick: onClickTabItem,
                list: list || []
            }}
        />
        
    );
}

export {Tabs, Tab};
