import * as THREE from "three";
import { snapshot } from "valtio";
import store from "../../../store";
import findIntersectionWithSection from "./findIntersectionWithSection";

// create new raycaster
const raycaster = new THREE.Raycaster();

export default function getMouseMoveIntersection() {
    const snap = snapshot(store);
    const selectedSectionId = snap.selectedSectionId;
    const section = snap.sections.find(s => s.section_id === selectedSectionId);
    if (!section) return null;
    
    const threeViewer = window.threeViewer;
    const sectionThreeViewer = threeViewer.scene.children.find(child => child.type === "section" && child.userData.id === selectedSectionId);
    if (!window.threeViewer.cursorActive) return null
    
    
    // Get the mouse position
    const mouse = threeViewer.intersection.mouse
    const mouseDownActive = threeViewer.intersection.active
    
    
    // Get the model
    const model = mouseDownActive
        ? sectionThreeViewer
        : threeViewer.scene;
    
    
    // Set the raycaster
    raycaster.setFromCamera(mouse, threeViewer.camera);
    raycaster.params.Mesh.threshold = 0.1;
    raycaster.layers.enableAll();
    
    let uv = findIntersectionWithSection(mouse, sectionThreeViewer, )
    if(!uv) {
        //console.log("no uv")
        uv = findIntersectionWithSection(mouse, sectionThreeViewer, true)
    }
    if(!uv) return null

    uv.y += section.offset_Y
    // Set the intersection target
    const intersects = raycaster.intersectObject(model, true);
    
    
    if (mouseDownActive) {
        return uv
    } else {
        if(intersects.length) {
            let helperIndex = intersects.findIndex(intersect => intersect.object.type === "decalHelper")
            helperIndex = helperIndex > -1 ? helperIndex : 0;
            return intersects.length ? intersects[helperIndex] : null
        }
    }
}
