import React from "react";
import store from "../../store";
import {useSnapshot} from "valtio";


export default function SectionsCanvas() {
    const snap = useSnapshot(store);
    const sectionList = snap.sections;
    /*const productColor = window?.productColorObject
    const is3DViewMode = snap?.ui?.viewMode === "3D";
    const isFullScreen = snap?.ui?.fullScreen;
    const scale = 2;
    
    const minOffset = 0//sectionList?.reduce((min, item) => item.offset_Y < min ? item.offset_Y : min, sectionList[0].offset_Y) || 0;*/
    return (
        <div id={"sectionsCanvas"} >
            {sectionList.map((section, index) => (
                <canvas key={index} id={`sectionCanvas-${section.section_id}`}/>
            ))}
            <canvas id={'sectionsCanvas_effectCanvas'}/>
        </div>
        /*<div
            className={`!bg-[${productColor?.color}] fixed w-[100vw] h-[100%] top-0 left-0 overflow-x-auto select-none z-[99] flex items-center  ${is3DViewMode || !isFullScreen ? "hidden" : ""}`}
            style={{backgroundColor: productColor?.color}}>
            <div className={'h-max flex flex-row gap-md px-[17px] mx-auto'}
                 style={{filter: `${productColor?.isLight ? "invert(1)" : "none"}`}}>
                {
                    sectionList.map((section, index) => (
                        <div className={`pb-[${(section.offset_Y - minOffset) * scale}px] h-full flex flex-col gap-md`}
                             key={index}>
                            <div
                                className={'text-title-md text-center text-[#bbbbbb] opacity-50'}>{section.section_name}</div>
                            <canvas
                                key={index} id={`sectionCanvas-${section.section_id}`}
                                width={section.width} height={section.height}
                                className={`w-[${section.width * scale}px]  h-[${section.height * scale}px] border  border-[#bbbbbb] border-opacity-30 `}
                            
                            />
                        </div>
                    ))
                }
            </div>
            <canvas className={"hidden"} id={'effectCanvas'}/>
        </div>*/
    )
}