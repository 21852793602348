import React, {useCallback, useMemo, useState} from "react";
import store from '../../../store';
import { useSnapshot } from "valtio";
import addNewTextElement from "../../../util/internal/elements/addNewTextElement";
import addNewMonogram from "../../../util/internal/elements/addNewMonogram";
import addNewListTextsElement from "../../../util/internal/elements/addNewListTextsElement";
import ThemeElement from "../../../util/theme/ThemeElement";

const TextTab = () => {
    const snap = useSnapshot(store);
    const selectedSectionId = snap?.selectedSectionId;
    
    const [inProgress, setInProgress] = useState(false)
    
    // Check list texts is visible fot this item
    const isListTextsVisible = useMemo(() => {
        // const textDynamicElementList = snap.elements.filter(e => e.type === 'listTexts');
        const textDynamicElementList = snap.elements.filter(e => e.type === 'listTexts' && e.sectionId === selectedSectionId);
        const selectBundleIndex = snap.sections.find(it => it.section_id === selectedSectionId)?.bundle_index;

        if (textDynamicElementList.length === 0) return true;

        return !textDynamicElementList.some(item => {
            const bundle_index_dynamic = snap.sections.find(it => it.section_id === item.sectionId)?.bundle_index;
            return bundle_index_dynamic === selectBundleIndex;
        });
    }, [snap.elements, snap.sections, selectedSectionId]);


    
    
    // Add Normal Text
    const addText = useCallback(async () => {
        setInProgress(true);
        store.editElement = await addNewTextElement('Text here!', selectedSectionId);
        setInProgress(false);
    }, [selectedSectionId]);
    
    // Add Monogram Text
    const addMonogram = useCallback(async () => {
        setInProgress(true);
        store.editElement = await addNewMonogram('ABC   ', selectedSectionId);
        setInProgress(false);
    }, [selectedSectionId]);
    
    // Add List Texts
    const addListTexts = useCallback(async () => {
        setInProgress(true);
        store.editElement = await addNewListTextsElement('Placeholder!', selectedSectionId);
        setInProgress(false);
    }, [selectedSectionId]);
    
    
    
    return (
        <ThemeElement
            name={"AddElementText"}
            data={{
                handleAddText: addText,
                handleAddMonogram: addMonogram,
                handleAddListTexts: addListTexts,
                selectedSectionId: selectedSectionId,
                isListTextsVisible: isListTextsVisible,
                inProgress:inProgress
            }}
        />
    );
}

export default React.memo(TextTab);