import React from 'react';

const Range = ({item, value=[undefined,undefined], validationMessage, handleChange, formData}) => {
    
    const disabled = typeof item.disabled === "function" ? item.disabled(formData) : item.disabled || false;
    
    // Empty value
    const rangValue = (val) => {
        return(!val || val === "" ? ["", ""] : val).map(child => child === "" || !child ? "" : child)
    };
    
    // Return Range in view
    return (
        <div className={"flex flex-row gap-3 items-center justify-center h-full"}>
            <input
                name={item.name}
                type={"number"}
                placeholder={"Start"}
                disabled={disabled}
                className={`transition ${item.disabled && "disabled"} text-center disabled:text-gray-400 rounded-md px-[8px] h-[42px] border-gray-200 border hover:border-gray-400 focus:outline-none focus:border-blue-500 focus:ring-1 block w-full ${item.innerClassName || ""} ${validationMessage ? 'border-red-500 border-2' : ''}`}
                value={rangValue(value)[0]}
                max={rangValue(value)[1]}
                onChange={(e) => {
                    handleChange([e.target.value,rangValue(value)[1]], item.name)
                }}
            />
            <span>to</span>
            <input
                name={item.name}
                type={"number"}
                placeholder={"End"}
                disabled={disabled}
                className={`transition ${item.disabled && "disabled"} text-center disabled:text-gray-400 rounded-md px-[8px] h-[42px] border-gray-200 border hover:border-gray-400 focus:outline-none focus:border-blue-500 focus:ring-1 block w-full ${item.innerClassName || ""} ${validationMessage ? 'border-red-500 border-2' : ''}`}
                value={rangValue(value)[1]}
                min={rangValue(value)[0]}
                onChange={(e) => {
                    handleChange([rangValue(value)[0],e.target.value], item.name)
                }}
            />
        </div>
    )
}


export default Range;