import {proxy} from 'valtio';


const state = proxy({
    
    sections: [],
    selectedSectionId: null,
    selectedElementId: null,
    selectedProductId: null,
    
    selectedProductSKU: null,
    selectedBundleProductSKU: null,
    selectedVariantSKU: null,
    variants: [],
    variantsOptions: {},
    
    editElement: null,
    container: null,
    product: null,
    configuration: null,
    status: {
        loaded: false,
        initialized: false,
        initializing: false,
        error: null,
        tour: false,
        addToCart: false,
        loading: true
    },
    elements: [],
    
    ui: {
        showTour: false,
        fullScreen: false,
        locked: false,
        zoom: null,
        addModalVisibility: false,
        showDesignListActiveTab: false,
        fitToSection: null,
        tour: true,
        viewMode: "3D",
        configurator: false,
        cameraFlash: false,
        setting: {},
        theme : false,
        graphicCategories: false,
        loadingStyle: {},
    },
    snapPoints: {},
    order: {
        price: "0.00",
    },
    userId:null,
    user : null/*{
        email: "banipalart@gmail.com",
        firstName : "memet",
        id:"7228871573786",
        lastName:"khaki"
    }*/
})

export default state;



