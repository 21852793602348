// Map the option list from the variant list
// ----------------------------------------------------------

export default function mapOptionList(variantList) {
    const optionItems = {}
    
    variantList.forEach(variant => {
        // Collect unique option items
        // Check if the variant available in both Admin Portal and Shopify
        if (variant?.options && (variant?.["3d_image_url"] || variant?.is_bundle)) {
            
            Object.keys(variant?.options).forEach(option => {
                
                const item = variant.options[option];
                
                // Initialize a Map instead of a Set for each option
                if (!optionItems[option]) {
                    optionItems[option] = new Map();
                }
                
                // Use a unique string representation of the item as the key in the Map
                const itemKey = JSON.stringify(item);
                
                // Add the item to the Map if it doesn't already exist
                if (!optionItems[option].has(itemKey)) {
                    optionItems[option].set(itemKey, item);
                }
            });
        } else if (variant){}
    });
    
    // Convert Maps to Arrays of the stored items
    Object.keys(optionItems).forEach(option => {
        // Check if the optionItems[option] has less than 2 items
        if (optionItems[option].size < 2) {
            delete optionItems[option];
        } else {
            optionItems[option] = Array.from(optionItems[option].values()).sort((a, b) => {
                if (a.label && b.label) {
                    return a.label.localeCompare(b.label);
                }
                return 0;
            });
        }
    });
    
    return optionItems;
}
